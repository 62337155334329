import React from 'react';
import { ContactStatus } from './private/ContactRow/ContactRow.types';

export interface CallForwardCardProps<RowItem> {
  id: string;
  className?: string;
  callForwardDialpadClassName?: string;
  contentClassName?: string;
  inputWrapperClassName?: string;
  dialpadButtonContainerClassName?: string;
  contactRowListClassName?: string;
  hideHeader?: boolean;
  title: string;
  proceedButtonLabel: string;
  proceedButtonDisabled?: boolean;
  onClose?: (callbackValue?: any, event?: any) => any;
  onProceed?: (callbackValue?: any, event?: any) => any;
  onSelect?: (callbackValue?: any, event?: any) => any;
  onInputChange?: (inputValue?: any, event?: any) => any;
  callBackValue?: any;
  inputValue?: string;
  style?: React.CSSProperties;
  data?: Array<RowItem>;
  dataTotalCounts?: number;
  dataLoading?: boolean;
  miniSkeleton?: boolean;
  getId: (item: RowItem) => string;
  getAvatarUrl: (item: RowItem) => string | null;
  getAvatarFallbackLabel: (item: RowItem) => string;
  getContactName: (item: RowItem) => string;
  getContactStatus: (item: RowItem) => ContactStatus;
  isSelected: (item: RowItem) => boolean;
  isActive: (item: RowItem) => boolean;
  dialpadOpen: boolean;
  externalNumber: string;
  externalNumberCallbackValue?: any;
  showBackSpace?: boolean;
  dialpadProceedButtonDisabled?: boolean;
  type?: Type;
  onDialpadClicked: () => any;
  onNumberChange?: (event?: any) => any;
  onDialpadNumberPressed: (callbackValue?: any, event?: any) => any;
  onBackSpaceClicked: (callbackValue?: any, event?: any) => any;
  onBackButtonClicked: () => any;
  onDialpadProceed: (callbackValue?: any, event?: any) => any;
}

export enum Type {
  forwardCall = 'forwardCall',
  hotTransferCall = 'hotTransferCall',
}