import React, { forwardRef } from 'react';
import Skeleton from '@mui/material/Skeleton';
import classnames from 'classnames';

import { CallHistoryRowLoadingProps, Theme } from './CallHistoryRowLoading.types';
import CallHistoryRowLoadingThemer from './CallHistoryRowLoading.theme';

const CallHistoryRowLoadingComponent = forwardRef<HTMLDivElement, CallHistoryRowLoadingProps>((props, ref) => {
  const { id, className, style, showNavigationNode, showCallActionButton, navigationNode, theme } = props;
  const classes = CallHistoryRowLoadingThemer.useStyles(props);
  const rowClassName = classnames(
    classes.row,
    { [classes.rowNormal]: theme === Theme.normal, [classes.rowInverse]: theme === Theme.inverse },
    className,
  );

  return (
    <div
      id={id}
      ref={ref}
      className={rowClassName}
      style={style}
    >
      <div className={classnames(classes.columnAvatar)}>
        <Skeleton variant="circular" width={40} height={40} />
      </div>

      <div className={classnames(classes.columnTimestamp)}>
        <Skeleton variant="rectangular" width={90} height={21} />
      </div>

      <div className={classnames(classes.columnDuration)}>
        <Skeleton variant="rectangular" width={90} height={21} />
      </div>

      <div className={classnames(classes.columnActions)}>
        <Skeleton variant="rectangular" width={78} height={21} />
      </div>

      {showNavigationNode && navigationNode && (
        <div className={classnames(classes.columnNavigation)}>
          <Skeleton variant="circular" width={30} height={30} />
        </div>
      )}
    </div>
  );
});

CallHistoryRowLoadingComponent.defaultProps = {
  className: undefined,
  style: undefined,
  showNavigationNode: false,
  showCallActionButton: false,
  navigationNode: undefined,
  miniVersion: false,
};

export default CallHistoryRowLoadingComponent;
