import ApiHelper from '../../helpers/apiHelper';
import VoiceIQApiAxiosInstance from '../instances/VoiceIQAPI';
import { store } from '../../app/store';

const getTeam = (teamId: string, tenantCode: string, AccessToken: string) => {
  const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(AccessToken, tenantCode),
    url: `/api/v1/teams/${teamId}`,
    method: 'get',
  };

  return VoiceIQApiAxiosInstance.request(config);
}

const UserService = { getTeam };

export default UserService;