import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ModalType } from '../constants/ModalType';

const initialState: any = { open: false, modalData: {}, modalType: ModalType.Gdpr };

const gdprModalSlice = createSlice({
  name: 'gdprModal',
  initialState,
  reducers: {
    showGdprModal: (state,  action: PayloadAction<any>) => {
      const { contact, contactNumber, dialFromNumber, callEventId, modalType } = action.payload;

      state.open = true;

      if (modalType === ModalType.Gdpr) {
        state.modalData = {
          contact,
          contactNumber,
          dialFromNumber,
          callEventId,
          title: 'GDPR Warning',
          primaryText: 'This contact has opted out of marketing related activities.',
          secondaryText: 'You may proceed if the call is for a legitimate interest.',
          confirmButtonText: 'Proceed with call',
          cancelButtonText: 'Take me back',
        }
      } else {
        state.modalData = {
          contact,
          contactNumber,
          dialFromNumber,
          callEventId,
          title: 'Unable to Dial Number',
          primaryText: 'Your license does not include international numbers.',
          cancelButtonText: 'Close Window',
        }
      }
    },
    hideGdprModal: (state) => {
      state.open = false;
      state.modalData = {
        contact: null,
        contactNumber: '',
        dialFromNumber: '',
        callEventId: '',
        title: ''
      }
    },
  },
});

export const { showGdprModal, hideGdprModal } = gdprModalSlice.actions;
const { reducer } = gdprModalSlice;
export default reducer;
