import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import CallCentreService from "../Services/CallCentre/CallCentre";
import { RootState } from '../app/store';
import { setIsCurrentCallInHold } from '../slices/callCentre';
import { CallDeviceStatusEnum } from '../constants/CallStatusEnum';

const initialState: any = { callStatus: CallDeviceStatusEnum.UnInitialized, errors: [], callsOnHold: {}, callWaitingToBeResumed: {}, callHoldLoading: false };

export const callHold: any = createAsyncThunk(
  "callHold/post",
  async (args: {currentActiveCallRecordId: any, callMetaData: any}, { getState, dispatch }) => {
    const {currentActiveCallRecordId, callMetaData} = args;
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    dispatch(callHoldStatus({currentActiveCallRecordId, status: 'pending'}));
    const result = await CallCentreService.holdCall(currentActiveCallRecordId, tenantCode, AccessToken);
    dispatch(callHoldReducer({currentActiveCallRecordId, callMetaData}));
    dispatch(setIsCurrentCallInHold(true));

    return result.data;
  }
);

export const callResume: any = createAsyncThunk(
  "callResume/post",
  async (args: {currentActiveCallRecordId: any, callMetaData: any}, { getState, dispatch }) => {
    const {currentActiveCallRecordId, callMetaData} = args;
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const result = await CallCentreService.resumeCall(currentActiveCallRecordId, tenantCode, AccessToken);
    dispatch(callResumeReducer({currentActiveCallRecordId, callMetaData}));
    dispatch(setIsCurrentCallInHold(false));

    return result.data;
  }
);

const callStateSlice = createSlice({
  name: 'callState',
  initialState,
  reducers: {
    callError(state, action: PayloadAction<any>) {
      state.errors.push(action.payload);
    },
    callHoldStatus(state, action: PayloadAction<any>) {
      const { currentActiveCallRecordId, status } = action.payload;

      state.callsOnHold = {
        ...state.callsOnHold,
        [currentActiveCallRecordId]: {
          remoteId: currentActiveCallRecordId,
          status: status,
        },
      };
    },
    callHoldReducer(state, action: PayloadAction<any>) {
      const { currentActiveCallRecordId, callMetaData } = action.payload;

      state.callsOnHold = {
        ...state.callsOnHold,
        [currentActiveCallRecordId]: {
          remoteId: currentActiveCallRecordId,
          callDetails: callMetaData,
          status: 'complete',
        },
      };
    },
    callResumeReducer(state, action: PayloadAction<any>) {
      const { currentActiveCallRecordId } = action.payload;
      const callsOnHold = { ...state.callsOnHold };
      const resumedCallDetails = callsOnHold[currentActiveCallRecordId];
      delete callsOnHold[currentActiveCallRecordId];

      state.callsOnHold = {};

      state.callWaitingToBeResumed = {
        ...state.callWaitingToBeResumed,
        [currentActiveCallRecordId]: resumedCallDetails,
      };
    },
    clearResumingCallDetails(state) {
      state.callWaitingToBeResumed = null;
    },
    callStatusChange(state, action: PayloadAction<any>) {
      const { status } = action.payload;
      state.callStatus = status;
    },
  },
  extraReducers: {
    [callHold.pending]: (state, action) => {
      state.callHoldLoading = true;
    },
    [callHold.fulfilled]: (state, action) => {
      state.callHoldLoading = false;
    },
    [callHold.rejected]: (state, action) => {
      state.callsOnHold = {
        error: action.error,
      };
      state.callHoldLoading = false;
    },

    [callResume.pending]: (state, action) => {
      state.callHoldLoading = true;
    },
    [callResume.fulfilled]: (state, action) => {
      state.callHoldLoading = false;
    },
    [callResume.rejected]: (state, action) => {
      state.callsOnHold = {
        ...state,
        error: action.error,
        callWaitingToBeResumed: null,
      };
      state.callHoldLoading = false;
    },
  }
});

export const { callError, callHoldStatus, callHoldReducer, callResumeReducer, clearResumingCallDetails, callStatusChange } = callStateSlice.actions;
const { reducer } = callStateSlice;
export default reducer;
