import React, { forwardRef, useCallback, useRef, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import Icon, { IconTheme, IconSize } from '../../../Icon';
import Typography, { TypographyVariant } from '../../../Typography';

import { NumberPadProps, KeyboardKeys } from './NumberPad.types';
import NumberPadThemer from './NumberPad.theme';

const ThemedTextField = withStyles(NumberPadThemer.textFieldClasses)(InputBase);

const NumberPadCardComponent = forwardRef<HTMLElement, NumberPadProps>((props, ref) => {
  const {
    id,
    className,
    style,
    number,
    onBackspaceClick,
    callbackValue,
    showBackSpace,
    onNumberChange,
    disableFocus,
    onEscape,
    contactName,
    showContactName,
  } = props;

  const inputRef: React.MutableRefObject<any> = useRef(null);

  const [escClickedOnce, setEscClickedOnce] = useState(false);

  useEffect(() => {
    const handleEsc = (nativeEvent: any) => {
      const event = nativeEvent;
      if (event.key === KeyboardKeys.esc && escClickedOnce) onEscape?.();
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [escClickedOnce]);

  const classes = NumberPadThemer.useStyles();
  const cardClassName = classnames(classes.card, className);
  const backSpaceClassName = classnames(classes.backspaceIcon);
  const iconClassName = classnames(classes.iconElement);

  const handleClick = useCallback(
    (e: any) => {
      onBackspaceClick?.(callbackValue, e);
    },
    [onBackspaceClick, callbackValue]
  );

  const handleOnKeyDown = useCallback(
    (e: any) => {
      const key = e.key || '';
      const keysToInclude =
        key === KeyboardKeys.plus ||
        key === KeyboardKeys.backSpace ||
        key === KeyboardKeys.hash ||
        key === KeyboardKeys.asterisk ||
        key === KeyboardKeys.arrowLeft ||
        key === KeyboardKeys.arrowRight;

      //Prevent default action, which is inserting character
      if (disableFocus) {
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
      } else if (key === KeyboardKeys.esc) {
        if (e.preventDefault) e.preventDefault();
        e.target.blur();
        setEscClickedOnce(true);
      } else if (key === KeyboardKeys.spaceBar) {
        // Not the most elegant way to do it
        if (e.repeat) {
          e.target.value = `${e.target.value}+`;
        } else {
          e.target.value += '0';
        }
      } else if (isNaN(key) && !keysToInclude) {
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
      }
    },
    [onEscape, disableFocus]
  );

  const handleOnFocus = useCallback(() => {
    setEscClickedOnce(false);
  }, [setEscClickedOnce]);

  const backspaceIcon = (
    <div onClick={handleClick} className={backSpaceClassName}>
      <Icon theme={IconTheme.default} size={IconSize.extraLarge} className={iconClassName}>
        backspace
      </Icon>
    </div>
  );
  const numberField = (
    <ThemedTextField
      id={`${id}-Number-Field`}
      type="tel"
      value={number}
      fullWidth
      autoFocus
      onChange={onNumberChange}
      inputRef={inputRef}
      onKeyDown={handleOnKeyDown}
      onFocus={handleOnFocus}
      inputProps={{ autoComplete: 'off' }}
      autoComplete="off"
    />
  );

  const contactNameNode = (
    <Typography.P1 id={`${id}-Contact-Name`} variant={TypographyVariant.light} className={classes.contactName}>
      {contactName}
    </Typography.P1>
  );

  return (
    <Card id={id} ref={ref} className={cardClassName} style={style}>
      <div className={classes.mainContainer}>
        <div className={classes.inputContainer}>
          {numberField}
          {showBackSpace && backspaceIcon}
        </div>
        {showContactName && contactNameNode}
      </div>
    </Card>
  );
});

NumberPadCardComponent.defaultProps = {
  className: undefined,
  numberTextClassName: undefined,
  style: undefined,
  number: undefined,
  onBackspaceClick: () => null,
  callbackValue: undefined,
  showBackSpace: false,
  disableFocus: false,
  onEscape: () => {},
  contactName: '',
  showContactName: false,
};

export default NumberPadCardComponent;
