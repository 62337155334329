import { makeStyles } from '@material-ui/core/styles';

import FontFamily from '../../Theme/FontFamily';
import Colours from '../../Theme/Colours';

import { ButtonProps, Theme, Variant } from './Button.types';

const NormalVariantThemeBackgroundColourPaletteMap = {
  [Theme.primary]: {
    normal: Colours.PrimaryA.normal,
    hovered: Colours.PrimaryA.hovered,
    pressed: Colours.PrimaryA.pressed,
    disabled: '#F7F7F7',
  },
  [Theme.secondary]: {
    normal: Colours.PrimaryB.normal,
    hovered: Colours.PrimaryB.hovered,
    pressed: Colours.PrimaryB.pressed,
    disabled: '#F7F7F7',
  },
  [Theme.danger]: {
    normal: Colours.Danger.normal,
    hovered: Colours.Danger.hovered,
    pressed: Colours.Danger.pressed,
    disabled: '#F7F7F7',
  },
  [Theme.light]: {
    normal: '#FFFFFF',
    hovered: '#CFCFCF',
    pressed: '#BDBDBD',
    disabled: '#F7F7F7',
  },
};
const InverseVariantThemeBackgroundColourPaletteMap = {
  [Theme.primary]: {
    normal: 'transparent',
    hovered: Colours.PrimaryA.inverseHovered,
    pressed: Colours.PrimaryA.inversePressed,
    disabled: '#F7F7F7',
  },
  [Theme.secondary]: {
    normal: 'transparent',
    hovered: Colours.PrimaryB.inverseHovered,
    pressed: Colours.PrimaryB.inversePressed,
    disabled: '#F7F7F7',
  },
  [Theme.danger]: {
    normal: 'transparent',
    hovered: Colours.Danger.inverseHovered,
    pressed: Colours.Danger.inversePressed,
    disabled: '#F7F7F7',
  },
  [Theme.light]: {
    normal: 'transparent',
    hovered: 'rgba(255, 255, 255, 0.1)',
    pressed: 'rgba(255, 255, 255, 0.2)',
    disabled: 'transparent',
  },
};
const TextVariantThemeBackgroundColourPaletteMap = {
  [Theme.primary]: {
    normal: 'transparent',
    hovered: 'transparent',
    pressed: 'transparent',
    disabled: 'transparent',
  },
  [Theme.secondary]: {
    normal: 'transparent',
    hovered: 'transparent',
    pressed: 'transparent',
    disabled: 'transparent',
  },
  [Theme.danger]: {
    normal: 'transparent',
    hovered: 'transparent',
    pressed: 'transparent',
    disabled: 'transparent',
  },
  [Theme.light]: {
    normal: 'transparent',
    hovered: 'transparent',
    pressed: 'transparent',
    disabled: 'transparent',
  },
};

const NormalVariantThemeBorderColourPaletteMap = {
  [Theme.primary]: {
    normal: 'transparent',
    hovered: 'transparent',
    pressed: 'transparent',
    disabled: 'transparent',
  },
  [Theme.secondary]: {
    normal: 'transparent',
    hovered: 'transparent',
    pressed: 'transparent',
    disabled: 'transparent',
  },
  [Theme.danger]: {
    normal: 'transparent',
    hovered: 'transparent',
    pressed: 'transparent',
    disabled: 'transparent',
  },
  [Theme.light]: {
    normal: 'transparent',
    hovered: 'transparent',
    pressed: 'transparent',
    disabled: 'transparent',
  },
};
const InverseVariantThemeBorderColourPaletteMap = {
  [Theme.primary]: {
    normal: Colours.PrimaryA.normal,
    hovered: Colours.PrimaryA.hovered,
    pressed: Colours.PrimaryA.pressed,
    disabled: '#D1D4D8',
  },
  [Theme.secondary]: {
    normal: Colours.PrimaryB.normal,
    hovered: Colours.PrimaryB.hovered,
    pressed: Colours.PrimaryB.pressed,
    disabled: '#D1D4D8',
  },
  [Theme.danger]: {
    normal: Colours.Danger.normal,
    hovered: Colours.Danger.hovered,
    pressed: Colours.Danger.pressed,
    disabled: '#D1D4D8',
  },
  [Theme.light]: {
    normal: '#FFFFFF',
    hovered: '#FFFFFF',
    pressed: '#FFFFFF',
    disabled: '#D1D4D8',
  },
};
const TextVariantThemeBorderColourPaletteMap = NormalVariantThemeBorderColourPaletteMap;

const NormalVariantThemeTextColourMap = {
  [Theme.primary]: {
    normal: Colours.Typography.light,
    hovered: Colours.Typography.light,
    pressed: Colours.Typography.light,
    disabled: Colours.Typography.disabled,
  },
  [Theme.secondary]: {
    normal: Colours.Typography.light,
    hovered: Colours.Typography.light,
    pressed: Colours.Typography.light,
    disabled: Colours.Typography.disabled,
  },
  [Theme.danger]: {
    normal: Colours.Typography.light,
    hovered: Colours.Typography.light,
    pressed: Colours.Typography.light,
    disabled: Colours.Typography.disabled,
  },
  [Theme.light]: {
    normal: Colours.Typography.black,
    hovered: Colours.Typography.black,
    pressed: Colours.Typography.black,
    disabled: Colours.Typography.disabled,
  },
};
const InverseVariantThemeTextColourMap = {
  [Theme.primary]: {
    normal: Colours.PrimaryA.normal,
    hovered: Colours.PrimaryA.hovered,
    pressed: Colours.PrimaryA.pressed,
    disabled: Colours.Typography.disabled,
  },
  [Theme.secondary]: {
    normal: Colours.PrimaryB.normal,
    hovered: Colours.PrimaryB.hovered,
    pressed: Colours.PrimaryB.pressed,
    disabled: Colours.Typography.disabled,
  },
  [Theme.danger]: {
    normal: Colours.Danger.normal,
    hovered: Colours.Danger.hovered,
    pressed: Colours.Danger.pressed,
    disabled: Colours.Typography.disabled,
  },
  [Theme.light]: {
    normal: '#FFFFFF',
    hovered: '#FFFFFF',
    pressed: '#FFFFFF',
    disabled: Colours.Typography.disabled,
  },
};
const TextVariantThemeTextColourMap = InverseVariantThemeTextColourMap;

const resolveBackgroundColourPalette = (variant: Variant = Variant.normal, theme: Theme = Theme.primary) => {
  let result = { normal: 'transparent', hovered: 'transparent', pressed: 'transparent', disabled: 'transparent' };

  switch (variant) {
    case Variant.normal:
      result = NormalVariantThemeBackgroundColourPaletteMap[theme];
      break;
    case Variant.inverse:
      result = InverseVariantThemeBackgroundColourPaletteMap[theme];
      break;
    case Variant.text:
      result = TextVariantThemeBackgroundColourPaletteMap[theme];
      break;
    default:
      break;
  }

  return result;
};

const resolveBorderColourPalette = (variant: Variant = Variant.normal, theme: Theme = Theme.primary) => {
  let result = { normal: 'transparent', hovered: 'transparent', pressed: 'transparent', disabled: 'transparent' };

  switch (variant) {
    case Variant.normal:
      result = NormalVariantThemeBorderColourPaletteMap[theme];
      break;
    case Variant.inverse:
      result = InverseVariantThemeBorderColourPaletteMap[theme];
      break;
    case Variant.text:
      result = TextVariantThemeBorderColourPaletteMap[theme];
      break;
    default:
      break;
  }

  return result;
};

const resolveTextColourPalette = (variant: Variant = Variant.normal, theme: Theme = Theme.primary) => {
  let result = { normal: 'transparent', hovered: 'transparent', pressed: 'transparent', disabled: Colours.Typography.disabled };

  switch (variant) {
    case Variant.normal:
      result = NormalVariantThemeTextColourMap[theme];
      break;
    case Variant.inverse:
      result = InverseVariantThemeTextColourMap[theme];
      break;
    case Variant.text:
      result = TextVariantThemeTextColourMap[theme];
      break;
    default:
      break;
  }

  return result;
};

const classes = {
  root: {
    borderRadius: 18,
    padding: '8px 14px',
    fontFamily: FontFamily,
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'none',
    minHeight: 36,
    height: 36,
    boxShadow: 'none',
  },
};
const useStyles = makeStyles({
  button: {
    backgroundColor: (props: ButtonProps) => resolveBackgroundColourPalette(props.variant, props.theme).normal,
    color: (props: ButtonProps) => resolveTextColourPalette(props.variant, props.theme).normal,
    border: (props: ButtonProps) => `1px solid ${resolveBorderColourPalette(props.variant, props.theme).normal}`,

    '&:hover': {
      backgroundColor: (props: ButtonProps) => resolveBackgroundColourPalette(props.variant, props.theme).hovered,
      color: (props: ButtonProps) => resolveTextColourPalette(props.variant, props.theme).hovered,
      border: (props: ButtonProps) => `1px solid ${resolveBorderColourPalette(props.variant, props.theme).hovered}`,
    },
    '&:active': {
      backgroundColor: (props: ButtonProps) => resolveBackgroundColourPalette(props.variant, props.theme).pressed,
      color: (props: ButtonProps) => resolveTextColourPalette(props.variant, props.theme).pressed,
      border: (props: ButtonProps) => `1px solid ${resolveBorderColourPalette(props.variant, props.theme).pressed}`,
    },
    '&:focus': {
      backgroundColor: (props: ButtonProps) => resolveBackgroundColourPalette(props.variant, props.theme).pressed,
      color: (props: ButtonProps) => resolveTextColourPalette(props.variant, props.theme).pressed,
      border: (props: ButtonProps) => `1px solid ${resolveBorderColourPalette(props.variant, props.theme).pressed}`,
    },

    '&:disabled': {
      backgroundColor: (props: ButtonProps) => resolveBackgroundColourPalette(props.variant, props.theme).disabled,
      color: (props: ButtonProps) => resolveTextColourPalette(props.variant, props.theme).disabled,
      border: (props: ButtonProps) => `1px solid ${resolveBorderColourPalette(props.variant, props.theme).disabled}`,
    },
  },
});

export default { classes, useStyles };
