import CreateMuiTypographyVariantComponent from './Typography.factory';

export { Variant as TypographyVariant } from './Typography.types';
export default {
  H1: CreateMuiTypographyVariantComponent('h1'),
  H2: CreateMuiTypographyVariantComponent('h2'),
  H3: CreateMuiTypographyVariantComponent('h3'),
  H4: CreateMuiTypographyVariantComponent('h4'),
  H5: CreateMuiTypographyVariantComponent('h5'),
  H6: CreateMuiTypographyVariantComponent('h6'),
  P1: CreateMuiTypographyVariantComponent('body1'),
  P2: CreateMuiTypographyVariantComponent('body2'),
};
