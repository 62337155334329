import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { type: '', data: {}, open: false };

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<any>) => {
      const { snackbarType, snackbarData } = action.payload;

      state.open = true;
      state.type = snackbarType;
      state.data = snackbarData;
    },
    hideSnackbar: (state) => {
      state.open = false;
      state.type = '';
      state.data = {};
    },
  },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
const { reducer } = snackbarSlice;
export default reducer;
