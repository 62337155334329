import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  splashContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  centers: {
    color: '#38363a',
    textAlign: 'center',
    position: 'relative',
    bottom: '75px',         
    }, 
});

export default { useStyles };