import { makeStyles, createStyles } from '@material-ui/core/styles';

import Colours from '../../Theme/Colours';

const classes = createStyles({});
const useStyles = makeStyles({
  container: {
    width: 386,
    minWidth: 380,
    backgroundColor: Colours.Backgrounds.white,
  },
});

export default { classes, useStyles };
