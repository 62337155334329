import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  historyContainer: {
    minWidth: '200px !important',
    maxWidth: 260,
    '& > div:nth-child(1)': {
      height: '100%',
      width: 386,
    },
  },
  summaryHistoryBody: {
    paddingLeft: 0,
  },
  historyFooter: {
    justifyContent: 'center',
  },
  historyButton: {
    color: '#0339C4',
    fontSize: 14,
  },
});

export default { useStyles };
