import React, { forwardRef, useCallback } from "react";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faMicrophone,
  faMicrophoneSlash,
  faPhoneArrowRight,
  faPlay,
} from "@fortawesome/pro-solid-svg-icons";
import { CallActivityDirectionEnum } from "../../../../Enums/CallActivityActionType";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";

import Avatar, { AvatarVariant, AvatarSize } from "../../../Avatar";
import Typography, { TypographyVariant } from "../../../Typography";
import Buttons, {
  CallButtonTheme,
  CallIconButtonSize,
} from "../../private/Buttons";
import { useAppSelector } from "../../../../app/hooks";
import SaleforceService from "../../../../Services/SaleforceService";
import Button, { ButtonVariant } from '../../../Button';

import {
  ContactViewProps,
  ButtonsContainerVisibleMap,
  CallTimeIconVisibleMap,
  CallForwardButtonsDisabledState,
} from "./ContactView.types";
import ContactViewThemer from "./ContactView.theme";

const ContactView = forwardRef<HTMLDivElement, ContactViewProps>(
  (props, ref) => {
    const {
      id,
      callTime,
      isCallRecording,
      actionButtonDisable,
      isMicMute,
      isOnHold,
      callState,
      onCallHold,
      onCallMute,
      onCallForwardButtonClicked,
    } = props;

    const classes = ContactViewThemer.useStyles();
    const currentActiveCallRecordId = useAppSelector(
      (state: any) => state.callCentre.currentActiveCallRecordId
    );
    const callDetailsMap = useAppSelector((state: any) => state.callCentre.callDetailsMap);
    const callHoldLoading = useAppSelector((state: any) => state.callState.callHoldLoading);
    const callingContact = useAppSelector((state: any) => state.callingContact.data);
    const incomingCallData = useAppSelector(state => state.callCentre.incomingCallInfo);
    const outgoingCallContacts = useAppSelector((state: any) => state.contactSearchByPhone.data);

    const callDetails = callDetailsMap?.[currentActiveCallRecordId];
    const contactDetails = callDetails?.metadata;
    let contactName= contactDetails?.contactNumber;
    
   if (callingContact?.remoteId) {
      contactName = `${callingContact?.firstName ?? ''} ${callingContact?.lastName ?? ''}`
    } else {
      contactName = 'Unknown Caller';
    }

    const showCallControlButtons = ButtonsContainerVisibleMap[callState];
    const showCallTimeIcon = CallTimeIconVisibleMap[callState];
    const forwardDisable =
      CallForwardButtonsDisabledState[callState] || isOnHold;

      let matchContactCount = 0;

      if (contactDetails?.callDirection === CallActivityDirectionEnum.outgoing) {
        matchContactCount = outgoingCallContacts?.length || 0;
      } else {
        matchContactCount = incomingCallData?.contacts?.length || 0;
      }

    const handleCallerAvatarClick = useCallback(() => {
      let contactRemoteId = callDetails?.contactRemoteId;
      if (!contactRemoteId && callingContact) {
        contactRemoteId = callingContact?.remoteId;
      }
      // Navigate to SF object details
      if (contactRemoteId) {
        SaleforceService.navigateToObject(contactRemoteId);
      } else {
        SaleforceService.callingObjectNavigation(
          contactDetails?.contactNumber,
          contactDetails?.contactId,
          contactDetails?.callDirection === CallActivityDirectionEnum.outgoing
            ? true
            : false
        );
      }
    }, []);

    const didPressedMoreContacts = () => {
      if (contactDetails?.contactNumber) {
        SaleforceService.callingObjectNavigation(contactDetails?.contactNumber, '', true)
      }
    };

    return (
      <div ref={ref} className={classes.contactViewContainer}>
        <div onClick={handleCallerAvatarClick}>
          <Avatar
            id={`${id}-Contact-Avatar`}
            alt="Contact Avatar"
            url={null}
            fallbackLabel={contactName}
            variant={AvatarVariant.circular}
            size={AvatarSize.large}
            className={classes.contactAvatar}
            fallbackTextClassName={classes.avatarFallbackLabel}
          />
        </div>
        <div className={classes.contactNameContainer}>
          <Typography.H3
            id={`${id}-Contact-Name`}
            variant={TypographyVariant.light}
            className={classes.contactName}
            noWrap
          >
            {contactName}
          </Typography.H3>
          {matchContactCount > 1 && (
            <Button
              id={`${id}-Contact-Count`}
              style={{ color: '#FFFFFF' }}
              variant={ButtonVariant.text}
              onClick={didPressedMoreContacts}
            >
              {`(${matchContactCount - 1} other contacts)`}
            </Button>
          )}
        </div>
        <div className={classes.callTimeContainerCommon}>
          {showCallTimeIcon && (
            <FontAwesomeIcon
              icon={faCircle as IconProp}
              className={classes.callStateIndicator}
              color={
                isCallRecording
                  ? "#FF5B60"
                  : ContactViewThemer.CallTimeIconColourMap[callState]
              }
            />
          )}
          <Typography.H4
            variant={TypographyVariant.light}
            className={classes.timeText}
          >
            {callTime}
          </Typography.H4>
        </div>
        {showCallControlButtons && (
          <div className={classes.buttonContainer}>
            <Buttons.CallControlButton
              id={`${id}-Call-Hold-Button`}
              theme={CallButtonTheme.default}
              size={CallIconButtonSize.large}
              onClick={onCallHold}
              disabled={callHoldLoading || actionButtonDisable}
            >
              {isOnHold ? (
                <FontAwesomeIcon icon={faPlay as IconProp} style={{ color: "#ffffff" }} />
              ) : (
                <PhonePausedIcon />
              )}
            </Buttons.CallControlButton>
            <Buttons.CallControlButton
              id={`${id}-Call-Mute-Button`}
              className={classes.callControlButtons}
              theme={CallButtonTheme.default}
              size={CallIconButtonSize.large}
              onClick={onCallMute}
              disabled={isOnHold}
            >
              <FontAwesomeIcon
                icon={isMicMute ? faMicrophoneSlash as IconProp : faMicrophone as IconProp}
                style={{ color: "#ffffff" }}
              />
            </Buttons.CallControlButton>
            <Buttons.CallControlButton
              id={`${id}-Call-Forward-Button`}
              className={classes.callControlButtons}
              theme={CallButtonTheme.default}
              size={CallIconButtonSize.large}
              onClick={onCallForwardButtonClicked}
              disabled={forwardDisable}
            >
              <FontAwesomeIcon
                icon={faPhoneArrowRight as IconProp}
                style={{ color: "#ffffff" }}
              />
            </Buttons.CallControlButton>
          </div>
        )}
      </div>
    );
  }
);

ContactView.defaultProps = {
  contactCount: 0,
};

export default ContactView;
