import { makeStyles, createStyles } from '@material-ui/core/styles';

import { ScheduledCallsCardRowLoadingProps } from './ScheduledCallsCardRowLoading.types';

const Background = { normal: 'rgba(255, 255, 255, 1)', inverse: 'rgba(240, 248, 255, 1)' };

const classes = createStyles({});
const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    paddingLeft: 18,
    paddingRight: 18,
    width: (props: ScheduledCallsCardRowLoadingProps) => props.miniVersion ? '100%' : 90,
    justifyContent: 'space-between',
  },
  rowNormal: { backgroundColor: Background.normal },
  rowInverse: { backgroundColor: Background.inverse },

  columnAvatar: { width: 40 },
  avatar: { width: 36, height: 36 },
  
  columnContactDetails: {
    flex: 1,
    minWidth: 100,
    maxWidth: '32%',
    marginLeft: '8%',
  },
  columnTimestamp: {
    flex: 1,
    minWidth: 62,
    maxWidth: '22%',
    marginLeft: '15%',
  },

  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default { classes, useStyles };
