import React, { forwardRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { ButtonProps, Theme, Variant } from './Button.types';
import ButtonThemer from './Button.theme';

const ThemedButton = withStyles(ButtonThemer.classes as any)(Button);

const ButtonComponent = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    id,
    ariaOwns,
    ariaHasPopup,
    ariaControls,
    className,
    style,
    children,
    onClick,
    callbackValue,
    disabled,
    href,
    startIcon,
    endIcon,
    type,
    fullWidth,
    onMouseDown,
    onTouchStart,
    onMouseUp,
    onMouseLeave,
    onTouchEnd,
    onMouseEnter,
    onMouseOver,
  } = props;
  const classes = ButtonThemer.useStyles(props);
  const handleClick = useCallback(
    (e: any) => {
      const event = e;
      onClick?.(callbackValue, event);
    },
    [onClick, callbackValue]
  );

  return (
    <ThemedButton
      id={id}
      ref={ref}
      aria-owns={ariaOwns}
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopup}
      className={classnames(classes.button, className)}
      style={style}
      onClick={handleClick}
      disableRipple
      disableElevation
      disableFocusRipple
      disabled={disabled}
      href={href}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      fullWidth={fullWidth}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onTouchEnd}
      onMouseEnter={onMouseEnter}
      onMouseOver={onMouseOver}
    >
      {children}
    </ThemedButton>
  );
});

ButtonComponent.defaultProps = {
  className: undefined,
  style: undefined,
  theme: Theme.primary,
  variant: Variant.normal,
  onClick: () => {},
  callbackValue: undefined,
  disabled: false,
  href: undefined,
  startIcon: undefined,
  endIcon: undefined,
  type: 'button',
  fullWidth: false,
  ariaOwns: undefined,
  ariaHasPopup: undefined,
  ariaControls: undefined,
};

export { Theme as ButtonTheme, Variant as ButtonVariant } from './Button.types';
export default ButtonComponent;
