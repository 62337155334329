import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../Theme/Colours';

const useStyles = makeStyles({
  card: {
    minWidth: '408px',
    maxWidth: '408px',
    minHeight: '593px',
    backgroundColor: '#001932',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.15)',
    borderRadius: '24px',
  },
  header: {
    height: '84px',
    background: 'rgba(255, 255, 255, 0.15)',
    padding: '30px 24px',
    borderRadius: '24px 24px 0px 0px',
  },
  footer: {
    height: '72px',
    background: 'rgba(255, 255, 255, 0.1)',
    // borderRadius: '0px 0px 24px 24px',
  },
  contactSearchArea: {
    minHeight: 414,
    height: 414,
    maxHeight: 414,
    background: 'transparent',
  },
  closeIcon: {
    position: 'relative',
    float: 'right',
    top: '-40px',
    '& > *': {
      color: '#BBBBBB',
    },
  },
  button: {
    textAlign: 'center',
    width: '50%',
    background: 'rgba(255, 255, 255, 0.1)',
    '&:disabled': {
      background: 'rgba(255, 255, 255, 0.1)',
      opacity: '50%',
      color: '#FFFFFF',
    },
    '&:hovered': {
      backgound: Colours.Iconography.Dark.hovered,
    },
  },
  buttonTypography: {
    color: '#FFFFFF',
    '&:hover': {
      color: '#DADADA',
    },
    '&:active': {
      color: '#DADADA',
    },
    '&:focus': {
      color: '#DADADA',
    },
  },
  inputWrapper: {
    margin: '24px',
  },
  input: {},
  dialpadButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30,
    marginBottom: 9,

    '&:hover': {
      cursor: 'pointer',
      '& > h4': { color: '#0257D7' },
      '& > span': { color: '#FFFFFF', borderColor: '#0257D7', backgroundColor: '#0257D7' },
    },
    '&:active': {
      '& > h4': { color: '#00409F' },
      '& > span': { color: '#FFFFFF', borderColor: '#00409F', backgroundColor: '#00409F' },
    },
    '&:focus': {
      '& > h4': { color: '#00409F' },
      '& > span': { color: '#FFFFFF', borderColor: '#00409F', backgroundColor: '#00409F' },
    },
  },
  dialpadIcon: {
    width: 24,
    height: 24,
    color: '#158BEA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #158BEA',
    borderRadius: '50%',
  },
  dialpadText: {
    marginLeft: 12,
    color: '#158BEA',
  },
  numberPad: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 0,
    height: '100%',
    maxHeight: 72,
    minHeight: 60,
    padding: 0,
    minWidth: 'unset',
    width: '100%',
  },
  dialpad: {
    maxWidth: 408,
    minHeight: 437,

    '& > div:first-child': { maxWidth: 408, height: 72 },
    '& > div:last-child': {
      width: 252,
      margin: '9px 78px',

      '& > button': { margin: '15px 18px' },
    },
  },
});

export default { useStyles };
