export const SkipWrapUpEnum = Object.freeze({
  remindToDoLater: '1',
  nothingToReport: '2',
  badTiming: '3',
  badNumber: '4',
});

export const FinalizeEnum = Object.freeze({
  remindToDoLater: 'RemindToDoLater',
  nothingToReport: 'NothingToReport',
  badTiming: 'BackToCallPlan',
  completed: 'Completed',
});
