import React, { forwardRef, useState } from 'react';
import classNames from 'classnames';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonVariant } from '../Button';

import { MessageBannerProps } from './MessageBanner.types';
import MessageBannerThemer from './MessageBanner.theme';


const MessageBannerComponent: React.FC<MessageBannerProps> = forwardRef<HTMLSpanElement, MessageBannerProps>((props, ref) => {
  const { bannerClassName, message, status, hideDismiss } = props;
  const [isBarVisible, setIsBarVisible] = useState(true);

  const classes = MessageBannerThemer.useStyles();

  const handleDismissBar = () => {
    setIsBarVisible(false);
  };

  return (
    <div className={classNames(classes.parentBanner, { [classes.hideBar]: !isBarVisible })}>
      <div className={classNames(classes.messageContainer, bannerClassName)}>
        <div>
          {message}
        </div>
        {
          !(status === 'error' || hideDismiss) ?
            <div className={classes.rightSec}>
              <div className={classes.btnText}>Dismiss</div>
              <Button
                id="Message-Banner-Dismiss-Button"
                onClick={handleDismissBar}
                variant={ButtonVariant.text}
              >
                <FontAwesomeIcon icon={faTimes as IconProp} />
              </Button>
            </div> : null
        }
      </div>
    </div>
  );
});

MessageBannerComponent.defaultProps = {
  bannerClassName: undefined,
  hideDismiss: false,
};

export default MessageBannerComponent;
