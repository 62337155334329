import { makeStyles } from '@material-ui/core/styles';

import { SearchInputProps, Theme, Variant } from './SearchInput.types';

import Colours from '../../Theme/Colours';
import FontFamily from '../../Theme/FontFamily';

const ThemeBackgroundColourMap = {
  [Theme.default]: {
    normal: Colours.Backgrounds.white,
    disabled: Colours.Backgrounds.field,
  },
  [Theme.primary]: {
    normal: Colours.Backgrounds.white,
    disabled: Colours.Backgrounds.field,
  },
  [Theme.secondary]: {
    normal: Colours.Backgrounds.white,
    disabled: Colours.Backgrounds.field,
  },
  [Theme.inverse]: {
    normal: 'transparent',
    disabled: 'transparent',
  },
};
const ThemeBorderColourMap = {
  [Theme.default]: {
    normal: '#4C4C4C',
    hovered: '#4C4C4C',
    focused: '#4C4C4C',
    disabled: '#D1D4D8',
    error: Colours.Auxiliary.dangerForeground,
  },
  [Theme.primary]: {
    normal: '#D1D4D8',
    hovered: '#D1D4D8',
    focused: Colours.PrimaryA.pressed,
    disabled: '#D1D4D8',
    error: Colours.Auxiliary.dangerForeground,
  },
  [Theme.secondary]: {
    normal: '#D1D4D8',
    hovered: '#D1D4D8',
    focused: Colours.PrimaryB.pressed,
    disabled: '#D1D4D8',
    error: Colours.Auxiliary.dangerForeground,
  },
  [Theme.inverse]: {
    normal: Colours.Backgrounds.white,
    hovered: Colours.Backgrounds.white,
    focused: Colours.Backgrounds.white,
    disabled: '#D1D4D8',
    error: Colours.Backgrounds.white,
  },
};
const ThemeIconColourMap = {
  [Theme.default]: {
    normal: '#4C4C4C',
    hovered: '#4C4C4C',
    focused: '#4C4C4C',
    disabled: '#4C4C4C',
  },
  [Theme.primary]: {
    normal: 'rgba(21, 139, 234, 1)',
    hovered: Colours.PrimaryA.hovered,
    focused: Colours.PrimaryA.pressed,
    disabled: Colours.Typography.system,
  },
  [Theme.secondary]: {
    normal: Colours.PrimaryB.normal,
    hovered: Colours.PrimaryB.hovered,
    focused: Colours.PrimaryB.pressed,
    disabled: Colours.Typography.system,
  },
  [Theme.inverse]: {
    normal: Colours.Backgrounds.white,
    hovered: Colours.Backgrounds.white,
    focused: Colours.Backgrounds.white,
    disabled: '#D1D4D8',
  },
};

const variantBorderRadiusMap = {
  [Variant.rounded]: 18,
  [Variant.square]: 6,
  [Variant.flat]: 0,
};
const resolveVariant = (variant?: Variant) => variant ?? Variant.rounded;
const resolveVariantBorderRadius = (variant?: Variant) => variantBorderRadiusMap[resolveVariant(variant)];

const createTextFieldStyles = (theme: Theme) => makeStyles({
  root: {
    backgroundColor: ThemeBackgroundColourMap[theme].normal,
    borderRadius: (props: SearchInputProps) => resolveVariantBorderRadius(props.variant),

    '& $notchedOutline': {
      borderColor: ThemeBorderColourMap[theme].normal,
      borderRadius: (props: SearchInputProps) => resolveVariantBorderRadius(props.variant),
      borderWidth: 1,
    },
    '&:hover $notchedOutline': {
      borderColor: ThemeBorderColourMap[theme].hovered,
      borderRadius: (props: SearchInputProps) => resolveVariantBorderRadius(props.variant),
      borderWidth: 1,
    },
    '&$focused $notchedOutline': {
      borderColor: ThemeBorderColourMap[theme].focused,
      borderRadius: (props: SearchInputProps) => resolveVariantBorderRadius(props.variant),
      borderWidth: 1,
    },
    '&$disabled $notchedOutline': {
      borderColor: ThemeBorderColourMap[theme].disabled,
      borderRadius: (props: SearchInputProps) => resolveVariantBorderRadius(props.variant),
      borderWidth: 1,
    },
    '&$error $notchedOutline': {
      borderColor: ThemeBorderColourMap[theme].error,
      borderRadius: (props: SearchInputProps) => resolveVariantBorderRadius(props.variant),
      borderWidth: 1,
    },

    '& div:nth-child(1)': {
      color: ThemeIconColourMap[theme].normal,
    },
    '&:hover div:nth-child(1)': {
      color: ThemeIconColourMap[theme].hovered,
    },
    '&$focused div:nth-child(1)': {
      color: ThemeIconColourMap[theme].focused,
    },
    '&$disabled div:nth-child(1)': {
      color: ThemeIconColourMap[theme].disabled,
    },
  },
  input: {
    height: 21,
    padding: '8px 12px 8px 0',
    fontFamily: FontFamily,
    fontSize: 14,
    color: '#FFFFFF !important',
  },
  focused: {},
  disabled: {
    borderRadius: (props: SearchInputProps) => resolveVariantBorderRadius(props.variant),
    backgroundColor: ThemeBackgroundColourMap[theme].disabled,
  },
  error: {},
  notchedOutline: {},
});

const useDefaultTextFieldStyles = createTextFieldStyles(Theme.default);
const usePrimaryTextFieldStyles = createTextFieldStyles(Theme.primary);
const useSecondaryTextFieldStyles = createTextFieldStyles(Theme.secondary);
const useInverseTextFieldStyles = createTextFieldStyles(Theme.inverse);

const useStyles = makeStyles({
  searchInput: {
    width: '100%',
    height: 36,
    color: '#FFFFFF',
  },

  clearIconButton: {
    height: 24,
    width: 24,
    padding: 6,
  },
});

export default {
  useDefaultTextFieldStyles,
  usePrimaryTextFieldStyles,
  useSecondaryTextFieldStyles,
  useInverseTextFieldStyles,
  useStyles,
};
