import { store } from '../../app/store';
import { CallBarState } from '../../constants/CallBarState';
import { updateCallBarState, setCallRecordingStatusData, setCallRecordingConsentData } from '../../slices/callCentre';
import TwilioVoiceService from '../../Services/TwilioVoiceService';

//const LogTitle = 'CallActivityService';

export default class CallActivityService {

  static startRecording = (callRecordId: string) => {
    const shouldRecordCall = store.getState()?.settings?.tenantSettings?.recordAllCalls || false;

    if (shouldRecordCall) {
      store.dispatch(setCallRecordingStatusData({callRecordId: callRecordId, status: true}));
      store.dispatch(setCallRecordingConsentData({callRecordId: callRecordId, consent: true}));

      // if we have permission we start recording now it self, otherwise we start recording
      // when the user gives the consent.
      TwilioVoiceService.startRttStreaming(callRecordId);
    }
  }

  static resolveCallInProgressStatusChange = (notification: any) => {
    const { id } = notification;
    
    store.dispatch(updateCallBarState(CallBarState.IN_PROGRESS));
    this.startRecording(id);
  }
}
