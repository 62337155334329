import { makeStyles } from '@material-ui/core/styles';

import { AvatarService } from '../../Services/Avatar';
import FontFamily from '../../Theme/FontFamily';

import { AvatarProps, Size } from './Avatar.types';

const fontFamily = FontFamily;

const avatarClasses = {};
const useAvatarStyles = makeStyles({
  default: {
    backgroundColor: (props: AvatarProps) =>
      AvatarService.getBackgroundColour(props.fallbackLabel, props.fallbackBackgroundColour),
    border: (props: AvatarProps) => (props.borderColor ? `1px solid ${props.borderColor} !important` : 'none'),
  },
  [Size.small]: { width: 24, height: 24 },
  [Size.medium]: { width: 36, height: 36 },
  [Size.large]: { width: 48, height: 48 },
});

const fallbackTextClasses = {};
const useFallbackTextStyles = makeStyles({
  default: {
    color: (props: AvatarProps) =>
      AvatarService.getTextColour(props.fallbackLabel, props.fallbackBackgroundColour),
  },
  [Size.small]: { fontSize: 12 },
  [Size.medium]: { fontSize: 12 },
  [Size.large]: { fontSize: 20 },
});

const useMultiLineTooltipStyles = makeStyles({
  wrapper: {},
  text: {
    fontFamily,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    display: 'inline-block',
  },
});

export default { avatarClasses, useAvatarStyles, fallbackTextClasses, useFallbackTextStyles, useMultiLineTooltipStyles };
