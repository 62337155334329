import { makeStyles } from '@material-ui/core/styles';

const classes = {
  root: {
    padding: 16,
  },
};

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: 14,
    borderRadius: '18px !important',
  },
  title: {
    marginBottom: 24,
  },
  footer: {
    textAlign: 'right',
    marginTop: 30,
  },
  dangerButton: {
    backgroundColor: 'rgba(222, 30, 23, 1)',

    '&:hover': {
      backgroundColor: 'rgba(207, 16, 12, 1)',
    },
    '&:active': {
      backgroundColor: 'rgba(180, 1, 1, 1)',
    },
    '&:focus': {
      backgroundColor: 'rgba(180, 1, 1, 1)',
    },
    '&:disabled': {
      backgroundColor: 'rgba(245, 187, 185, 1) !important',
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
});

export default { classes, useStyles };
