import React, { forwardRef } from 'react';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { TypographyProps, Variant } from './Typography.types';
import TypographyTheme from './Typography.theme';

type MuiVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2';

const ThemedTypography = withStyles(TypographyTheme.classes)(Typography);
const VariantMapping: Partial<Record<MuiVariant, string>> = {
  'body1': 'span',
  'body2': 'span',
};

export default (muiVariant: MuiVariant) => {
  const TypographyVariantComponent: React.FC<TypographyProps> = forwardRef<HTMLSpanElement, TypographyProps>((props, ref) => {
    const {
      id, className, style, children,
      variant, bottomMargin, noWrap,
    } = props;
    const classes = TypographyTheme.useStyles();
    const variantClass = classes[variant ?? Variant.label];
  
    return (
      <ThemedTypography
        id={id}
        ref={ref}
        className={classnames(className, variantClass)}
        style={style}
        variant={muiVariant as any}
        gutterBottom={!!bottomMargin}
        noWrap={noWrap}
        variantMapping={VariantMapping}
      >
        {children}
      </ThemedTypography>
    );
  });

  TypographyVariantComponent.defaultProps = {
    id: undefined,
    children: '',
    className: '',
    style: undefined,
    variant: Variant.label,
    bottomMargin: false,
    noWrap: false,
  };

  return TypographyVariantComponent;
};
