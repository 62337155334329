import React, { forwardRef } from 'react';
import classnames from 'classnames';

import Typography from '../Typography';
import emptyStateImg from '../../assets/emptystates/empty-0.png';

import { SummaryCardEmptyStateProps } from './SummaryCardEmptyState.types';
import SummaryCardEmptyStateThemer from './SummaryCardEmptyState.theme';

const SummaryCardEmptyStateComponent = forwardRef<HTMLDivElement, SummaryCardEmptyStateProps>((props, ref) => {
  const {
    id, className, style, title,
    paragraph, bodyClassName,
  } = props;
  const classes = SummaryCardEmptyStateThemer.useStyles(props);

  return (
    <div
      id={id}
      ref={ref}
      className={classnames(className)}
      style={style}
    >      
      <Typography.H3 className={classes.title}>
        {title ?? 'Take it Breezy!'}
      </Typography.H3>
      <div className={classnames(bodyClassName, classes.summaryEmptyBody)}>
        <Typography.P1 style={{ color: '#222222' }}>
           {paragraph}
        </Typography.P1>
        <div className={classes.emptyStateImageContainer}>
          <img src={emptyStateImg} alt='Empty' className={classes.emptyStateImage} />
        </div>
      </div>
    </div>
  );
});

SummaryCardEmptyStateComponent.defaultProps = {
  className: undefined,
  bodyClassName: undefined,
  style: undefined,
  children: undefined,
  title: undefined,
  paragraph: undefined,
  width: 396,
  height: undefined,
  backgroundImage: undefined,
};

export default SummaryCardEmptyStateComponent;
