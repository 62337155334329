import React, { forwardRef, useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { AvatarService } from '../../Services/Avatar';
import Typography, { TypographyVariant } from '../Typography';
import Tooltip from '../Tooltip';

import { AvatarProps, Variant, Size } from './Avatar.types';
import AvatarThemer from './Avatar.theme';

const resolveImgProps = (imgStyles?: React.CSSProperties) => ({ styles: imgStyles });

const ThemedAvatar = withStyles(AvatarThemer.avatarClasses)(Avatar);
const ThemedTypography = withStyles(AvatarThemer.fallbackTextClasses)(Typography.H3);

// We use the fallback mechanics of MaterialUI Avatar
// Check this link for more info
// https://material-ui.com/components/avatars/#fallbacks
const AvatarComponent = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  const {
    id,
    alt,
    url,
    fallbackLabel,
    className,
    style,
    imgStyle,
    fallbackTextClassName,
    fallbackTextStyle,
    variant,
    size,
    disableToolTip,
    isMultiLineTooltip,
  } = props;
  const [imgProps, setImgProps] = useState(resolveImgProps(imgStyle));
  const avatarClasses = AvatarThemer.useAvatarStyles(props);
  const fallbackTextClasses = AvatarThemer.useFallbackTextStyles(props);
  const multiLineTooltipClasses = AvatarThemer.useMultiLineTooltipStyles();
  const avatarSizeClass = avatarClasses[size || Size.medium];
  const fallbackTextSizeClass = fallbackTextClasses[size || Size.medium];
  const initials = AvatarService.getInitials(fallbackLabel);

  // Making sure we cater to updates to imgStyle
  useEffect(() => {
    setImgProps(resolveImgProps(imgStyle));
  }, [imgStyle]);

  const avatarNode = (
    <ThemedAvatar
      id={id}
      ref={ref}
      className={classnames(avatarClasses.default, avatarSizeClass, className)}
      style={style}
      imgProps={imgProps as any}
      alt={alt}
      src={url ?? undefined}
      variant={variant}
    >
      <ThemedTypography
        className={classnames(fallbackTextClasses.default, fallbackTextSizeClass, fallbackTextClassName)}
        style={fallbackTextStyle}
      >
        {initials}
      </ThemedTypography>
    </ThemedAvatar>
  );

  const result = disableToolTip ? (
    avatarNode
  ) : (
    <Tooltip
      id={`${id}-FallbackLabel-Tooltip-Wrapper`}
      className={multiLineTooltipClasses.wrapper}
      content={
        !isMultiLineTooltip ? 
        <Typography.P2 variant={TypographyVariant.light}>{fallbackLabel}</Typography.P2> :
        <p className={multiLineTooltipClasses.text} dangerouslySetInnerHTML={{ __html: fallbackLabel }}/>
      }
      placement="bottom"
      arrow
    >
      {avatarNode}
    </Tooltip>
  );

  return result;
});

AvatarComponent.defaultProps = {
  url: undefined,
  className: undefined,
  style: undefined,
  imgStyle: undefined,
  fallbackBackgroundColour: undefined,
  fallbackTextClassName: undefined,
  fallbackTextStyle: undefined,
  borderColor: undefined,
  variant: Variant.circular,
  size: Size.medium,
  disableToolTip: false,
  isMultiLineTooltip: false,
};

export { Variant as AvatarVariant, Size as AvatarSize } from './Avatar.types';
export default AvatarComponent;
