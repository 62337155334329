import React, { forwardRef } from 'react';

import { ButtonsProps, ButtonTypes } from './Buttons.types';
import CallControlButton from './CallControlButton';
import CallActionButton from './CallActionButton';
import DiallerCallActionButton from './DiallerCallActionButton';

const ButtonTypesMap = {
  [ButtonTypes.callControlButton]: CallControlButton,
  [ButtonTypes.callActionButton]: CallActionButton,
  [ButtonTypes.diallerCallActionButton]: DiallerCallActionButton,
};

type ButtonType = 'CallControlButton' | 'CallActionButton' | 'DiallerCallActionButton';

export default (buttonType: ButtonType) => {
  const ButtonsTypeComponent: React.FC<ButtonsProps> = forwardRef<HTMLButtonElement, ButtonsProps>((props, ref) => {
    const { 
      id, className, style, size, theme, disabled, onClick, 
      onMouseDown, onTouchStart, onMouseUp, onMouseLeave, onTouchEnd, 
      callbackValue, children, startIcon, endIcon, type, buttonWidth, fullWidth 
    } = props;
    const ButtonComponent = ButtonTypesMap[buttonType];

    const ButtonPropssMap = {
      [ButtonTypes.callControlButton]: { 
        id, ref, className, style, size, theme, disabled, onClick, callbackValue,
        onMouseDown, onTouchStart, onMouseUp, onMouseLeave, onTouchEnd,
      },
      [ButtonTypes.callActionButton]: {
        id, ref, className, type,
        style, size, theme, disabled,
        onClick, callbackValue, startIcon, endIcon,
      },
      [ButtonTypes.diallerCallActionButton]: {
        id, ref, className, type,
        style, size, theme, disabled,
        onClick, callbackValue, startIcon, endIcon,
        buttonWidth, fullWidth,
      },
    };

    return <ButtonComponent {...ButtonPropssMap[buttonType]}>{children}</ButtonComponent>;
  });

  ButtonsTypeComponent.defaultProps = {
    id: undefined,
    children: '',
    className: undefined,
    style: undefined,
    disabled: false,
    onClick: () => null,
    callbackValue: undefined,
    startIcon: undefined,
    endIcon: undefined,
    type: 'button',
    fullWidth: false
  };

  return ButtonsTypeComponent;
};
