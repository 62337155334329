import React, { forwardRef, useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { IconButtonProps, Theme, Size, MuiSizeMap } from './IconButton.types';
import IconButtonThemer from './IconButton.theme';

const ThemedIconButton = withStyles(IconButtonThemer.classes)(IconButton);

const IconButtonComponent = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const {
    id,
    ariaOwns,
    ariaHasPopup,
    ariaControls,
    children,
    className,
    style,
    disabled,
    theme,
    size,
    edge,
    onClick,
    callbackValue,
    onMouseDown,
    onTouchStart,
    onMouseUp,
    onMouseLeave,
    onTouchEnd,
    onMouseEnter,
    onMouseOver,
  } = props;
  const classes = IconButtonThemer.useStyles();
  const themeClassName = theme ? classes[theme] : null;
  const sizeClassName = size ? classes[size] : null;
  const muiSize = MuiSizeMap[size ?? Size.medium];
  const handleClick = useCallback((event: any) => {
    onClick?.(callbackValue, event);
  }, [onClick, callbackValue]);

  return (
    <ThemedIconButton
      id={id}
      ref={ref}
      aria-owns={ariaOwns}
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopup}
      className={classnames(themeClassName, sizeClassName, className)}
      style={style}
      disabled={disabled}
      size={muiSize as any}
      edge={edge}
      onClick={handleClick}
      disableFocusRipple
      disableRipple
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onTouchEnd}
      onMouseEnter={onMouseEnter}
      onMouseOver={onMouseOver}
    >
      {children}
    </ThemedIconButton>
  );
});

IconButtonComponent.defaultProps = {
  className: undefined,
  style: undefined,
  disabled: false,
  theme: Theme.dark,
  size: Size.medium,
  edge: undefined,
  onClick: () => null,
  callbackValue: undefined,
  ariaOwns: undefined,
  ariaHasPopup: undefined,
  ariaControls: undefined,
};

export { Theme as IconButtonTheme, Size as IconButtonSize };
export default IconButtonComponent;
