import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CallHistoryRowProps } from './CallHistoryRow.types';
import { BorderAll } from '@material-ui/icons';
const Background = { normal: 'rgba(255, 255, 255, 1)', inverse: 'rgba(240, 248, 255, 1)' };
const classes = createStyles({});
const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 60,
    paddingRight: 8,
    paddingLeft: 18,    

    '&:hover': {
      // cursor: 'pointer',
      '& $columnDurationWithCallButton': {
        display: 'none',
      },
      '& $callButton': {
        display: 'flex',

        '& > div > div:last-child': { 
          zIndex: 10,
        },
      },
    },
  },
  
  callButton: {
    display: 'none',
  },

  rowNormal: { backgroundColor: Background.normal },
  statusColumn: { alignItems: 'center', justifyContent: 'center', width: 15, height: 21},
  rowInverse: { backgroundColor: Background.inverse },
  columnAvatar: { width: 36 },
  avatar: { width: 36, height: 36 },
  colourDot: { width: 10, height: 10, alignItems: 'center', justifyContent: 'center'},
  flagIcon: {
    marginRight: '4px !important',
    width: 24,
    height: 24,
    backgroundColor: '#50CF97',
  },
  callMediumIcon: {
    backgroundColor: '#4c94ba',
    width: 32,
    height: 32,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
  },
  columnTimestamp: {
    flex: 1,
    minWidth: 62,
    maxWidth: '22%',
    marginLeft: '15px',
    textAlign: 'left',
  },
  timestampText: { maxWidth: '100%' },
  columnDuration: {
    flex: 1,
    minWidth: 55,
    maxWidth: '22%',
    marginLeft: '15px',
  },
  durationText: { maxWidth: '100%' },
  columnActions: {
    // flex: 1,
    minWidth: 78,
    width: 78,
    display: 'flex',
    marginLeft: '6%',
  },
  columnDurationWithCallButton: {
    flex: 1,
    minWidth: 98,
    width: 118,
    display: 'flex',
    marginLeft: '2%',
  },
  columnNavigation: {
    width: (props: CallHistoryRowProps<any>) => props.miniVersion ? 30 : 90,
    marginLeft: '3%',
  },
  showOnHover: {
    opacity: 0,
    marginLeft: '6%',
  },
  rowOnHover: {
    '&:hover': {
      cursor: 'pointer',
      '& > div:last-child': {
        opacity: 1,
      },
    },
  },
});
const useCallStatusBadgeStyles = makeStyles({
  badge: { bottom: 0, right: 0 },
});
export default {
  classes, useStyles,
  useCallStatusBadgeStyles,
};