const ConferenceCallEventTypes = Object.freeze({
  ConferenceStart: 'conference-start',
  ParticipantJoin: 'participant-join',
  ParticipantLeave: 'participant-leave',
  ParticipantHold: 'participant-hold',
  ParticipantUnHold: 'participant-unhold',
  ConferenceEnd: 'conference-end',
});

export default ConferenceCallEventTypes;
