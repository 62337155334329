export const CallActivityDirectionEnum = Object.freeze({
  incoming: 'IncomingCall',
  outgoing: 'OutgoingCall',
});

export const CallActivityDurationEnum = Object.freeze({
  lessThanFiveSeconds: 'lessThanFiveSeconds',
  fiveToSixtySeconds: 'fiveToSixtySeconds',
  oneToThreeMinutes: 'oneToThreeMinutes',
  threeToTenMinutes: 'threeToTenMinutes',
  tenToTwentyMinutes: 'tenToTwentyMinutes',
  twentyToSixtyMinutes: 'twentyToSixtyMinutes',
  moreThanOneHour: 'moreThanOneHour',
});

export const CallActivityStatusEnum = Object.freeze({
  complete: 'completed',
  busy: 'busy',
  noAnswer: 'no-answer',
  voicemail: 'voicemail',

  // We are not going to explicitly check for these types.
  // But putting it here for completeness' sake
  failed: 'failed',
  inProgress: 'in-progress',
  initiated: 'initiated',
  ringing: 'ringing',
});

export const CallRecordWrapUpStatusEnum = Object.freeze({
  Pending: 'Pending',
  Finalized: 'Finalized',
});

export const CallActivityMediumEnum = Object.freeze({
  phone: 'Phone',
  sms: 'SMS',
  email: 'Email',
  zoom: 'Zoom',
  teams: 'Teams',
  whatsapp: 'Whatsapp',
});

export const CallActivityActionType = Object.freeze({
  Opportunity: 'Opportunity',
  Case: 'Case',
  Note: 'Note',
  GDPRSetting: 'GDPRSetting',
  ScheduleCallback: 'ScheduleCallback',
  SMS: 'Sms',
  Email: 'Email',
  HotTransfer: 'HotTransfer',
  Voicemail: 'Voicemail',
  CallOutcome: 'CallOutcome',
});

export const CallActivityStatusLabel = Object.freeze({
  [CallActivityStatusEnum.noAnswer]: 'No Answer',
  [CallActivityStatusEnum.voicemail]: 'Voicemail',
  [CallActivityStatusEnum.failed]: 'Failed',
  [CallActivityStatusEnum.busy]: 'Busy',

  [CallActivityStatusEnum.complete]: 'Completed',
});

export const callOngoingStatuses = [CallActivityStatusEnum.initiated, CallActivityStatusEnum.ringing, CallActivityStatusEnum.inProgress];
export const callFailedStatuses = [CallActivityStatusEnum.failed, CallActivityStatusEnum.busy, CallActivityStatusEnum.noAnswer];
export const callCompletionStatuses = [
  CallActivityStatusEnum.failed,
  CallActivityStatusEnum.busy,
  CallActivityStatusEnum.noAnswer,
  CallActivityStatusEnum.complete,
];

// export const isCallOngoingStatus = callStatus => callOngoingStatuses.includes(callStatus);
// export const isCallFailedStatus = callStatus => callFailedStatuses.includes(callStatus);
// export const isCallSuccessStatus = callStatus => callStatus === CallActivityStatusEnum.complete;
// export const isCallCompletionStatus = callStatus => callCompletionStatuses.includes(callStatus);
// export const isVoicemail = callStatus => callStatus === CallActivityStatusEnum.voicemail;
