export enum CallState {
  dialing = 'dialing',
  connecting = 'connecting',
  ringing = 'ringing',
  answered = 'answered',
  ended = 'ended',
  disconnected = 'disconnected',
}

export enum RecordingState {
  paused = 'paused',
  recording = 'recording',
}

export enum CallMuteState {
  unmuted = 'unmuted',
  muted = 'muted',
}

export enum Size {
  small = 'small',
  medium = 'medium',
}
