import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Moment from 'moment';

import ScheduledCallsService from "../Services/ScheduledCalls/ScheduledCallsService";
import { RootState } from '../app/store';

const initialState: any = { data: [], count: 0, loading: true };

export const getScheduledCalls: any = createAsyncThunk(
  "getScheduledCalls/get",
  async (_, { getState }) => {
    const currentState = getState() as RootState;
    const { userId, tenantCode, AccessToken } = currentState.auth.authUser;
    const startTime = Moment().toISOString();
    const result = await ScheduledCallsService.getScheduledCalls(userId, startTime, tenantCode, AccessToken);

    return result.data;
  }
);

const scheduledCallsSlice = createSlice({
  name: 'scheduledCalls',
  initialState,
  reducers: {
    getScheduledCallsInitState(state, action: PayloadAction<any>) {
      state.data = action.payload.listData;
      state.loading = action.payload.loading;
    },
    deleteScheduleCall(state, action: PayloadAction<any>) {
      const { callEventId } = action.payload;
      console.log("deleteScheduleCall", action.payload);
      let filteredData = state.data.filter((sc: any) => sc?.callEventId !== callEventId);
      state.data = filteredData;
    },
  },
  extraReducers: {
    [getScheduledCalls.fulfilled]: (state, action) => {
      const { data, count } = action.payload;
      state.data = data;
      state.count = count;
      state.loading = false;
    },
    [getScheduledCalls.rejected]: (state, action) => {
      state.data.error = action.error;
    },
  }
});

export const { getScheduledCallsInitState, deleteScheduleCall } = scheduledCallsSlice.actions;
export default scheduledCallsSlice.reducer;
