import React from 'react';

import CallCentreApi from '../../Services/CallCentre/CallCentre';
import { store } from '../../app/store';
import CallAgentSearchService from './CallAgentSearch';

export default class CallForwardService {
  /**
   * PRIVATE
   * The reference to the React node
   */
  static _modalNodeRef = React.createRef();

  // static openModal = (callRecordId, callDirection) => {
  //   if (!this._modalNodeRef.current) {
  //     Logger.warn(LogTitle, 'CallForwardOptionsModal not initialised correctly', { callRecordId, callDirection });
  //     return;
  //   }

  //   this._modalNodeRef.current.open(callRecordId, callDirection);
  // }

  static getCallForwardOptions = CallAgentSearchService.getCallAgentOptions

  static forwardCallToUser = async (callRecordId: string, callDirection: string, destinationUserId: string) => {
    const tenantCode = store.getState()?.auth?.authUser?.tenantCode;
    const accessToken = store.getState()?.auth?.authUser?.AccessToken;

    await CallCentreApi.forwardCallToUser(tenantCode, accessToken, callRecordId, destinationUserId, callDirection);
  }

  static forwardCallToTeam = async (callRecordId: string, callDirection: string, destinationTeamId: string) => {
    const tenantCode = store.getState()?.auth?.authUser?.tenantCode;
    const accessToken = store.getState()?.auth?.authUser?.AccessToken;

    await CallCentreApi.forwardCallToTeam(tenantCode, accessToken, callRecordId, destinationTeamId, callDirection);
  }

  static forwardCallToExternalNumber = async (callRecordId: string, callDirection: string, number: any) => {
    const tenantCode = store.getState()?.auth?.authUser?.tenantCode;
    const accessToken = store.getState()?.auth?.authUser?.AccessToken;

    await CallCentreApi.forwardCallToExternalNumber(tenantCode, accessToken, callRecordId, number, callDirection);
  }
}
