import { CallState } from '../../../../Enums/Call.types';

export interface ContactViewProps {
  id: string;
  callState: CallState;
  isCallRecording: boolean;
  actionButtonDisable: boolean;
  isMicMute: boolean;
  isOnHold: boolean,
  callTime?: string;
  contactCount?: number,
  onCallHold: (callbackValue?: any) => any;
  onCallMute: (callbackValue?: any) => any;
  onCallForwardButtonClicked: (callbackValue?: any) => any;
}

export const ButtonsContainerVisibleMap = {
  [CallState.dialing]: false,
  [CallState.connecting]: false,
  [CallState.ringing]: true,
  [CallState.answered]: true,
  [CallState.ended]: false,
  [CallState.disconnected]: false,
};

export const CallTimeIconVisibleMap = {
  [CallState.dialing]: true,
  [CallState.connecting]: true,
  [CallState.ringing]: true,
  [CallState.answered]: true,
  [CallState.ended]: false,
  [CallState.disconnected]: true,
};

export const CallForwardButtonsDisabledState = {
  [CallState.dialing]: true,
  [CallState.connecting]: true,
  [CallState.ringing]: true,
  [CallState.answered]: false,
  [CallState.ended]: true,
  [CallState.disconnected]: true,
};
