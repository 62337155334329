import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TranscriptionService from '../Services/Transcription';
import BookmarkTemplateConstants from '../constants/BookmarkTemplate';

interface InterimTranscriptSourceKeyMapperInterface {
  phone: string;
  mic: string;
};

const InterimTranscriptSourceKeyMapper: InterimTranscriptSourceKeyMapperInterface = {
  phone: 'phone',
  mic: 'mic',
};

const initialState: any = {
  finalisedTranscriptionsIdList: {},
  finalisedTranscriptionsMap: {},
  captureFavouriteSegmentsIdList: [],
  capturesIdList: [],
  capturesMap: {},
  capturesTranscriptMap: {},
  processedTranscriptionsMap: {},
  interimTranscriptions: {},
  callAiSummaryMap: {data: {}, loading: true, error: null},
};

const callCapturesOfTranscript = (state: any, callRecordId: string, transcriptSegmentId: string) => {
  const capturesMap = state.capturesMap[callRecordId] || {};
  const capturesIdListOfTranscriptSegment = state.capturesTranscriptMap[callRecordId]?.[transcriptSegmentId] || [];
  const result = capturesIdListOfTranscriptSegment.map((captureId: string) => capturesMap[captureId]).filter((capture: any) => !!capture);

  return result;
};

const callTranscriptSlice = createSlice({
  name: 'callTranscript',
  initialState,
  reducers: {
    addCallTranscript(state: any, action: PayloadAction<any>) {
      const { callRecordId, transcript } = action.payload;
      const currentFinalisedTranscriptionsIdListOfCall = state.finalisedTranscriptionsIdList[callRecordId] || [];
      const currentFinalisedTranscriptionsMapOfCall = state.finalisedTranscriptionsMap[callRecordId] || {};
      const favIdList = state.captureFavouriteSegmentsIdList || [];
      const favouriteSegments = state.favouriteSegmentCapuring ? [...favIdList, transcript?.id] : [];

      state.finalisedTranscriptionsIdList = {
        [callRecordId]: [...currentFinalisedTranscriptionsIdListOfCall, transcript.id],
      };
      state.finalisedTranscriptionsMap = {
        [callRecordId]: { ...currentFinalisedTranscriptionsMapOfCall, [transcript.id]: transcript },
      };
      state.captureFavouriteSegmentsIdList = favouriteSegments;

      const bookmarkTemplatesOfTranscript = callCapturesOfTranscript(state, callRecordId, transcript.id);
      const processedTranscript = TranscriptionService.Segment.processSegment(transcript, bookmarkTemplatesOfTranscript);

      callTranscriptSlice.caseReducers.upsertProcessedTranscript(state, {callRecordId, transcriptId: transcript.id, processedTranscript});
    },
    upsertProcessedTranscript(state: any, action: any) {
      const { callRecordId, transcriptId, processedTranscript } = action;
      const currentProcessedTranscriptionsMapOfCall = state.processedTranscriptionsMap[callRecordId] || {};

      state.processedTranscriptionsMap = {
        [callRecordId]: { ...currentProcessedTranscriptionsMapOfCall, [transcriptId]: processedTranscript },
      };
    },
    setInterimTranscription(state: any, action: PayloadAction<any>) {
      const { callRecordId, transcript, source } = action.payload;
      const currentInterimTranscriptionOfCall = state.interimTranscriptions[callRecordId] || {};
      const typedSource = source as keyof InterimTranscriptSourceKeyMapperInterface;

      state.interimTranscriptions = {
        [callRecordId]: { ...currentInterimTranscriptionOfCall, [InterimTranscriptSourceKeyMapper[typedSource]]: transcript },
      };
    },
    addBookmark(state: any, action: PayloadAction<any>) {
      const { callData: { id: callRecordId, bookmarks: allBookmarks, actions: rawActions }, transcriptionSegments } = action.payload;
      const processedCapturesMap = state.capturesMap[callRecordId] || {};
      const transcriptionIdsList = (transcriptionSegments || []).map((transcriptionSegment: any) => transcriptionSegment.id);
      const capturesList: any = [];
      const bookmarkTemplatesMap: any = {};

      // Index the bookmark templates for easy access
      allBookmarks?.forEach((bookmark: any) => (bookmarkTemplatesMap[bookmark.id] = bookmark));

      // Multiple actions may trigger out of the same Bookmark Template
      // We need to index them using the bookmark template ID
      // Which is where actionTriggerId comes in
      // What we are going here is creating an object of arrays
      // The arrays are indexed using the actionTriggerId
      // By the end of this loop, we will have actions grouped by their respective bookmark template IDs
      const actionTriggerMap: any = {};
      (rawActions || [])
        // We need to make sure these actions have proper triggers and also they are not yet processed
        .filter((rawAction: any) => !!rawAction.actionTriggerId && !processedCapturesMap[rawAction.id])
        .forEach((rawAction: any) => {
          const actionsOfActionTrigger = actionTriggerMap[rawAction.actionTriggerId] || [];
          const resolvedAction = {
            id: rawAction.id,
            triggerId: rawAction.actionTriggerId,
            captureType: BookmarkTemplateConstants.Type.ActionEngine,
            type: rawAction.actionType,
            bookmarkTemplate: bookmarkTemplatesMap[rawAction.actionTriggerId]?.bookmarkTemplate,
            transcriptionSegments: bookmarkTemplatesMap[rawAction.actionTriggerId]?.transcriptionSegments || [],
          };

          actionsOfActionTrigger.push(resolvedAction);
          actionTriggerMap[rawAction.actionTriggerId] = actionsOfActionTrigger;
        });

      allBookmarks?.forEach((bookmark: any) => {
        // We receive all bookmarks here. But we should only consider the ones we haven't processed yet
        if (bookmark?.status !== 'Rejected') {
          switch (bookmark.bookmarkTemplate.bookmarkType) {
            case BookmarkTemplateConstants.Type.CallAssist:
            case BookmarkTemplateConstants.Type.Bookmark:
              !processedCapturesMap[bookmark.id] && capturesList.push({
                  id: bookmark.id,
                  triggerId: bookmark.id,
                  captureType: bookmark.bookmarkTemplate.bookmarkType,
                  type: bookmark.bookmarkTemplate.bookmarkType,
                  bookmarkTemplate: bookmark.bookmarkTemplate,
                  transcriptionSegments: bookmark.transcriptionSegments,
                });
            break;
            case BookmarkTemplateConstants.Type.ActionEngine:
              // There could be multiple actions pertaining to this ActionEngine bookmark
              // Also there could be none
              // So we need to conditionally add them to the captures e1
              capturesList.push(...(actionTriggerMap[bookmark.id] || []));
            break;
            default:
            break;
          }
        }
      });

      // If we don't have any "new" data to consider, no point going forward
      // What I mean by "new" is;
      //  - The bookmarks we get is not just the new bookmark
      //  - It's a snapshot of all bookmarks at this stage
      if (!capturesList.length) {
      //  Logger.info(LogTitle, 'Bookmark received with no data. Skipping');
        console.log('AddBookmarkFeature', 'Bookmark received with no data. Skipping');
        return;
      }

      transcriptionSegments?.forEach((transcriptionSegment: any) => {
        const transcript = state.finalisedTranscriptionsMap[callRecordId]?.[transcriptionSegment.id] || null;

        // We need to show the bookmakrs created, even if the transcription is disbaled
        if (transcript) {
          const bookmarksOfTranscript = [
            ...(callCapturesOfTranscript(state, callRecordId, transcript.id) || []),
            ...capturesList,
          ];
          const processedTranscript = TranscriptionService.Segment.processSegment(transcript, bookmarksOfTranscript);

          callTranscriptSlice.caseReducers.upsertProcessedTranscript(state, {callRecordId, transcriptId: transcript.id, processedTranscript});
        } else {
          // If we don't have the transcript yet, we are gonna skip the processing bit at this moment
          // Logger.warn(LogTitle, 'Bookmark received before transription segment. Skipping processing');
          console.log('AddBookmarkFeature', 'Bookmark received before transription segment. Skipping processing');
        }
      });

      callTranscriptSlice.caseReducers.addBookmarkResolved(state, {callRecordId, transcriptionIdsList, capturesList});
    },
    addBookmarkResolved(state: any, action: any) {
      const { callRecordId, capturesList, transcriptionIdsList } = action;
      const currentCapturesIdListOfCall = state.capturesIdList[callRecordId];
      const currentCapturesMapOfCall = state.capturesMap[callRecordId];
      const currentCapturesTranscriptMapOfCall = state.capturesTranscriptMap[callRecordId];
    
      const appendingCapturesTranscriptMap: any = {};
      (transcriptionIdsList || []).forEach((transcriptionId: string) => {
        appendingCapturesTranscriptMap[transcriptionId] = [
          ...(currentCapturesTranscriptMapOfCall?.[transcriptionId] || []),
          ...(capturesList || []),
        ];
      });
      const updatedCapturesTranscriptMap = { ...currentCapturesTranscriptMapOfCall, ...appendingCapturesTranscriptMap };
    
      const updatedCapturesIdListOfCall = {...currentCapturesIdListOfCall};
      const updatedCapturesMapOfCall = { ...currentCapturesMapOfCall };
      capturesList.forEach((capture: any) => {
        updatedCapturesIdListOfCall[capture.id] = capture;
        updatedCapturesMapOfCall[capture.id] = capture;
      });
    
      state.capturesIdList = { [callRecordId]: updatedCapturesIdListOfCall };
      state.capturesMap = { [callRecordId]: updatedCapturesMapOfCall };
      state.capturesTranscriptMap = { [callRecordId]: updatedCapturesTranscriptMap };
    },
  },
  extraReducers: {
  },
});

export const {
  addCallTranscript,
  setInterimTranscription,
  addBookmark, addBookmarkResolved,
} = callTranscriptSlice.actions;

export default callTranscriptSlice.reducer;
