export default Object.freeze({
  Contact: 'Contact',
  Case: 'Case',
  Opportunity: 'Opportunity',
  GDPRSetting: 'GDPRSetting',
  ScheduleCallback: 'ScheduleCallback',
  Sms: 'Sms',
  Email: 'Email',
  Note: 'Note',
  Vulnerability: 'Vulnerability',
  Documents: 'Documents',
  Troubleshoot: 'Troubleshoot',

  // Demo Actions
  Gdrive: 'Gdrive',
  Dropbox: 'Dropbox',
  Slack: 'Slack',
  AddToCampaign: 'AddToCampaign',
  // SMS: 'SMS',
  // Email: 'Email',
  Oracle: 'Oracle',
  SAP: 'SAP',
});
