import ApiHelper from '../../helpers/apiHelper';
import VoiceIQApiAxiosInstance from '../instances/VoiceIQAPI';
import { store } from '../../app/store';

const getTenantSettings = (tenantCode: string, AccessToken: string) => {
  const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(AccessToken, tenantCode),
    url: `/api/v1/tenant/settings/${tenantCode}`,
    method: 'get',
  };

  return VoiceIQApiAxiosInstance.request(config);
};

const getCallDispositions = (tenantCode: string, AccessToken: string) => {
  const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(AccessToken, tenantCode),
    url: `/api/v1/tenant/call-dispositions`,
    method: 'get',
  };

  return VoiceIQApiAxiosInstance.request(config);
};

const TenantService = { getTenantSettings, getCallDispositions };

export default TenantService;