import React from 'react';

export interface CallActionAvatarProps {
  id: string
  className?: string
  style?: React.CSSProperties
  iconClassName?: string
  iconStyle?: React.CSSProperties
  type: Type
  variant?: Variant
  size?: Size
  hideTooltip?: boolean
  firstAgent?: string
  secondAgent?: string
  callOutcome?: string
}

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export enum Type {
  opportunity = 'opportunity',
  case = 'case',
  note = 'note',
  vulnerability = 'vulnerability',
  gdpr = 'gdpr',
  callback = 'callback',
  voicemail = 'voicemail',
  email = 'email',
  sms = 'sms',
  incomplete = 'incomplete',
  hotTransfer = 'hotTransfer',
  callOutcome = 'callOutcome',
}

export enum Variant {
  normal = 'normal',
  inverse = 'inverse',
  light = 'light',
}
