import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../../../Theme/Colours';

const useStyles = makeStyles({
  default: {},
  dialpadContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  numberInputContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  addContactContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    height: '100%',
    minHeight: 60,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  addContactButton: {
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: 'unset',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: 'unset',
    },
  },
  numberPad: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 0,
    height: 44,
    maxHeight: 60,
    minHeight: 44,
    padding: 0,
    minWidth: 'unset',
    width: '100%',
  },
  buttonsContainer: {
    padding: '15px 24px 25px',

  },
  diallerButton: {
    margin: '13px 24px',
  },
});

export default { useStyles };
