import { makeStyles, createStyles } from '@material-ui/core/styles';

const classes = createStyles({});
const useStyles = makeStyles({
  list: {
    height: 'calc(100% - 60px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 168,

    '&::-webkit-scrollbar': {
      width: '6px',
      marginRight: 6,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(209, 212, 216, 1)',
      borderRadius: 30,
      border: 'none',
    }
  },
});

export default { classes, useStyles };
