import CallCentreApi from '../../Services/CallCentre/CallCentre';

export default class CallActions {
  // static handleDtmfWithTwilio = (number) => {
  //   let digitNumber = number;

  //   if (number?.length > 1) {
  //     digitNumber = number[number?.length - 1];
  //   }

  //   TwilioVoiceService.sendDigits(digitNumber);
  // }

  // static menuItemWithIndex = (menuItems, fromNumber) => {
  //   let selectedNo = '';
  //   let primaryText = '';
  //   let selectedIndex = 0;

  //   const menuItemWithIndex = menuItems.map((menu, index) => ({
  //     primaryText: menu.number,
  //     id: menu.id,
  //     name: menu.name,
  //     leftIcon: menu?.leftIcon,
  //     index,
  //     className: menu?.className,
  //   }));

  //   if (menuItemWithIndex) {
  //     if (fromNumber?.charAt(0) === '+') {
  //       selectedNo = fromNumber?.substring(1);
  //     } else {
  //       selectedNo = fromNumber;
  //     }

  //     const isMenuItemElement = menuItemWithIndex?.filter(item => item?.primaryText === fromNumber);
  //     if (isMenuItemElement?.length === 0 && menuItemWithIndex?.length > 0) {
  //       const menuItemLastItem = (menuItemWithIndex?.length - 1);

  //       menuItemWithIndex[menuItemLastItem].primaryText = fromNumber;
  //     }

  //     menuItemWithIndex?.forEach((item) => {
  //       if (item?.primaryText?.charAt(0) === '+') {
  //         primaryText = item?.primaryText?.substring(1);
  //       } else {
  //         primaryText = item?.primaryText;
  //       }
  //       if ((selectedNo && primaryText) && (primaryText === selectedNo)) {
  //         selectedIndex = item?.index;
  //       }
  //     });
  //   }

  //   return ({ number: selectedNo, text: primaryText, index: selectedIndex, menuItem: menuItemWithIndex });
  // }

  // static findSelectedTeamById = (id, userTeams) => {
  //   const items = [];

  //   if (userTeams?.length > 0) {
  //     userTeams?.forEach((item) => {
  //       items.push({ id: item.id, number: item?.number?.number, name: item.name });
  //     });
  //   }

  //   const selectedTeam = items?.filter(item => item.id === id);

  //   return selectedTeam?.[0];
  // }

  static getLocalNumber = (tenantCode: string, accessToken: string, contactNumber: string) => {
    return CallCentreApi.getLocalNumber(tenantCode, accessToken, contactNumber);
  }
}
