import { makeStyles, createStyles } from '@material-ui/core/styles';

import Colours from '../../Theme/Colours';
import { CallStatusBadgeProps, StatusType } from './CallStatusBadge.types';

const CallStatusColours = { received: 'rgba(80, 207, 151, 1)', missed: 'rgba(243, 96, 90, 1)' };
const ThemeColourMap = {
  [StatusType.inboundMissed]: Colours.Danger.normal,
  [StatusType.inboundReceived]: Colours.PrimaryB.normal,
  [StatusType.outboundMissed]: Colours.Danger.normal,
  [StatusType.outboundReceived]: Colours.PrimaryB.normal,
  [StatusType.salesforce]: Colours.PrimaryB.normal,
};

const classes = createStyles({
  badge: {
    fontSize: 8,
    fontWeight: 500,
    color: Colours.Typography.light,
    width: 15,
    height: 15,
    minWidth: 15,
  },
  colorSecondary: { backgroundColor: CallStatusColours.received },
  colorError: { backgroundColor: CallStatusColours.missed },
});
const useStyles = makeStyles({
  icon: { fontSize: 10 },
  avatar: {
    width: '15px !important',    
    height: '15px !important',
    // we can remove the thi as it's not worth anymore
    backgroundColor: (props: CallStatusBadgeProps) => ThemeColourMap[props.statusType ?? Colours.PrimaryB.normal], 
  }
});

export default { classes, useStyles };
