import RollingCache from '../../Util/RollingCache';
import ColourUtil from '../../Util/Colour';
import StringUtil from '../../Util/String';

export interface AvatarInformation {
  initials: string,
  backgroundColour: string,
  textColour: string,
};

const getAvatarCacheKey = (name: string, backgroundColourOverride?: string | null) =>
  `${name}${backgroundColourOverride ? `-${backgroundColourOverride}` : ''}`;

/**
 * Avatar service
 * Handles all avatar related logic
 *
 * @type {AvatarService}
 */
class AvatarService {
  /**
   * PRIVATE
   * Cache layer
   */
  _cache = new RollingCache()

  /**
   * All background colour options
   */
  backgroundColours = ColourUtil.colourOptions

  /**
   * Gets the avatar information from given name
   */
  getAvatarInformation = (name: string, backgroundColourOverride?: string | null): AvatarInformation => {
    // If it's a cache miss, we need to resolve it and add it
    if (!this._cache.has(getAvatarCacheKey(name, backgroundColourOverride))) {
      const overrideColourValid = backgroundColourOverride && ColourUtil.isColour(backgroundColourOverride);
      const backgroundColour = overrideColourValid ?
        // If we are given a valid colour as the background override we have to use that
        // TS doesn't seem to be smart enough to understand that we are making sure it's not undefined on the previous line
        // So I'm casting it
        (backgroundColourOverride as string) :
        ColourUtil.getBackgroundColourOfString(name);

      const initials = StringUtil.initialsFromText(name)?.slice(0, 2).join('').toUpperCase();
      const textColour = ColourUtil.getContrastingTextColour(backgroundColour);
      const avatarInformation = { initials, backgroundColour, textColour };

      this._cache.add(name, avatarInformation);
    }

    return this._cache.get(name);;
  }

  /**
   * Gets the initials to display on the avatar
   */
  getInitials = (name: string, backgroundColourOverride?: string | null) =>
    this.getAvatarInformation(name, backgroundColourOverride).initials

  /**
   * Gets the background colour on the avatar
   */
  getBackgroundColour = (name: string, backgroundColourOverride?: string | null) =>
    this.getAvatarInformation(name, backgroundColourOverride).backgroundColour

  /**
   * Gets the text colour on the avatar
   */
  getTextColour = (name: string, backgroundColourOverride?: string | null) =>
    this.getAvatarInformation(name, backgroundColourOverride).textColour
};

export default new AvatarService();
