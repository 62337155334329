import React, { forwardRef } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import SummaryCardRoot from './SummaryCardRoot';
import IconTitle from '../IconTitle';
import Typography, { TypographyVariant } from '../Typography';
import ScheduledCall from '../ScheduledCall';

import { SummaryCardScheduledCallsProps } from './SummaryCardScheduledCalls.types';
import SummaryCardScheduledCallsThemer from './SummaryCardScheduledCalls.theme';

const DefaultPlaceholderItemsCount = 3;

const Title = (props: any) => {
  const { textColor, iconBgColor, title, scheduledCallsCount, scheduleIconOnClick, renderScheduleIcon } = props;

  return (
    <IconTitle
      icon={renderScheduleIcon}
      iconBackgroundColor={iconBgColor}
      iconOnClick={scheduleIconOnClick}
    >
      <Typography.H3 style={{ color: textColor, paddingLeft: 10 }} variant={TypographyVariant.active}>
        {title ??  `Today's Scheduled Calls `}
        <>{(scheduledCallsCount > 0) && `(${scheduledCallsCount})`}</>
      </Typography.H3>
    </IconTitle>   
  );
};

const SummaryCardScheduledCallsComponent = forwardRef<HTMLDivElement, SummaryCardScheduledCallsProps<any>>((props, ref) => {
  const {
    id, className, style, title,
    footerClassName, headerClassName, listClassName,
    bodyClassName, data, dataLoading, children,
    getId, getPhotoUrl, getPhotoFallbackLabel,
    getName, getCompanyName, showTime, getTime, getDate,
    getMenuItems, menuStyle, menuListStyle, onCall,
    getCampaignColour, getCampaignName, showCallButton,
    scheduledCallsCount, scheduleIconOnClick,
    bottomHitThreshold, onNextPageRequested, placeholderCount,
    onContactClick, onScheduleRowClick, callButtonDisabled, isUnknownContact,
  } = props;
  const classes = SummaryCardScheduledCallsThemer.useStyles(props);

  return (
    <SummaryCardRoot
      id={`${id}-SummaryCard-scheduledCalls`}
      className={className}
      style={style}
      headerClassName={classnames(headerClassName)}
      footerClassName={classnames(footerClassName, classes.historyFooter)}
      bodyClassName={classnames(bodyClassName, classes.summaryScheduledBody)}
      header={
        <Title
          title={title}
          iconBgColor='#158BEA'
          textColor='#000000'
          scheduledCallsCount={scheduledCallsCount}
          scheduleIconOnClick={scheduleIconOnClick}
          renderScheduleIcon={
          <>
            {dataLoading && 
              (
                <FontAwesomeIcon icon={faCalendarAlt as IconProp} beatFade/>
            )}
            {!dataLoading && 
              (
                <FontAwesomeIcon icon={faCalendarAlt as IconProp}/>
            )}
          </>}
        />
      }
      footer={
        <>
        </>
      }
    >
      <div
        id={id}
        ref={ref}
        className={classnames(className)}
        style={style}
      >
        <ScheduledCall
          id={`${id}-SummaryScheduledCall`}
          className={classnames(listClassName, classes.historyContainer)}
          data={data}
          dataTotalCount={scheduledCallsCount}
          dataLoading={dataLoading}
          bottomHitThreshold={bottomHitThreshold}
          onNextPageRequested={onNextPageRequested}
          getId={getId}
          getPhotoUrl={getPhotoUrl}
          getPhotoFallbackLabel={getPhotoFallbackLabel}
          getName={getName}
          getCompanyName={getCompanyName}
          getCampaignColour={getCampaignColour}
          getCampaignName={getCampaignName}
          getTime={getTime}
          getDate={getDate}
          onCall={onCall}
          callButtonDisabled={callButtonDisabled}
          onContactClick={onContactClick}
          onScheduleRowClick={onScheduleRowClick}
          menuStyle={menuStyle}
          menuListStyle={menuListStyle}
          showTime={showTime}
          getMenuItems={getMenuItems}
          showCallButton={showCallButton}
          placeholderCount={placeholderCount ?? DefaultPlaceholderItemsCount}
          isUnknownContact={isUnknownContact}
        />

        {children}
      </div>
    </SummaryCardRoot>
  );
});

SummaryCardScheduledCallsComponent.defaultProps = {
  className: undefined,
  style: undefined,
  title: undefined,
  headerClassName: undefined,
  footerClassName: undefined,
  bodyClassName: undefined,
  data: [],
  dataLoading: false,
  scheduledCallsCount: 0,
  onViewMoreClick: () => null,
  href: '',
  placeholderCount: 3,
  callButtonDisabled: false,
  getDate: undefined,
};

export default SummaryCardScheduledCallsComponent;
