import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../slices/auth';
import settingsReducer from '../slices/settings';
import beaconReducer from '../slices/beacon';
import twilioReducer from '../slices/twilio';
import scheduledCallsReducer from '../slices/scheduledCalls';
import callHistoryReducer from '../slices/callHistory';
import gdprModalReducer from '../slices/gdprModal';
import alertModalReducer from '../slices/alertModal';
import snackbarReducer from '../slices/snackbar';
import contactSearchByPhoneReducer from '../slices/contactSearchByPhone';
import callCentreReducer from '../slices/callCentre';
import callTranscriptReducer from '../slices/callTranscript';
import callingContactReducer from '../slices/callingContact';
import callStateReducer from '../slices/callState';
import networkConnectionReducer from '../slices/networkConnection';

const reducer = {
  auth: authReducer,
  settings: settingsReducer,
  beacon: beaconReducer,
  twilio: twilioReducer,
  scheduledCalls: scheduledCallsReducer,
  callHistory: callHistoryReducer,
  gdprModal: gdprModalReducer,
  alertModal: alertModalReducer,
  snackbar: snackbarReducer,
  contactSearchByPhone: contactSearchByPhoneReducer,
  callCentre: callCentreReducer,
  callTranscript: callTranscriptReducer,
  callingContact: callingContactReducer,
  callState: callStateReducer,
  networkConnection: networkConnectionReducer,
}

export const store = configureStore({
  reducer: reducer,
  devTools: true,
})

// export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;