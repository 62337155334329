import React, { useCallback, useState, useEffect } from 'react';
import debounce from 'debounce';
import classnames from 'classnames';

import ContactRow, { ContactPersonStatus } from '../ContactRow';
import ContactRowLoading from '../../../Private/ContactRowLoading';

import { ContactRowListProps } from './ContactRowList.type';
import ContactRowListThemer from './ContactRowList.theme';

const placeholderItemsCount = 10;

const ContactRowListComponent = (props: ContactRowListProps<any>) => {
  const {
    id,
    className,
    style,
    data,
    dataLoading,
    miniSkeleton,
    getId,
    getAvatarUrl,
    getAvatarFallbackLabel,
    getContactName,
    getContactStatus,
    onSelect,
    isActive,
    isSelected,
  } = props;
  const classes = ContactRowListThemer.useStyles();
  const contactRowClass = classnames(classes.contactRow);

  const handleSelect = useCallback(
    (value: any, e: any) => {
      onSelect?.(value, e);
    },
    [onSelect]
  );

  const scrollHandler = useCallback(
    debounce(() => {}, 400),
    []
  );
  const [scrollContainerRef, setScrollContainerRef] = useState<HTMLDivElement | null>(null);
  const handleScrollContainerRef = useCallback((ref: HTMLDivElement) => {
    if (ref) {
      setScrollContainerRef(ref);
      ref.addEventListener('scroll', scrollHandler);
    }
  }, []);

  useEffect(() => {
    return () => scrollContainerRef?.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <div id={id} key={id} ref={handleScrollContainerRef} className={classnames(classes.contactList, className)} style={style}>
      {!dataLoading &&
        data?.map((item) => (
          <div key={`${id}-Row-${getId(item)}`} className={contactRowClass}>
            <ContactRow
              id={`${id}-Row-${getId(item)}`}
              key={`${id}-Row-${getId(item)}`}
              dataItem={item}
              avatarUrl={getAvatarUrl(item)}
              avatarFallbackLabel={getAvatarFallbackLabel(item)}
              contactName={getContactName(item)}
              contactStatus={getContactStatus(item)}
              onSelect={handleSelect}
              selected={isSelected(item)}
              active={isActive(item)}
            />
            <br></br>
          </div>
        ))}

      {dataLoading &&
        Array.from(Array(placeholderItemsCount).keys()).map((item, index) => (
          <ContactRowLoading
            id={`${id}-Row-Placeholder-${index}`}
            key={`${id}-Row-Placeholder-${index}`}
            style={style} 
            miniSkeleton={miniSkeleton}
          />
        ))}
    </div>
  );
};

ContactRowListComponent.defaultProps = {
  className: undefined,
  style: undefined,
  dataLoading: false,
  miniSkeleton: false,
};
export const ContactListPersonStatus = ContactPersonStatus;
export default ContactRowListComponent;
