import { v4 as uuidv4 } from 'uuid';
import { AxiosRequestConfig } from 'axios'; // Import AxiosRequestConfig

import TranscriptAxiosInstance from '../instances/VoiceIQTranscripts';

export default class TranscriptsApi {
  static async loadTranscript(transcriptUrl: string) {
    const config: AxiosRequestConfig = {
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'text/plain',
        'X-Correlation-Token': uuidv4(),
      },
      url: transcriptUrl,
      method: 'get',
      responseType: 'text',
      // crossDomain: true,
    };

    return TranscriptAxiosInstance.request(config);
  }
}
