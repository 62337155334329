import { makeStyles, createStyles } from '@material-ui/core/styles';

import { ContactRowLoadingProps } from './ContactRowLoading.types';

const Background = { normal: 'rgba(255, 255, 255, 1)', inverse: 'rgba(240, 248, 255, 1)' };

const classes = createStyles({});
const useStyles = makeStyles({
  column: {
    background: 'rgba(255, 255, 255, 0.15)'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '52px',
    paddingRight: '24px',
    paddingLeft: (props: ContactRowLoadingProps) => props.miniSkeleton ? '6px' : '0px',
    gridGap: '12px'
  },

  columnAvatar: { 
    width: (props: ContactRowLoadingProps) => props.miniSkeleton ? '24px' : '36px',
    height: (props: ContactRowLoadingProps) => props.miniSkeleton ? '24px' : '36px',
    borderRadius: '50%'
  },
  avatar: { width: 24, height: 24 },

});

export default { classes, useStyles };
