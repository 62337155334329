import React from 'react';

export interface NumberPadProps {
  id: string;
  className?: string;
  numberTextClassName?: string;
  style?: React.CSSProperties;
  onBackspaceClick?: (callbackValue?: any, event?: any) => any;
  onNumberChange?: (callbackValue?: any, event?: any) => any;
  onEscape?: (callbackValue?: any) => any;
  callbackValue?: any;
  number?: string;
  showBackSpace?: boolean;
  disableFocus?: boolean;
  contactName?: string;
  showContactName?: boolean;
}

export enum KeyboardKeys {
  esc = 'Escape',
  backSpace = 'Backspace',
  spaceBar = ' ',
  plus = '+',
  hash = '#',
  asterisk = '*',
  arrowLeft = 'ArrowLeft',
  arrowRight = 'ArrowRight',
}
