/**
 * Utility class to manage string functions
 */
class StringUtil {
  /**
   * Reduces a given string into it's initials
   */
  initialsFromText = (text: string) => text?.split(' ').filter(piece => !!piece?.length).map(piece => piece[0])

  /**
   * Reduces the given string into it's initials and converts them to numbers
   */
   numberFromText = (text: string): number => {
    const charCodes = (text || '')
      .split('')
      .map(char => char.charCodeAt(0))
      .reduce((accumulator, currentValue) => (accumulator + currentValue), 0);

    return Number.parseInt(`${charCodes}`, 10);
  }
};

export default new StringUtil();
