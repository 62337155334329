// import Logger from 'viq:services/Logger';

const LogTitle = 'TranscriptionHelper';

const timeStampToSeconds = (timestamp: any) => {
  let result = -1;

  if (timestamp) {
    const [hours = 0, minutes = 0, seconds = 0] = timestamp.split(':');
    result = (parseInt(hours, 10) * 3600) + (parseInt(minutes, 10) * 60) + parseInt(seconds, 10);
  }

  return result;
};

export default class TranscriptionHelper {
  static parseTranscript(transcript: any) {
    let parsedSegments: any = [];

    try {
      if (!transcript) {
        return parsedSegments;
      }

      const segments = transcript?.split('\n\n');
      let previousSpeaker = '';

      parsedSegments = segments?.reduce((filtered: any, segment: any) => {
        if (segment && segment !== 'WEBVTT' && segment !== '') {
          const segmentParts = segment.split('\n');
          const [timeStart, timeEnd, source] = segmentParts[1].split(' --> ');
          const timeStartInSeconds = timeStampToSeconds(timeStart);
          const timeEndInSeconds = timeStampToSeconds(timeEnd);

          let rest = '';
          let speaker = '';
          let content = '';
          if (segmentParts[2].includes(':')) {
            rest = segmentParts[2].split(':');
            speaker = rest[0] ? rest[0] : '';
            content = rest[1] ? rest[1].trim() : '';
          } else {
            speaker = previousSpeaker;
            [,, content] = segmentParts;
          }
          if (segmentParts?.length > 3) {
            content += ` ${segmentParts[3]?.trim()}`;
          }


          filtered.push({
            index: segmentParts[0],
            timeStart,
            timeStartInSeconds,
            timeEnd,
            timeEndInSeconds,
            speaker,
            content,
            bookmarks: [],
            source,
          });

          previousSpeaker = speaker;
        }
        return filtered;
      }, []);
      return parsedSegments;
    } catch (error) {
      // Logger.error(LogTitle, 'Failed to parse transcription', { error });
      console.log(LogTitle, 'Failed to parse transcription', { error });
    }
    return parsedSegments;
  }

  static getNlpBookmarksForSegments(response: any, existingBookmarks: any, existingTaggedMessages: any) {
    const uniqueBookmarks = existingBookmarks || new Set();
    const existingTaggedMsgs = existingTaggedMessages || {};
    const { data } = response;

    if (data && data?.length > 0) {
      data.forEach((element: any) => {
        const { Id } = element;
        existingTaggedMsgs[Id] = [];
        const msg = existingTaggedMsgs[Id];

        element.Tags.forEach((tag: any) => {
          // add this nlp bookmark name to the bookmark list.
          uniqueBookmarks.add(tag.Name);

          // if the message doesnt already have this tag, add it.
          if (!msg.some((elem: any) => elem.name === tag.Name && elem.context === tag.Context)) {
            msg.push({ name: tag.Name, context: tag.Context });
          }
        });
      });
    }

    return { nlpBookmarks: uniqueBookmarks, taggedMessages: existingTaggedMsgs };
  }
}
