import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  navigationDiallerContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#001932',
  },
});

export default { useStyles };
