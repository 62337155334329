import React, { forwardRef } from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import classnames from 'classnames';

import Tooltip from '../Tooltip';
import Icon, { IconTheme } from '../Icon';
import Colours from '../../Theme/Colours';
import Typography, { TypographyVariant } from '../Typography';
import { ActivityStatusType } from '../../Enums/ActivityEnum';

import {
  CallStatusBadgeProps, StatusType,
  Theme, ThemeMuiColourMap,
  Placement, PlacementMuiAnchorOriginMap,
} from './CallStatusBadge.types';
import CallStatusBadgeThemer from './CallStatusBadge.theme';

const ThemedBadge = withStyles(CallStatusBadgeThemer.classes)(Badge);
const PlacementAnchorOriginMap: PlacementMuiAnchorOriginMap = {
  [Placement.default]: { vertical: 'top', horizontal: 'right' },
  [Placement.topRight]: { vertical: 'top', horizontal: 'right' },
  [Placement.bottomRight]: { vertical: 'bottom', horizontal: 'right' },
  [Placement.bottomLeft]: { vertical: 'bottom', horizontal: 'left' },
  [Placement.topLeft]: { vertical: 'top', horizontal: 'left' },
};

const StatusTypeIconMap = {
  [StatusType.inboundMissed]: 'call_missed',
  [StatusType.inboundReceived]: 'call_received',
  [StatusType.outboundMissed]: 'call_missed_outgoing',
  [StatusType.outboundReceived]: 'call_made',
  [StatusType.salesforce]: 'cloud',
};
const StatusTypeThemeMap = {
  [StatusType.inboundMissed]: Theme.danger,
  [StatusType.inboundReceived]: Theme.action,
  [StatusType.outboundMissed]: Theme.danger,
  [StatusType.outboundReceived]: Theme.action,
  [StatusType.salesforce]: Theme.action,
};

const colourMap = {
  [StatusType.inboundMissed]: Colours.Danger.normal,
  [StatusType.inboundReceived]: Colours.PrimaryB.normal,
  [StatusType.outboundMissed]: Colours.Danger.normal,
  [StatusType.outboundReceived]: Colours.PrimaryB.normal,
  [StatusType.salesforce]: Colours.PrimaryB.normal,
};

const CallStatusBadgeComponent = forwardRef<HTMLDivElement, CallStatusBadgeProps>((props, ref) => {
  const { id, classes: muiClasses, className, style, children, statusType, placement, callStatusTooltip } = props;
  const classes = CallStatusBadgeThemer.useStyles(props);
  const muiColor = ThemeMuiColourMap[StatusTypeThemeMap[statusType]];
  const anchorOrigin = PlacementAnchorOriginMap[placement || Placement.default]!;
  const iconName = StatusTypeIconMap[statusType];
  const iconNode = (
    <Tooltip
      id={`${id}-CallStatus-Tooltip-Wrapper`}
      content={<Typography.P2 variant={TypographyVariant.light}>{callStatusTooltip}</Typography.P2>}
      placement="bottom"
      arrow
    >
      <Avatar className={classes.avatar} style={{ backgroundColor: colourMap[statusType] ?? Colours.PrimaryB.normal }}>
        <Icon
          id={`${id}-Icon`}
          theme={IconTheme.light}
          className={classnames(classes.icon)}
        >
          {iconName}
        </Icon>
      </Avatar>
    </Tooltip>
    
  );
  const iconNodeWithoutTooltip = (
    <Avatar className={classes.avatar} style={{ backgroundColor: colourMap[statusType] ?? Colours.PrimaryB.normal }}>
      <Icon
        id={`${id}-Icon`}
        theme={IconTheme.light}
        className={classnames(classes.icon)}
      >
        {iconName}
      </Icon>
    </Avatar>
  );

  return (
    <ThemedBadge
      id={id}
      ref={ref}
      className={classnames(className)}
      style={style}
      badgeContent={!callStatusTooltip ? iconNodeWithoutTooltip : iconNode}
      anchorOrigin={anchorOrigin}
      color={muiColor as any}
      classes={muiClasses}
    >
      {children}
    </ThemedBadge>
  );
});

CallStatusBadgeComponent.defaultProps = {
  classes: undefined,
  className: undefined,
  style: undefined,
  placement: Placement.bottomRight,
  callStatusTooltip: undefined,
};

export const CallStatusBadgeStatusType = StatusType;
export const CallStatusBadgePlacement = Placement;
export default CallStatusBadgeComponent;
