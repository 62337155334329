import React, { forwardRef } from 'react';
import classnames from 'classnames';

import Typography, { TypographyVariant } from '../../../Typography';

import { CallHistoryRowHeaderProps } from './CallHistoryRowHeader.types';
import CallHistoryRowHeaderThemer from './CallHistoryRowHeader.theme';

const CallHistoryRowHeaderComponent = forwardRef<HTMLDivElement, CallHistoryRowHeaderProps>((props, ref) => {
  const { id, className, style, showNavigationNode } = props;
  const classes  = CallHistoryRowHeaderThemer.useStyles(props);
  const rowClassName = classnames(classes.row, className);

  return (
    <div
      id={id}
      ref={ref}
      className={rowClassName}
      style={style}
    >
      <div className={classnames(classes.columnAvatar)}>
        <Typography.H4 className={classes.avatarText} variant={TypographyVariant.title} noWrap>Type</Typography.H4>
      </div>

      <div className={classnames(classes.columnTimestamp)}>
        <Typography.H4 variant={TypographyVariant.title} noWrap>Date</Typography.H4>
      </div>

      <div className={classnames(classes.columnDuration)}>
        <Typography.H4 variant={TypographyVariant.title} noWrap>Duration</Typography.H4>
      </div>

      <div className={classnames(classes.columnActions)}>
        <Typography.H4 variant={TypographyVariant.title} noWrap>Outcomes</Typography.H4>
      </div>

      {showNavigationNode && (
        <div className={classnames(classes.columnNavigation)} />
      )}
    </div>
  );
});

CallHistoryRowHeaderComponent.defaultProps = {
  className: undefined,
  style: undefined,
  showNavigationNode: false,
  miniVersion: false,
};

export default CallHistoryRowHeaderComponent;
