export default class FinalizeCallRecordRequest {
  // Mandatory props
  callRecordId = null;
  reason = null;
  callOutcome = null;
  callDuration = 0;
  // All these are not mandatory
  // On the back-end we only update what's sent
  // So set these only if you need to update it
  note = null;
  topic = null;

  constructor(callRecordId: any, reason: any, callOutcome: any, callDuration: any) {
    this.callRecordId = callRecordId;
    this.reason = reason;
    this.callOutcome = callOutcome;
    this.callDuration = callDuration
  }
}
