import React from 'react';
import { Modal, Paper } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';

import { hideAlertModal } from '../../../slices/alertModal';
import { updateCallBarState } from '../../../slices/callCentre';
import { CallBarState } from '../../../constants/CallBarState';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import Typography, { TypographyVariant } from '../../Typography';
import Button, { ButtonTheme, ButtonVariant } from '../../Button';

import AlertModalThemer from './AlertModal.theme';

const ThemedModal = withStyles(AlertModalThemer.classes as any)(Modal);

const AlertModal = () => {
  const dispatch = useAppDispatch();
  const classes = AlertModalThemer.useStyles();
  const authData = useAppSelector(state => state.auth);
  const tenantSettings = useAppSelector(state => state.settings.tenantSettings);
  const alertModalData = useAppSelector(state => state.alertModal);

  const handleClose = () => {
    dispatch(hideAlertModal());
  };

  const handleConfirmButtonClick = () => {
    dispatch(hideAlertModal());
    dispatch(updateCallBarState(CallBarState.WRAP_UP))
  };

  return (
    <ThemedModal className={classes.modal} open={alertModalData?.open} onClose={handleClose}>
      <Paper className={classes.paper}>
        <div className={classes.title}><Typography.H2 variant={TypographyVariant.title}>{alertModalData.modalData.title}</Typography.H2></div>
        <div><Typography.P1 variant={TypographyVariant.title}>{alertModalData.modalData.primaryText}</Typography.P1></div>
        <br />
        <div className={classes.footer}>
          <Button id="Back-Button" theme={ButtonTheme.primary} variant={ButtonVariant.text} onClick={handleClose} disabled={false}>
            <Typography.H4 variant={TypographyVariant.primary}>{alertModalData.modalData.cancelButtonText}</Typography.H4>
          </Button>
          {!!(alertModalData.modalData.confirmButtonText) && (
            <Button
              id="Proceed-Button"
              theme={ButtonTheme.primary}
              variant={ButtonVariant.normal}
              className={classes.proceedButton}
              onClick={handleConfirmButtonClick}
              disabled={false}
            >
              <Typography.H4 variant={TypographyVariant.light}>{alertModalData.modalData.confirmButtonText}</Typography.H4>
            </Button>
          )}
        </div>
      </Paper>
    </ThemedModal>
  );
};

export default AlertModal;
