import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../Theme/Colours';

const classes = {
  root: {
    minHeight: 556,
    maxHeight: "100vh",
    width: '100%',
    margin: 0,
    height: 556,
    borderRadius: 0,
  },
};
const cardContentClasses = {
  root: {
    padding: 0,
    minHeight: 445,
    maxHeight: 568,
    height: 568,
    '&:last-child': {
      padding: '0px !important',
    },
  },
};

const useStyles = makeStyles({
  default: {},
  mainContainer: {
    display: 'flex',
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    height: 70,
    background: Colours.Auxiliary.navigation,
    overflow: 'hidden',
  },
  bodyContainer: {
    background: Colours.Auxiliary.navigation,
    height: 400,
    minHeight: 400,
    maxHeight: 600,
  },
  bodyContainerExtended: {
    height: 400,
  },
  actionsContainer: {
    width: '100%',
    minHeight: 89,
    height: 89,
    paddingTop: 0,
  },
  numberAndContactContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  numberInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  addContactContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    height: 60,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  addContactContainerExtended: {
    height: 72,
  },
  addContactButton: {
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: 'unset',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: 'unset',
    },
  },
  numberPad: {
    backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
    borderRadius: 0,
    height: '100%',
    maxHeight: 72,
    minHeight: 100,
    padding: 0,
    minWidth: 400,
    width: '100%',
  },
  numberPadExtended: {
    height: 60,
  },
  callButtonContainer: {
    width: '100%',
    height: '100%',
  },
  callButton: {
    height: '100% !important',
  },
  callIcon: {
    fontSize: 30,
  },
  dialpadIcon: {
    fontSize: 24,
  },
  callControlsContainer: {
    width: '100%',
    height: 89,
    backgroundColor: 'rgba(0, 25, 50, 0.87)',
    display: 'flex',
    alignItems: 'center',
  },
  callButtonsWrapper: {
    width: '100%',
    display: 'block',
    alignItems: 'center',
  },
  callControlButton: {
    marginLeft: 18,
  },
  wrapupButtonsContainer: {
    width: '100%',
  },
  diallerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  externalCallActionButton: {
    marginTop: 24,
  },
  moreNumberContainerWrapper: {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  moreNumbersPanel: {
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      color: '#FFFFFF',
    },
    '& > svg': {
      position: 'relative',
      right: 50,
      color: '#FFFFFF',
      fontSize: 16,
    },
  },
  nestedNumber: {
    paddingLeft: 24,
    color: '#FFFFFF',
  },
  nestedNumberValue: {
    flex: '0 1 auto',
    marginRight: 6,
  },
  nestedNumberIcon: {
    minWidth: 0,
    marginRight: 20,
    color: '#FFFFFF',
  },
  cardContainer: {
    minWidth: '264px !important',
    borderRadius: '0px !important',
    minHeight: '486px !important',
    maxHeight: '486px !important',
    display: 'flex',
    flexDirection: 'column',

    '& > div': {
      backgroundColor: '#001932',
    },
  },
  callForwardDialpad: {
    minHeight: '378px !important',
    borderRadius: '0px',
    flex: 1,

    '& > div:first-child': {
      height: '60px !important',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '0px',
    },
    '& > div:nth-child(2)': {
      width: 'auto !important',
      margin: '38px 80px !important',

      '& > button': { margin: '9px 12px !important' },
    },
  },
  content: {
    // height: '378px !important',
    // minHeight: '378px !important',
    flex: 1,
  },
  inputWrapper: { margin: '24px 12px !important' },
  dialpadButtonContainer: { paddingLeft: '12px !important' },
  contactRowList: {
    paddingLeft: '6px !important',
    maxHeight: '260px !important',
    textAlign: 'initial',
  },
});

export default { classes, cardContentClasses, useStyles };
