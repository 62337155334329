import React, { forwardRef } from 'react';
import Skeleton from '@mui/material/Skeleton';
import classnames from 'classnames';

import { ScheduledCallsCardRowLoadingProps, Theme } from './ScheduledCallsCardRowLoading.types';
import ScheduledCallsCardRowLoadingThemer from './ScheduledCallsCardRowLoading.theme';

const ScheduledCallsCardRowLoadingComponent = forwardRef<HTMLDivElement, ScheduledCallsCardRowLoadingProps>((props, ref) => {
  const { id, className, style, theme } = props;
  const classes = ScheduledCallsCardRowLoadingThemer.useStyles(props);
  const rowClassName = classnames(
    classes.row,
    { [classes.rowNormal]: theme === Theme.normal, [classes.rowInverse]: theme === Theme.inverse },
    className,
  );

  return (
    <div
      id={id}
      ref={ref}
      className={rowClassName}
      style={style}
    >
      <div className={classes.leftSide}>
        <div className={classnames(classes.columnAvatar)}>
          <Skeleton variant="circular" width={40} height={40} />
        </div>
        <div className={classnames(classes.columnContactDetails)}>
          <Skeleton variant="rectangular" width={110} height={21} />
        </div>
      </div>

      <div className={classnames(classes.columnTimestamp)}>
        <Skeleton variant="rectangular" width={78} height={24} />
      </div>
    </div>
  );
});

ScheduledCallsCardRowLoadingComponent.defaultProps = {
  className: undefined,
  style: undefined,
  miniVersion: true,
};

export default ScheduledCallsCardRowLoadingComponent;
