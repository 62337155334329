import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  home: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItem: {
    maxHeight: 72,
    paddingLeft: '24px !important',
  },
  SummaryBodyWrapper: {
    position: 'relative',
  }, 
  SummaryFooter: {   
    fontWeight: 400,
  },
  emptyState: {
    padding: '0px 18px',
  },
  unavailableBanner: {
    backgroundColor: '#FF5B60',
    zIndex: 999,
  },
  homeIconStyle: {
    cursor: 'pointer',
  },
});

export default { useStyles };
