import CreateCallButtonsComponent from './Buttons.factory';

export { Theme as CallButtonTheme } from './Buttons.types';
export { ButtonWidth as DiallerButtonWidth } from './Buttons.types'
export { Size as CallIconButtonSize } from '../../../IconButton/IconButton.types';
export default {
  CallControlButton: CreateCallButtonsComponent('CallControlButton'),
  CallActionButton: CreateCallButtonsComponent('CallActionButton'),
  DiallerCallActionButton: CreateCallButtonsComponent('DiallerCallActionButton'),
};
