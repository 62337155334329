import { makeStyles } from '@material-ui/core/styles';

import { IconTitleProps } from './IconTitle.types';
import Colours from '../../Theme/Colours';

const defaultIconbackgroundColor = '#158BEA';

const useStyles = makeStyles({
  iconTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.12)',
    cursor: 'pointer',
    marginRight: (props: IconTitleProps) => props.iconMarginRight ? props.iconMarginRight : 11,
    '& > svg': {
      color: Colours.Typography.light,
      fontSize: 18,
    },
    backgroundColor: 'rgb(21, 139, 234)'
  },
  childrenWrapper: {},
  subChildren: {
    position: 'absolute',
  },
});

export default { useStyles };
