import React from 'react';

export interface ScheduledCallsCardRowLoadingProps {
  id: string
  className?: string
  style?: React.CSSProperties
  theme?: Theme
  miniVersion?: boolean
};

export enum Theme {
  normal = 'normal',
  inverse = 'inverse',
};
