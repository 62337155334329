import { makeStyles } from '@material-ui/core/styles';

import FontFamily from '../../Theme/FontFamily';
import Colours from '../../Theme/Colours';

const classes = {
  root: {
    minWidth: 178,
    height: 35,
    padding: '7px 12px',
  },
};
const useStyles = makeStyles({
  default: {
    fontFamily: FontFamily,
    fontSize: 14,
    color: Colours.Typography.active,
    backgroundColor: Colours.Backgrounds.lightMenuItem,
    '&:hover': { backgroundColor: Colours.Backgrounds.lightMenuItemHovered },
  },
  danger: {
    color: Colours.Danger.normal,
    backgroundColor: Colours.Backgrounds.dangerMenuItem,
    '&:hover': { backgroundColor: Colours.Backgrounds.dangerMenuItemHovered },
  },
  dark: {
    color: Colours.Typography.light,
    backgroundColor: Colours.Auxiliary.navigation,
    '&:hover': { backgroundColor: Colours.Iconography.Dark.hovered },
  },

  selectionWithPadding: { marginLeft: 12, marginRight: 12 },

  icon: { minWidth: 24 },
  iconDanger: { color: Colours.Danger.normal },
  iconDark: { color: Colours.Typography.light },
});

export default { classes, useStyles };
