import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import CallHistoryService from "../Services/CallHistory/CallHistoryService";
import { RootState } from '../app/store';

const initialState: any = { data: [], loading: true };

export const getCallHistory: any = createAsyncThunk(
  "getCallHistory/post",
  async (_, { getState }) => {
    const currentState = getState() as RootState;
    const { userId, tenantCode, AccessToken } = currentState.auth.authUser;
    const requestBody = { top: 3, type: 'Call', Users: [userId] };
    const result = await CallHistoryService.getCallHistory(requestBody, tenantCode, AccessToken);

    return result.data;
  }
);

const callHistorySlice = createSlice({
  name: 'callHistory',
  initialState,
  reducers: {
    getCallHistoryInitState(state, action: PayloadAction<any>) {
      state.data = action.payload.listData;
      state.loading = action.payload.loading;
    },
  },
  extraReducers: {
    [getCallHistory.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.data = data;
      state.loading = false;
    },
    [getCallHistory.rejected]: (state, action) => {
      state.data.error = action.error;
    },
  }
});

export const { getCallHistoryInitState } = callHistorySlice.actions;
export default callHistorySlice.reducer;
