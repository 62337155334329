import React, { forwardRef } from 'react';
import classnames from 'classnames';

import Typography, { TypographyVariant } from '../Typography';

import { IconTitleProps } from './IconTitle.types';
import IconTitleThemer from './IconTitle.theme';

const IconTitleComponent = forwardRef<HTMLDivElement, IconTitleProps>((props, ref) => {
  const {
    className, style,
    iconWrapperClassName,
    children, subChildren,
    icon, iconOnClick,
  } = props;
  const classes = IconTitleThemer.useStyles(props);

  return (
    <div
      ref={ref}
      className={classnames(className, classes.iconTitleWrapper)}
      style={style}
    >
      <div className={classnames(iconWrapperClassName, classes.iconWrapper)} onClick={iconOnClick}>
        {icon}
      </div>
      <div>
        {children}
        <Typography.H4 variant={TypographyVariant.active} className={classes.subChildren}>{subChildren}</Typography.H4>
      </div>
    </div>
  );
});

IconTitleComponent.defaultProps = {
  className: undefined,
  style: undefined,
  iconWrapperClassName: undefined,
  icon: undefined,
  children: undefined,
  subChildren: undefined,
  iconMarginRight: undefined,
  iconBackgroundColor: undefined,
};

export default IconTitleComponent;
