import React, { forwardRef, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';

import Typography, { TypographyVariant } from '../Typography';
import Icon, { IconSize } from '../Icon';
import IconButton, { IconButtonTheme } from '../IconButton';
import SearchInput, { SearchInputTheme } from '../SearchInput';
import Buttons, { CallButtonTheme, DiallerButtonWidth } from '../Call/Private/Buttons';
import Dialpad from '../Call/Dialpad';

import ContactRowList, { ContactListPersonStatus } from './private/ContactRowList';

import { CallForwardCardProps, Type } from './CallForwardCard.types';
import CallForwardCardThemer from './CallForwardCard.theme';

const CallForwardCardComponent = forwardRef<HTMLElement, CallForwardCardProps<any>>((props, ref) => {
  const {
    id,
    className,
    style,
    hideHeader,
    title,
    onClose,
    onProceed,
    onInputChange,
    proceedButtonLabel,
    proceedButtonDisabled,
    inputValue,
    onSelect,
    data,
    dataTotalCounts,
    dataLoading,
    miniSkeleton,
    getId,
    getAvatarUrl,
    getAvatarFallbackLabel,
    getContactName,
    getContactStatus,
    isActive,
    isSelected,
    dialpadOpen,
    callForwardDialpadClassName,
    contentClassName,
    inputWrapperClassName,
    dialpadButtonContainerClassName,
    contactRowListClassName,
    externalNumber,
    externalNumberCallbackValue,
    showBackSpace,
    dialpadProceedButtonDisabled,
    type,
    onDialpadClicked,
    onNumberChange,
    onDialpadNumberPressed,
    onBackSpaceClicked,
    onBackButtonClicked,
    onDialpadProceed,
  } = props;

  const classes = CallForwardCardThemer.useStyles();
  const cardClassName = classnames(classes.card, className);
  const headerClassName = classnames(classes.header);
  const footerClassName = classnames(classes.footer);
  const iconClassname = classnames(classes.closeIcon);
  const contentClassNames = classnames(classes.contactSearchArea, contentClassName);
  const buttonClassName = classnames(classes.button);
  const buttonTypoGraphyClassName = classnames(classes.buttonTypography);
  const inputClassName = classnames(classes.input);
  const callForwardDialpadClassNames = classnames(classes.dialpad, callForwardDialpadClassName);

  const handleClose = useCallback(
    (e: any) => {
      onClose?.(e);
    },
    [onClose]
  );

  const handleProceed = useCallback(
    (e: any) => {
      onProceed?.(e);
    },
    [onProceed]
  );

  const handleDialpadProceed = useCallback(
    (e: any) => {
      onDialpadProceed?.(e);
    },
    [onDialpadProceed]
  );

  const handleSelect = useCallback(
    (value: any) => {
      onSelect?.(value);
    },
    [onSelect]
  );

  const handleChange = useCallback(
    (e: any) => {
      onInputChange?.(e);
    },
    [onInputChange]
  );

  const handleDialpadClick = useCallback(() => {
    onDialpadClicked?.();
  }, [onDialpadClicked]);

  const handleBackButtonClicked = useCallback(() => {
    onBackButtonClicked?.();
  }, [onBackButtonClicked]);

  const header = (
    <Card id={`${id}-CallForwardCard-Header}`} className={headerClassName}>
      <Typography.H1 variant={TypographyVariant.light}>{title}</Typography.H1>
      <IconButton
        id={`${id}-CallForwardCard-Header-close-icon}`}
        theme={IconButtonTheme.light}
        className={iconClassname}
        onClick={handleClose}
      >
        <Icon size={IconSize.extraLarge}>close</Icon>
      </IconButton>
    </Card>
  );

  const footer = (
    <Card id={`${id}-CallForwardCard-Footer}`} className={footerClassName}>
      {!dialpadOpen && (
        <>
        <Buttons.DiallerCallActionButton
          id={`${id}-CallForwardCard-Footer-button-cancel}`}
          theme={CallButtonTheme.dark}
          className={buttonClassName}
          onClick={handleClose}
          buttonWidth={DiallerButtonWidth.halfLeft}
        >
          <Typography.H3 variant={TypographyVariant.light} className={buttonTypoGraphyClassName}>
            Cancel
          </Typography.H3>
        </Buttons.DiallerCallActionButton>
        <Buttons.DiallerCallActionButton
          id={`${id}-CallForwardCard-Footer-button-proceed}`}
          theme={CallButtonTheme.dark}
          className={buttonClassName}
          onClick={handleProceed}
          disabled={proceedButtonDisabled}
          buttonWidth={DiallerButtonWidth.halfRight}
        >
          <Typography.H3 variant={TypographyVariant.light} className={buttonTypoGraphyClassName}>
            {proceedButtonLabel}
          </Typography.H3>
        </Buttons.DiallerCallActionButton>
        </>
      )}
      {dialpadOpen && (
        <>
        <Buttons.DiallerCallActionButton
          id={`${id}-CallForwardCard-Footer-button-back}`}
          theme={CallButtonTheme.dark}
          className={buttonClassName}
          onClick={handleBackButtonClicked}
          buttonWidth={DiallerButtonWidth.halfLeft}
        >
          <Typography.H3 variant={TypographyVariant.light} className={buttonTypoGraphyClassName}>
            Back
          </Typography.H3>
        </Buttons.DiallerCallActionButton>
        <Buttons.DiallerCallActionButton
          id={`${id}-CallForwardCard-Footer-button-proceed}`}
          theme={CallButtonTheme.dark}
          className={buttonClassName}
          onClick={handleDialpadProceed}
          disabled={dialpadProceedButtonDisabled}
          buttonWidth={DiallerButtonWidth.halfRight}
        >
          <Typography.H3 variant={TypographyVariant.light} className={buttonTypoGraphyClassName}>
            {proceedButtonLabel}
          </Typography.H3>
        </Buttons.DiallerCallActionButton>
        </>
      )}
    </Card>
  );

  return (
    <Card id={`${id}-CallForwardCard`} ref={ref} className={cardClassName} style={style}>
      {!hideHeader && header}
      {!dialpadOpen && (
        <Card id={`${id}-CallForwardCard-content`} className={contentClassNames}>
          <div className={classnames(classes.inputWrapper, inputWrapperClassName)}>
            <SearchInput
              id={`${id}-CallForwardCard-content-input`}
              className={inputClassName}
              theme={SearchInputTheme.inverse}
              value={inputValue}
              onChange={handleChange}
            />
          </div>
          {type === Type.forwardCall  && (
            <div className={classnames(classes.dialpadButtonContainer, dialpadButtonContainerClassName)} onClick={handleDialpadClick}>
              <Icon className={classes.dialpadIcon}>
                dialpad
              </Icon>
              <Typography.H4 className={classes.dialpadText}>Use Dialpad</Typography.H4>
            </div>
          )}
        <ContactRowList
          id={`${id}-CallForwardCard-content-list`}
          className={contactRowListClassName}
          data={data}
          dataLoading={dataLoading}
          miniSkeleton={miniSkeleton}
          dataTotalCount={dataTotalCounts}
          getId={getId}
          getAvatarUrl={getAvatarUrl}
          getAvatarFallbackLabel={getAvatarFallbackLabel}
          getContactName={getContactName}
          getContactStatus={getContactStatus}
          isActive={isActive}
          isSelected={isSelected}
          onSelect={handleSelect}
        />
      </Card>
      )}
      {dialpadOpen  && (
        <Dialpad
          id={`${id}-Call-Forward-Card-Dialpad`}
          className={callForwardDialpadClassNames}
          number={externalNumber}
          onChange={onDialpadNumberPressed}
          onBackSpaceClicked={onBackSpaceClicked}
          callBackValue={externalNumberCallbackValue}
          showBackSpace={showBackSpace}
          onNumberChange={onNumberChange}
        />
      )}
      {footer}
    </Card>
  );
});

CallForwardCardComponent.defaultProps = {
  className: undefined,
  callForwardDialpadClassName: undefined,
  contentClassName: undefined,
  inputWrapperClassName: undefined,
  dialpadButtonContainerClassName: undefined,
  contactRowListClassName: undefined,
  style: undefined,
  hideHeader: false,
  title: undefined,
  proceedButtonLabel: undefined,
  inputValue: '',
  proceedButtonDisabled: false,
  onClose: () => {},
  onProceed: () => {},
  onSelect: () => {},
  onInputChange: () => {},
  data: [],
  dataTotalCounts: undefined,
  dataLoading: false,
  miniSkeleton: false,
  externalNumberCallbackValue: undefined,
  showBackSpace: false,
  dialpadProceedButtonDisabled: false,
  type: Type.forwardCall,
  onNumberChange: () => {},
  onDialpadProceed: () => {},
};

export const UserStatus = ContactListPersonStatus;
export { Type as CallType } from './CallForwardCard.types';
export default CallForwardCardComponent;
