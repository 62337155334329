import React, { forwardRef, useEffect, useCallback, useState } from 'react';
import Lottie from "lottie-react";
import { useDispatch } from "react-redux";

import { useMetaDataLoad } from "../../Hooks/useMetaDataLoad";
import animationData from '../../assets/AnimationData.json';
import { SplashScreenProps } from './SplashScreen.types';
import SplashScreenThemer from './SplashScreen.theme'; 
import MainComponent from '../Main';
import ErrorPageComponent from '../ErrorPage';
import { useAppSelector } from '../../app/hooks';
import { CallBarState } from "../../constants/CallBarState";

import { getUser, getUserTeam, getUserWrapupStatus } from '../../slices/auth';
import { getCallDispositions, getTenantSettings } from '../../slices/settings';
import { getBeacon } from '../../slices/beacon';
import { getTwilioData } from '../../slices/twilio';
import { updateCallBarState } from "../../slices/callCentre";
import { getTeamWithOutGoingCallIdPreference } from '../../slices/auth';

const SplashScreenComponent = forwardRef<HTMLDivElement, SplashScreenProps>((props, ref) => {
  const {} = props;
  const [getBeaconDispatched, setGetBeaconDispatched] = useState(false);

  const authData = useAppSelector((state:any) => state.auth);
  const tenantSettingsData = useAppSelector((state:any) => state.settings?.tenantSettings);
  const dispatch = useDispatch();

  const classes = SplashScreenThemer.useStyles();
  const [errorCode, loading, fetched] = useMetaDataLoad();
  const tenantSettingsAvailable = Object.keys(tenantSettingsData).length > 0;

  const resolveInitialApiCalls = useCallback(() => {
    dispatch(getUserTeam(authData?.authUser?.userId));
    dispatch(getUser(authData?.authUser?.userId));
    dispatch(getTwilioData(authData?.authUser?.userId));
    dispatch(getUserWrapupStatus(authData?.authUser?.userId));
    dispatch(getTenantSettings());
    dispatch(getCallDispositions());
    dispatch(updateCallBarState(CallBarState.IDLE));
  }, [authData?.authUser?.userId, dispatch]);

  const resolveTeamWithOutGoingCallIdPreference = useCallback(() => {
    dispatch(getTeamWithOutGoingCallIdPreference());
  }, []);

  useEffect(() => {
    if(!!authData?.authUser?.userId) resolveInitialApiCalls();
  }, [authData?.authUser, resolveInitialApiCalls]);

  useEffect(() => {
    if(!!authData?.userTeam?.id) resolveTeamWithOutGoingCallIdPreference();
  }, [authData?.userTeam?.id, resolveTeamWithOutGoingCallIdPreference]);

  useEffect(() => {
    // Dispatch getBeacon only if tenantData is available and getBeacon hasn't been dispatched yet
    if (tenantSettingsAvailable && !getBeaconDispatched) {
      dispatch(getBeacon({ statusId: authData?.user?.userStatus?.id, tenantSettings: tenantSettingsData }));
      setGetBeaconDispatched(true);
    }

  }, [authData?.user?.userStatus?.id, tenantSettingsAvailable, getBeaconDispatched, dispatch]);

    if (fetched) {
      return <MainComponent id='Main-Component' />
    } else if (errorCode !== 0) {
      return <ErrorPageComponent errorCode={errorCode}/>
    }

  return (
    <div className={classes.splashContainer}>
      <Lottie
        loop={true}
        animationData={animationData}
        autoPlay={true}
        renderer='svg'
        style={{ height: '350px', width: '350px', alignSelf: 'center', placeContent: 'center', marginTop: 110 }}
      />
      <div className={classes.centers}>Warming up the engines...</div>
    </div>
  )
});

SplashScreenComponent.defaultProps = {};

export default SplashScreenComponent;    