export const CallDeviceStatusEnum = Object.freeze({
  UnInitialized: 'UnInitialized',
  Ready: 'Ready',
  Connected: 'Connected',
  Disconnected: 'Disconnected',
  Error: 'Error',
  Offline: 'Offline',
});

export const IsActive = (callStatus: any) =>
  callStatus !== CallDeviceStatusEnum.UnInitialized &&
  callStatus !== CallDeviceStatusEnum.Offline &&
  callStatus !== CallDeviceStatusEnum.Error;

export const IsUserInCall = (callStatus: any) => callStatus === CallDeviceStatusEnum.Connected;

export const CallActivityDirectionEnum = Object.freeze({
  incoming: 'IncomingCall',
  outgoing: 'OutgoingCall',
});