import { makeStyles } from '@material-ui/core/styles';

import { CallState } from '../../../../Enums/Call.types';
import Colours from '../../../../Theme/Colours';

const useStyles = makeStyles({
  default: {},
  contactViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contactAvatar: {
    width: 54,
    height: 54,
    marginTop: 36,
  },
  avatarFallbackLabel: {
    fontSize: 20,
  },
  contactName: {
    maxWidth: 228,
  },
  contactNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 18,
  },
  callTimeContainerCommon: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
  },
  callStateIndicator: {
    fontSize: 12,
  },
  timeText: {
    marginLeft: 8,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: 48,
    justifyContent: 'center',
  },
  callControlButtons: {
    marginLeft: 20,
  },
});

const CallTimeIconColourMap = {
  [CallState.dialing]: Colours.Backgrounds.tooltip,
  [CallState.connecting]: Colours.Backgrounds.tooltip,
  [CallState.ringing]: Colours.Backgrounds.tooltip,
  [CallState.answered]: Colours.PrimaryB.normal,
  [CallState.ended]: Colours.Backgrounds.tooltip,
  [CallState.disconnected]: Colours.Backgrounds.tooltip,
};

export default { useStyles, CallTimeIconColourMap };
