import Colours from '../../Theme/Colours';
import TranscriptionConstants from '../../constants/Transcription';

const DefaultBookmarkColour = Colours.Secondary.Teal.normal;
const DefaultBookmarkHighlightColour = 'rgba(80, 207, 151, 0.1)';

interface BookmarkPiece {
  id: string;
  name: string;
  icon: any[];
  colour: any[];
};
interface TranscriptionPiece {
  id: string;
  text: string;
  decorations: any[];
};

class TranscriptionSegmentService {
  processSegment = (transcriptionSegment: any, bookmarksOfTranscript: any, addTimestamp = false) => {
    const text = (transcriptionSegment.transcript || '');
    const textLowerCase = text.toLowerCase();
    const result = {
      id: transcriptionSegment.id,
      speaker: transcriptionSegment.source,
      speakerName: transcriptionSegment.speaker,
      text,
      bookmarks: [] as BookmarkPiece[],
      pieces: [] as TranscriptionPiece[],
      startTime: '',
      endTime: '',
    };

    // Add timestamp details if necessary
    if (addTimestamp) {
      result.startTime = transcriptionSegment.startTime;
      result.endTime = transcriptionSegment.endTime;
    }

    if (bookmarksOfTranscript?.length) {
      // We need to break each word into it's own piece
      result.pieces = transcriptionSegment
        .transcript
        .split(' ')
        .map((word: any, index: any) => ({
          id: `${transcriptionSegment.id}-${index}`,
          text: word,
          decorations: [],
        }));

      // Let's traverse each bookmark
      bookmarksOfTranscript.forEach((bookmark: any) => {
        const bookmarkTemplate = bookmark?.bookmarkTemplate;

        // Let's find out exactly what triggered this bookmark in this segment
        bookmarkTemplate?.triggers?.forEach((trigger: any) => {
          const triggerBeginIndex = textLowerCase.indexOf(trigger?.toLowerCase());
          const triggerEndIndex = triggerBeginIndex + trigger?.length;
          const triggerExistsInSegment = triggerBeginIndex > -1;

          // Proceed only if the trigger exists in this segment
          if (triggerExistsInSegment) {
            let traversedCharLength = 0;
            let headFound = false;
            let tailFound = false;

            // Let's look through the pieces to find the ones covered by the trigger
            for (let pieceIndex = 0; pieceIndex < result.pieces?.length; pieceIndex++) {
              const piece: any = result.pieces[pieceIndex];

              if (headFound) {
                // We've found the head. Now we need to look for the tail
                const isLastPiece = piece === result.pieces?.length - 1;
                const pieceCoversTrigger = (piece.text?.length + 1 + traversedCharLength >= triggerEndIndex);
                tailFound = isLastPiece || pieceCoversTrigger;

                if (tailFound) {
                  // We've found the tail
                  piece.decorations.push({
                    key: `${piece.id}-Decoration-(${trigger})-Tail`,
                    type: TranscriptionConstants.ProcessedSegment.DecorationType.tail,
                    colour: DefaultBookmarkHighlightColour,
                  });
                  break;
                } else {
                  // We haven't found the tail yet. Let's push a body
                  piece.decorations.push({
                    key: `${piece.id}-Decoration-(${trigger})-Body-${traversedCharLength}`,
                    type: TranscriptionConstants.ProcessedSegment.DecorationType.body,
                    colour: DefaultBookmarkHighlightColour,
                  });
                }
              } else {
                // Still looking for head. Hue hue!
                // If the currently traversed character length matches trigger beginning index, that means we found the head
                const triggerBeginsHere = triggerBeginIndex === traversedCharLength;

                if (triggerBeginsHere) {
                  headFound = true;
                  // Let's find out if this is a single piece trigger
                  const triggerEndsHere = triggerEndIndex <= traversedCharLength + piece.text?.length + 1;

                  if (triggerEndsHere) {
                    tailFound = true; // If we have found the whole piece, we can signal the loop to terminate
                    piece.decorations.push({
                      key: `${piece.id}-Decoration-(${trigger})-Full`,
                      type: TranscriptionConstants.ProcessedSegment.DecorationType.full,
                      colour: DefaultBookmarkHighlightColour,
                    });
                    break;
                  } else {
                    piece.decorations.push({
                      key: `${piece.id}-Decoration-(${trigger})-Head`,
                      type: TranscriptionConstants.ProcessedSegment.DecorationType.head,
                      colour: DefaultBookmarkHighlightColour,
                    });
                  }
                }
              }

              // Add to the traversed length
              traversedCharLength += (piece.text?.length + 1);

              // If we have already found the tail, there's no need to traverse anymore
              if (tailFound) break;
            }
          }
        });
      });
    } else {
      // No bookmarks detected. Let's push the whole segment as a single piece
      result.pieces.push({
        id: transcriptionSegment.id,
        text: transcriptionSegment.transcript,
        decorations: [],
      });
    }

    bookmarksOfTranscript
      .forEach((bookmark: any) => {
        const bookmarkTemplateColour = bookmark?.bookmarkTemplate?.colour;
        const colour = bookmarkTemplateColour &&
        (Colours.Secondary as { [key: string]: any })[bookmarkTemplateColour]
        ? (Colours.Secondary as { [key: string]: any })[bookmarkTemplateColour].normal
        : DefaultBookmarkColour;
      
        result.bookmarks.push({
          id: bookmark.id,
          name: bookmark?.bookmarkTemplate?.name,
          icon: bookmark?.bookmarkTemplate?.icon,
          colour: colour || DefaultBookmarkColour,
        });
      });

    return result;
  }

  /**
   * Resolves the list of transcription segments for a given bookmark time range
   *
   * @param   {number}                    bookmarkStartTime   Bookmark start time in seconds
   * @param   {number}                    bookmarkEndTime     Bookmark end time in seconds
   * @param   {Array<TranscriptSegment>}  transcriptSegments  List of transcription segments
   * @returns {Array<TranscriptSegment>}
   */
  resolveBookmarkTranscriptSegments = (bookmarkStartTime: any, bookmarkEndTime: any, transcriptSegments: any) => {
    const result = (transcriptSegments || []).filter((transcriptSegment: any) => {
      const { startTime: transcriptStartTime} = transcriptSegment;
      // const bookmarkIsBeforeTranscript = bookmarkEndTime < transcriptStartTime;
      // const bookmarkIsAfterTranscript = bookmarkStartTime > transcriptEndTime;
      // const bookmarkWithinRangeOfTranscript = !bookmarkIsBeforeTranscript && !bookmarkIsAfterTranscript;

      return transcriptStartTime >= bookmarkStartTime && transcriptStartTime <= bookmarkEndTime;
      // return true;

      // return bookmarkWithinRangeOfTranscript;
    });

    return result;
  }
}

export default new TranscriptionSegmentService();
