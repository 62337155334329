import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../../../../Theme/Colours';
import { Theme } from '../Buttons.types';

const classes = {};
const useStyles = makeStyles({
  common: {
    fontSize: 12,
    fontWeight: 600,
  },
  [Theme.primary]: {
    backgroundColor: Colours.PrimaryA.normal,
    color: Colours.Typography.light,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: Colours.PrimaryA.hovered,
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: Colours.PrimaryA.pressed,
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: Colours.PrimaryA.pressed,
    },
    '&:disabled': {
      color: Colours.Typography.disabled,
      backgroundColor: Colours.Backgrounds.button,
      boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.4)',
      border: '1px solid rgba(195, 198, 202, 1)',
    },
  },
  [Theme.secondary]: {
    backgroundColor: Colours.Secondary.Green.normal,
    color: Colours.Typography.light,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: '#25A474',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: '#25A474',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: '#25A474',
    },
    '&:disabled': {
      color: Colours.Typography.disabled,
      backgroundColor: Colours.Backgrounds.button,
    },
  },
  [Theme.secondaryAlternative]: {
    backgroundColor: '#50CF97',
    color: Colours.Typography.light,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: '#50B588',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: '#50B588',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: '#50B588',
    },
    '&:disabled': {
      color: Colours.Typography.disabled,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  [Theme.danger]: {
    backgroundColor: Colours.Secondary.Red.normal,
    color: Colours.Typography.light,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: '#DC4347',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: '#DC4347',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: '#DC4347',
    },
    '&:disabled': {
      color: Colours.Typography.disabled,
      backgroundColor: Colours.Backgrounds.button,
    },
  },
  [Theme.dark]: {
    color: Colours.Typography.light,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(247, 247, 247, 0.1)',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(247, 247, 247, 0.1)',
    },
    '&:disabled': {
      color: '#DADADA',
      opacity: 0.5,
    },
  },
  [Theme.defaultAlternative]: {
    color: Colours.Typography.light,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(247, 247, 247, 0.1)',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(247, 247, 247, 0.1)',
    },
    '&:disabled': {
      color: '#DADADA',
    },
  },
  [Theme.default]: {
    color: Colours.Typography.light,
    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(247, 247, 247, 0.1)',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: 'rgba(247, 247, 247, 0.1)',
    },
    '&:disabled': {
      color: '#DADADA',
    },
  },
});

export default { classes, useStyles };
