import React from 'react';

export interface ContactRowProps {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  dataItem?: any;
  contactName: string;
  avatarUrl: string | null;
  avatarFallbackLabel: string;
  contactStatus: ContactStatus;
  selected?: boolean;
  active?: boolean;
  onSelect: (callbackValue?: any, event?: any) => any;
  callbackvalue?: any;
}

export enum ContactStatus {
  Available = 'Available',
  AvailableMobile = 'Available (Mobile)',
  Busy = 'Busy',
  Away = 'Away'
};