import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../Theme/Colours';

const classes = {
  root: {
    borderRadius: 0,
    margin: 'auto',
    background: Colours.Backgrounds.white,
    boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.12)',
  },
};

const cardContentClasses = {
  root: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
};

const radioButtonClasses = {
  root: {
    color: '#D1D4D8',
    paddingRight: 12,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  checked: {
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
};

const formControlLabelClasses = {
  root: {
    fontFamily: 'Lato !important',
    fontStyle: 'normal !important',
    fontSize: '14px !important',
    lineHeight: '21px !important',
    color: '#555555',
  },
};

const useStyles = makeStyles({
  default: {},
  card: {
    width: '100%',
    maxWidth: 400,
    minWidth: 400,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FCFBFA',
    height: 60,
    paddingLeft: 15,
    paddingRight: 15,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    marginRight: 12,
    borderRadius: 50,
    backgroundColor: '#50CF97',
    '& > svg': {
      fontSize: 14,
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    marginRight: 4,
  },
  bodyContainer: {
    padding: '2px 15px 15px 15px',
    maxWidth: 400,
  },
  footerContainer: {
    backgroundColor: 'red',
  },
  formControlContainer: {
    width: '100%',
  },
  outcome: {
    width: '100%',
    textAlign: 'left',
  },
  wariningTextContainer: {
    width: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'left',
    padding: '0px 15px 0px 15px',
  },
  icon: {
    color: '#D1D4D8',
    fontSize: 16,
  },
  iconChecked: {
    color: Colours.Typography.primary,
    fontSize: 16,
  },
});

export default { classes, cardContentClasses, radioButtonClasses, formControlLabelClasses, useStyles };
