export interface RollingCacheUtility {
  maxLength: number;
  add: (key: string, value: any) => void;
  get: (Key: string, defaultValue: any) => any;
}


/**
 * A custom implementation of a rolling cache with a max length
 * @type {RollingCacheUtility}
 */
class RollingCacheUtil {
  /**
   * PRIVATE
   * The actual cache
   */
  _cache = new Map();

  /**
   * Max length of cache 
   */
  maxLength = 0;

  /**
   * Creates a new RollingCache object
   *
   * @param {number} maxLength Maximum number of values to hold onto in the cache (Default: 50)
   */
  constructor(maxLength = 50) {
    this.maxLength = maxLength;
  }

  /**
   * PRIVATE
   * Removes the oldest cache entry
   */
  _removeOldest = () => {
    const oldestKey = this._cache.keys().next().value;
    this._cache.delete(oldestKey);
  }

  /**
   * Tells whether a cached value is available for given key
   *
   * @param  {string}  key           Cache key
   * @return {bool}                  True if cached value exists
   */
  has = (key: any) => this._cache.has(key)

  /**
   * Gets the value from cache and returns null/defaultValue on cache miss
   *
   * @param  {string}  key           Cache key
   * @param  {any}     defaultValue  Value to return if it's a miss
   * @return {any}                   Cached value if cache hit. DefaultValue/null if cache miss
   */
  get = (key: any, defaultValue = null) => (this._cache.get(key) || defaultValue);

  /**
   * Adds a value to the cache
   *
   * @param {string}  key   Cache key
   * @param {any}     value Value to cache
   */
  add = (key: any, value: any) => {
    // We set the value first, no questions asked
    this._cache.set(key, value);

    // Now we check if we need to let go of the oldest geezer
    if (this._cache.size >= this.maxLength) this._removeOldest();
  }
};

export default RollingCacheUtil;
