import React from 'react';

export interface SearchInputProps {
  id: string
  className?: string
  style?: React.CSSProperties
  theme?: Theme
  variant?: Variant
  callbackValue?: any
  placeHolder?: string
  disabled?: boolean
  value?: string
  autoComplete?: string
  showClearButton?: boolean
  showClearIconButton?: boolean
  startAdornmentNode?: React.ReactNode
  onChange: (event: any, callbackValue: any) => any
  onFocus?: (event: any, callbackValue: any) => any
  onBlur?: (event: any, callbackValue: any) => any
  onClear?: (callbackValue: any) => any
  onKeyPress?: (callbackValue: any) => any
}

export enum Theme {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  inverse = 'inverse',
}

export enum Variant {
  rounded = 'rounded',
  square = 'square',
  flat = 'flat',
}