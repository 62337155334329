import axios from 'axios';
import Transport from 'winston-transport';

interface SeqAxiosTransportOptions {
  serverUrl: string;
  apiKey?: string;
  level?: string;
}

export class SeqAxiosTransport extends Transport {
  private serverUrl: string;
  private apiKey?: string;

  constructor(options: SeqAxiosTransportOptions) {
    super(options);
    this.serverUrl = options.serverUrl;
    this.apiKey = options.apiKey;
  }

  log(info: any, callback: () => void) {
    const { message, level, ...meta } = info;

    const levelMap: {[key: string]: string} = {
      error: 'Error',
      warn: 'Warning',
      info: 'Information',
      http: 'Information',
      verbose: 'Verbose',
      debug: 'Debug',
      silly: 'Debug'
    };

    // Prepare the log event payload
    const payload = {
      Events: [
        {
          Timestamp: new Date().toISOString(),
          Level: levelMap[level] || level, // Log level: Information, Error, Warning, etc.
          MessageTemplate: message,
          Properties: meta
        }
      ]
    };

    // Configure Axios POST request
    axios.post(this.serverUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        ...(this.apiKey ? { 'X-Seq-ApiKey': this.apiKey } : {}),
      },
    })
    .then((response) => {
      // Inform Winston that the log has been processed
      console.log("Log sent to Seq:", response?.data);
      callback();
    })
    .catch((error) => {
      // Handle errors if needed
      console.error('Error logging to Seq:', error);
      callback();
    });
  }
}
