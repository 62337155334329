import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseDay } from '@fortawesome/pro-solid-svg-icons';
import Avatar from '@mui/material/Avatar';
import SendIcon from '@mui/icons-material/Send';

import ErrorPageThemer from './ErrorPage.theme';
import { ErrorPageProps } from './ErrorPage.types';
import { Button } from '@material-ui/core';

const ErrorPageComponent = forwardRef<HTMLDivElement, ErrorPageProps>((props, ref) => {
  const {errorCode} = props;
  const classes = ErrorPageThemer.useStyles();

  const handleClick = (e: any) => {
    e.preventDefault();
    console.log('Send Email was clicked.');
  }

  return (
    <div className={classes.home}>
      <p style={{ color: "white", fontSize: 18 }}>ErrorCode: {errorCode}</p>
      {errorCode === 100 && <p style={{ color: "white", fontSize: 18 }}>Something went wrong. Seems you do not have the right access to use this app. Please contact rocketphone.ai to get access.</p>}
      {errorCode === 401 && <>
        <p style={{ color: "white", fontSize: 18 }}>Authentication Failed.Please try logging in and out of Salesforce. If the problem persists, please make contact with the rocketphone.ai support team</p>
        <Button variant="contained" endIcon={<SendIcon />} color="primary" onClick={handleClick}>
          Send Email
        </Button>
      </>}
    </div>
  );
});

ErrorPageComponent.defaultProps = {};

export default ErrorPageComponent;
