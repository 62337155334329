import { v4 as uuidv4 } from 'uuid';
import { AxiosResponse } from 'axios'; // Import Axios and AxiosResponse

const TenantCodeHeaderKey = 'X-TENANT-CODE';
const CorrelationTokenHeaderKey = 'X-Correlation-Token';

export default class ApiHelper {
  static createHeader(token?: string, tenantCode?: string) {
    let result:any = {};

    result['Accept'] = 'application/json';
    result['Content-Type'] =  'application/json';
    result[CorrelationTokenHeaderKey] = uuidv4();

    // The token and tenantCode can be optionals
    // if (token) result.Authorization = `Bearer ${token}`;
    if (token) result['Authorization'] = `Bearer ${token}`;
    if (tenantCode) result[TenantCodeHeaderKey] = tenantCode;

    return result;
  }
  static createOpenCtiHeader(token: string, tenantCode: string) {
    const result = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      [CorrelationTokenHeaderKey]: uuidv4(),
      Authorization: '',
      [TenantCodeHeaderKey]: '',
    };

    // The token and tenantCode can be optionals
    // if (token) result.Authorization = `Bearer ${token}`;
    if (token) result.Authorization = `Bearer ${token}`;
    if (tenantCode) result[TenantCodeHeaderKey] = tenantCode;

    return result;
  }

  static SuccessResponse(axiosResponse: AxiosResponse<any, any>) {
    const responseData = axiosResponse.data;
    const responseConfig = axiosResponse.config;
  
    // Create a new AxiosResponse object
    const newAxiosResponse: AxiosResponse<any, any> = {
      data: responseData,
      status: axiosResponse.status,
      statusText: axiosResponse.statusText,
      headers: responseConfig.headers,
      config: responseConfig,
    };
  
    return newAxiosResponse;
  }
}
