import React, { forwardRef } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';

import { PopperProps } from './Popper.types';
import PopperThemer from './Popper.theme';
import { useCallback } from 'react';

const ThemedPaper = withStyles(PopperThemer.classes)(Paper);

const PopperComponent = forwardRef<HTMLDivElement, PopperProps>((props, ref) => {
  const {
    id,
    className, style,
    anchorEl,
    callbackValue,
    open, onClose,
    placement,
    skipMenuList,
    menuListStyle, menuListClassName,
    menuItems,
    disablePortal,
    transition,
  } = props;

  const handleClose = useCallback(() => {
    onClose(callbackValue);
  }, [callbackValue, onClose])

  // The children of ClickAwayListener must be one valid child
  // Can't be bool, can't be undefined, can't be null
  const content = (skipMenuList && menuItems) ? (menuItems) : (
    <MenuList id={`${id}-Menu-List`} className={menuListClassName} style={menuListStyle} onClick={handleClose}>
      {menuItems}
    </MenuList>
  );

  return (
    <Popper
      id={id}
      ref={ref}
      className={className}
      style={style}
      anchorEl={anchorEl as any}
      open={open}
      onClick={handleClose}
      placement="bottom-end"
      transition={transition}
      disablePortal={disablePortal}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: false,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <ThemedPaper>
            <ClickAwayListener onClickAway={handleClose}>
              {content}
            </ClickAwayListener>
          </ThemedPaper>
        </Grow>
      )}
    </Popper>
  );
});

PopperComponent.defaultProps = {
  callbackValue: undefined,
  skipMenuList: false,
  menuListClassName: undefined,
  menuListStyle: undefined,
  disablePortal: false,
  transition: false,
  placement: 'bottom',
};

export default PopperComponent;
