// import http from "../../http-common";
import ApiHelper from '../../helpers/apiHelper';
import VoiceIQApiAxiosInstance from '../instances/VoiceIQAPI';
import { store } from '../../app/store';

const getScheduledCalls = (userId: string, startTime: string, tenantCode: string, AccessToken: string) => {
  const params = [
    'scheduledType=Scheduled',
    `userId=${userId}`,
    `startTime=${startTime}`,
    'status=Upcoming',
    'order=Asc',
  ];
  const queryParams = params.join('&');

  const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(AccessToken, tenantCode),
    url: `/api/v2/callevents?${queryParams}`,
    method: 'get',
  };

  return VoiceIQApiAxiosInstance.request(config);
};

const ScheduledCallsService = { getScheduledCalls };

export default ScheduledCallsService;