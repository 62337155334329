import React from 'react';
import { BadgeOrigin, BadgeClassKey } from '@material-ui/core/Badge';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Placement as BadgePlacement } from '../Badge/Badge.types';

export interface CallStatusBadgeProps {
  id: string;
  classes?: Partial<ClassNameMap<BadgeClassKey>>;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  statusType: StatusType,
  placement?: BadgePlacement,
  callStatusTooltip?: string,
};

export enum StatusType {
  inboundMissed = 'inbound-missed',
  inboundReceived = 'inbound-received',
  outboundMissed = 'outbound-missed',
  outboundReceived = 'outbound-received',
  salesforce = 'cloud',
};

export enum Placement {
  default = 'default',
  topRight = 'top-right',
  bottomRight = 'bottom-right',
  bottomLeft = 'bottom-left',
  topLeft = 'top-left',
};

export interface PlacementMuiAnchorOriginMap {
  [Placement.default]: BadgeOrigin,
  [Placement.topRight]: BadgeOrigin,
  [Placement.bottomRight]: BadgeOrigin,
  [Placement.bottomLeft]: BadgeOrigin,
  [Placement.topLeft]: BadgeOrigin,
};

export { Theme, ThemeMuiColourMap } from '../Badge/Badge.types';
