export const DefaultUserStatuses = Object.freeze({
  Available: 'Available',
  Unavailable: 'Unavailable',
  OnCall: 'On Call',
  Offline: 'Offline',
});

export const DefaultUserStatusesColours = Object.freeze({
  Available: '#4caf50',
  OnCall: '#f44336',
  Offline: '#f44336',
});

