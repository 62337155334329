import { createLogger, format, transports } from 'winston';
import { SeqAxiosTransport } from './SeqAxiosTransport';

// Access environment variables for Seq URL and API key
const SEQ_SERVER_URL = process.env.REACT_APP_SEQ_SERVER_URL;
const SEQ_API_KEY = process.env.REACT_APP_SEQ_API_KEY;

if (!SEQ_SERVER_URL || !SEQ_API_KEY) {
  throw new Error('Seq configuration missing: Check environment variables.');
}
// Set up Winston logger with custom Seq Axios transport
const logger = createLogger({
  level: 'info',
  format: format.combine(
    format.timestamp(),
    format.json()
  ),
  transports: [
    new SeqAxiosTransport({
      serverUrl: `${SEQ_SERVER_URL}/api/events/raw`,  // URL of your Seq instance
      apiKey: SEQ_API_KEY,        // API key (if required)
    }),
    new transports.Console({ format: format.simple() }),  // Log to console as well
  ],
});

export default logger;
