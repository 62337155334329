import { v4 as uuidv4 } from 'uuid';

import TranscriptsApi from '../../Services/Transcript/Transcripts';
import { BookmarkStatusEnum } from '../../constants/BookmarkStatusEnum';
import TranscriptionHelper from '../../helpers/transcriptionHelper';
import TranscriptionSegmentService from './Segment';

// const LogTitle = 'TranscriptionFileService';
interface TranscriptSegment {
  id: string; // Change the type to the actual type of threadId
  source: string; // Change the type to the actual type of transcriptSegment.source
  speaker: string; // Change the type to the actual type of transcriptSegment.speaker
  content?: string; // Change the type to the actual type of transcriptSegment.content
  timeStartInSeconds: any;
  timeEndInSeconds: any;
};
interface FinalisedSegment {
  id: string; // Change the type to the actual type of threadId
  source: string; // Change the type to the actual type of transcriptSegment.source
  speaker: string; // Change the type to the actual type of transcriptSegment.speaker
  transcript: string;
  startTime: number; // Change the type to the actual type of startTime
  endTime: number; // Change the type to the actual type of endTime
};
interface Result {
  finalised: FinalisedSegment[];
  processed: any[]; // You may want to replace 'any[]' with the actual type of processed
};

const timeStampToSeconds = (timestamp: any) => {
  let result = -1;

  if (timestamp) {
    const [hours = 0, minutes = 0, seconds = 0] = timestamp.split(':');
    result = (parseInt(hours, 10) * 3600) + (parseInt(minutes, 10) * 60) + parseInt(seconds, 10);
  }

  return result;
};

class TranscriptionFileService {
  fetchTranscriptSegments = async (transcriptionUrl: string) => {
    // Validating the transcription URL
    if (!transcriptionUrl) {
      // Logger.warn(LogTitle, 'Transcription URL not given. Skipping', { transcriptionUrl });
      return [];
    }

    const transcriptResponse = await TranscriptsApi.loadTranscript(transcriptionUrl);
    const transcriptionContent = transcriptResponse.data;
    const result = TranscriptionHelper.parseTranscript(transcriptionContent);

    return result;
  }

  resolveTranscriptionSegments = (contactName: string, transcriptSegments: any, bookmarks: any) => {
    // We put 'Contact' here to reference an 'Unknown Contact', since there wont be any contact name for an Unknown Contact
    const isSpeakerContact = (speakerName: string) => speakerName === contactName || speakerName === 'Contact';

    const bookmarkTranscriptionSegmentsMap: any = {};
    const transcriptionBookmarksMap: any = {};

    bookmarks.forEach((bookmark: any) => {
      const { transcriptionSegments, status } = bookmark;
      const bookmarkNotRejected = status !== BookmarkStatusEnum.Rejected;

      // Only consider bookmarks that are not rejected
      if (bookmarkNotRejected) {
        transcriptionSegments.forEach((transcriptionSegment: any) => {
          const startTime = timeStampToSeconds(transcriptionSegment.startTime);
          const endTime = timeStampToSeconds(transcriptionSegment.endTime);
          const key = `${transcriptionSegment.speakerType}: ${startTime} -> ${endTime}`;

          bookmarkTranscriptionSegmentsMap[key] = transcriptionSegment;

          // Making sure we are not trying to push shit into undefined
          if (!transcriptionBookmarksMap[transcriptionSegment.id]) transcriptionBookmarksMap[transcriptionSegment.id] = [];

          // Now let's add the Bookmark into the correct place
          transcriptionBookmarksMap[transcriptionSegment.id].push(bookmark);
        });
      }
    });

    // const result = { finalised: [], processed: [] };
    const result: Result = { finalised: [], processed: [] };

    (transcriptSegments || []).forEach((transcriptSegment: TranscriptSegment) => {
      const startTime = transcriptSegment.timeStartInSeconds;
      const endTime = transcriptSegment.timeEndInSeconds;
      const key = `${isSpeakerContact(transcriptSegment.speaker) ? 'Contact' : 'Agent'}: ${startTime} -> ${endTime}`;
      const threadId = bookmarkTranscriptionSegmentsMap?.[key]?.id || uuidv4();
      const bookmarksOfTranscript = transcriptionBookmarksMap[threadId] || [];

      const finalisedSegment: FinalisedSegment = {
        id: threadId,
        source: transcriptSegment.source,
        speaker: transcriptSegment.speaker,
        transcript: transcriptSegment.content?.trim().replace(/\s\s+/g, ' ') || '',
        startTime,
        endTime,
      };
      const processedSegment = TranscriptionSegmentService.processSegment(finalisedSegment, bookmarksOfTranscript, true);

      result.finalised.push(finalisedSegment);
      result.processed.push(processedSegment);
    });

    return result;
  }
}

export default new TranscriptionFileService();
