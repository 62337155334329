import React from 'react';

export interface TypographyProps {
  id?: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  variant?: Variant;
  bottomMargin?: boolean;
  noWrap?: boolean;
}

export enum Variant {
  title = 'title',
  active = 'active',
  input = 'input',
  system = 'system',
  label = 'label',
  disabled = 'disabled',
  prompt = 'prompt',
  light = 'light',
  navigation = 'navigation',
  error = 'error',
  primary = 'primary',
  secondary = 'secondary',
}
