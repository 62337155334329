import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    minHeight: '384px',
    backgroundColor: '#001932',
    boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.24)',
    borderRadius: '24px'
  },
  diallerButton: {
    margin: '12px'
  },
  buttonArea: {
    margin: '12px 24px'
  }
});

export default { useStyles };
