import StringUtil from '../String';
import Colours, { SecondaryColourSet } from '../../Theme/Colours';

const RGBPattern = /(\d+),[ ]{0,1}(\d+),[ ]{0,1}(\d+)/;

const componentToHex = (component: number) => `0${component.toString(16)}`.substr(-2);
const rgbToHex = (colour: string) => {
  const [red = '255', green = '255', blue = '255'] = colour.match(RGBPattern) || [];

  return `#${componentToHex(Number(red))}${componentToHex(Number(green))}${componentToHex(Number(blue))}`;
};

const isRGB = (colour: string) => RGBPattern.test(colour);

/**
 * The text colour derivation logic might not be perfect
 * We can use the overrides to manage specific edge cases
 */
const TextColourOverrides = {
  [Colours.Secondary.Red.normal]: Colours.Typography.light,
  [Colours.Secondary.Orange.normal]: Colours.Typography.light,
  [Colours.Secondary.Yellow.normal]: Colours.Typography.input,
  [Colours.Secondary.Green.normal]: Colours.Typography.light,
  [Colours.Secondary.Teal.normal]: Colours.Typography.light,
  [Colours.Secondary.Blue.normal]: Colours.Typography.light,
  [Colours.Secondary.DarkBlue.normal]: Colours.Typography.light,
  [Colours.Secondary.Indigo.normal]: Colours.Typography.light,
  [Colours.Secondary.Purple.normal]: Colours.Typography.light,
  [Colours.Secondary.DarkGrey.normal]: Colours.Typography.light,
};

/**
 * Utility class to manage colour functions
 */
class ColourUtil {
   colourOptions = Object
      .values(Colours.Secondary)
      .map((colourSet: SecondaryColourSet) => colourSet.normal)

  /**
   * Validates a given string to be a valid colour string
   *
   * @param  {string}  colour Colour string to validate
   * @return {boolean}        True if the given colour is a valid colour 
   */
  isColour = (colour: string | null | undefined) => {
    // No point fannying around with colour validations if it's not even a valid value
    if (!colour) return false;

    const style = new Option().style;
    style.color = colour;

    return !!style.color;
  }

  /**
   * Gets the contrasting colour to use for the foreground text
   *
   * @param  {string} backgroundColour Background colour hex code
   * @return {string}                  Contrasting colour to the given background
   */
  getContrastingTextColour = (backgroundColour: string): string => {
    const textColourOverride = TextColourOverrides[backgroundColour];

    // If we have a override value, we don't have to move into the whole logic thing
    if (textColourOverride) return textColourOverride;

    const hexColour = isRGB(backgroundColour) ? rgbToHex(backgroundColour) : backgroundColour;
    const formattedHexColour = hexColour.replace('#', '');
    const red = parseInt(formattedHexColour.substr(0, 2), 16);
    const green = parseInt(formattedHexColour.substr(2, 2), 16);
    const blue = parseInt(formattedHexColour.substr(4, 2), 16);
    const yiq = ((red * 299) + (green * 587) + (blue * 114)) / 1000;
  
    return yiq >= 128 ? Colours.Typography.title : Colours.Typography.light;
  }

  /**
   * Gets the matching secondary colour set from a given colour
   *
   * @param   {string}                         colourOption Colour option to search
   * @param   {SecondaryColourSet | undefined} defaultValue Default value to return if not found
   * @returns {SecondaryColourSet | undefined}              The result
   */
  getMatchingSecondaryColourPaletteFromNormal = (colourOption: string, defaultValue?: SecondaryColourSet) => {
    const matchingPalette: SecondaryColourSet | undefined = Object
      .values(Colours.Secondary)
      .filter((colourSet: SecondaryColourSet) => colourSet.normal === colourOption)[0];

    return matchingPalette ?? defaultValue;
  }

  /**
   * Gets a deterministic Colour matching the given text
   *
   * @param   {string} value Value to match
   * @returns {string}       Matching colour
   */
  getBackgroundColourOfString = (value: string) => this.colourOptions[StringUtil.numberFromText(value) % this.colourOptions.length]

  /**
   * Gets the matching background colour of a colour option
   * The colour option MUST be a selection from the Design Library
   *
   * @param   {string}        colourOption  The colour option to query
   * @returns {string | null}               The matching background colour
   */
  getBackgroundColourOfColourOption = (colourOption: string): string | null => this.getMatchingSecondaryColourPaletteFromNormal(colourOption)?.hovered ?? null
};

export enum SecondaryPalette {
  red = 'Red',
  orange = 'Orange',
  yellow = 'Yellow',
  green = 'Green',
  teal = 'Teal',
  blue = 'Blue',
  darkBlue = 'DarkBlue',
  indigo = 'Indigo',
  purple = 'Purple',
  darkGrey = 'DarkGrey',
}

export const SecondaryPaletteColourSetMap = {
  [SecondaryPalette.red]: Colours.Secondary.Red,
  [SecondaryPalette.orange]: Colours.Secondary.Orange,
  [SecondaryPalette.yellow]: Colours.Secondary.Yellow,
  [SecondaryPalette.green]: Colours.Secondary.Green,
  [SecondaryPalette.teal]: Colours.Secondary.Teal,
  [SecondaryPalette.blue]: Colours.Secondary.Blue,
  [SecondaryPalette.darkBlue]: Colours.Secondary.DarkBlue,
  [SecondaryPalette.indigo]: Colours.Secondary.Indigo,
  [SecondaryPalette.purple]: Colours.Secondary.Purple,
  [SecondaryPalette.darkGrey]: Colours.Secondary.DarkGrey,
};

export default new ColourUtil();
