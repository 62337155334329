import React, { forwardRef } from 'react';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { IconProps, Theme, Size} from './Icon.types';
import IconThemer from './Icon.theme';

const ThemedIcon = withStyles(IconThemer.classes)(Icon);

const IconComponent: React.FC<IconProps> = forwardRef<HTMLSpanElement, IconProps>((props, ref) => {
  const { id, children, className, style, size, theme } = props;
  const classes = IconThemer.useStyles();
  const themeClassName = classes[theme ?? Theme.dark];
  const sizeClassName = classes[size ?? Size.medium];

  return (
    <ThemedIcon
      id={id}
      ref={ref}
      className={(classnames(themeClassName, sizeClassName, className))}
      style={style}
    >
      {children}
    </ThemedIcon>
  );
});

IconComponent.defaultProps = {
  id: undefined,
  className: undefined,
  style: undefined,
  theme: Theme.inherit,
  size: Size.medium,
};

export const IconTheme = Theme;
export const IconSize = Size;
export default IconComponent;
