import VoiceIQApiAxiosInstance from '../instances/VoiceIQAPI';
import ApiHelper from '../../helpers/apiHelper';
import { store } from '../../app/store';

export default class CallCentre {
  static async initiateCall(tenantCode: string, token: string, callRequest: any ) {
    const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: '/api/v1/callcentre/call',
      method: 'post',
      data: callRequest,
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  static async assignObjectToCall(tenantCode: string, token: string, callRequest: any ) {
    const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: '/api/v1/callcentre/call/assignobject',
      method: 'post',
      data: callRequest,
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  static async getCallForwardOptions(
    tenantCode: string,
    token: string,
    searchTerm = '',
    includeTeams = true,
    includeBusyUsers = false,
    includeUnavailableUsers = false,
    includeBusyTeams = false,
    includeUnavailableTeams = false,
  ) {
    const urlParams = `searchTerm=${searchTerm}&` +
      `includeTeams=${includeTeams}&` +
      `includeBusyUsers=${includeBusyUsers}&` +
      `includeUnavailableUsers=${includeUnavailableUsers}&` +
      `includeBusyTeams=${includeBusyTeams}&` +
      `includeUnavailableTeams=${includeUnavailableTeams}`;
    const config = {
      baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: `/api/v1/callcentre/call/forward/options?${urlParams}`,
      method: 'get',
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  // static async getAvailableUsers(tenantCode, token) {
  //   const config = {
  //     headers: ApiHelper.createHeader(token, tenantCode),
  //     url: '/api/v1/callcentre/call/forward/options/user',
  //     method: 'get',
  //   };

  //   return VoiceIQApiAxiosInstance.request(config);
  // }

  // static async getAvailableTeams(tenantCode, token) {
  //   const config = {
  //     headers: ApiHelper.createHeader(token, tenantCode),
  //     url: '/api/v1/callcentre/call/forward/options/team',
  //     method: 'get',
  //   };

  //   return VoiceIQApiAxiosInstance.request(config);
  // }

  static forwardCallToUser(tenantCode: string, token: string, callRecordId: string, destinationUserId: string, callDirection: string) {
    const config = {
      baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: '/api/v1/callcentre/call/forward',
      method: 'post',
      data: {
        UserId: destinationUserId,
        CallRecordId: callRecordId,
        CallDirection: callDirection,
      },
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  static forwardCallToTeam(tenantCode: string, token: string, callRecordId: string, destinationTeamId: string, callDirection: string) {
    const config = {
      baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: '/api/v1/callcentre/call/forward',
      method: 'post',
      data: {
        TeamId: destinationTeamId,
        CallRecordId: callRecordId,
        CallDirection: callDirection,
      },
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  static forwardCallToExternalNumber(tenantCode: string, token: string, callRecordId: string, number: any, callDirection: string) {
    const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: '/api/v1/callcentre/call/forward',
      method: 'post',
      data: {
        UserId: null,
        TeamId: null,
        phoneNumber: number,
        CallRecordId: callRecordId,
        CallDirection: callDirection,
      },
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  static holdCall(callRecordId: any, tenantCode: string, token: string) {
    const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: '/api/v1/callcentre/call/hold',
      method: 'post',
      data: { CallRecordId: callRecordId },
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  static hangupCall(callRecordId: any, tenantCode: string, token: string) {
    const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: '/api/v1/callcentre/call/hangup',
      method: 'post',
      data: { CallRecordId: callRecordId },
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  static resumeCall(callRecordId: any, tenantCode: string, token: string) {
    const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: '/api/v1/callcentre/call/resume',
      method: 'post',
      data: { CallRecordId: callRecordId },
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  // static reconnectCustomerToConferenceCall(tenantCode, token, previousCallRecordId, callRecordId) {
  //   const config = {
  //     headers: ApiHelper.createHeader(token, tenantCode),
  //     url: '/api/v1/callcentre/call/conference/reconnect',
  //     method: 'post',
  //     data: {
  //       PreviousCallRecordId: previousCallRecordId,
  //       CallRecordId: callRecordId,
  //     },
  //   };

  //   return VoiceIQApiAxiosInstance.request(config);
  // }

  // static dialUserToConference(tenantCode, token, previousCallRecordId, newCallRecordId, currentUserId, destinationUserId) {
  //   const config = {
  //     headers: ApiHelper.createHeader(token, tenantCode),
  //     url: '/api/v1/callcentre/call/conference/dial',
  //     method: 'post',
  //     data: {
  //       CallRecordId: previousCallRecordId,
  //       NewCallRecordId: newCallRecordId,
  //       InitiatorAgentId: currentUserId,
  //       NewAgentId: destinationUserId,
  //     },
  //   };

  //   return VoiceIQApiAxiosInstance.request(config);
  // }

  // static holdConferenceContact(tenantCode, token, callRecordId) {
  //   const config = {
  //     headers: ApiHelper.createHeader(token, tenantCode),
  //     url: '/api/v1/callcentre/call/conference/contact/hold',
  //     method: 'post',
  //     data: { CallRecordId: callRecordId },
  //   };

  //   return VoiceIQApiAxiosInstance.request(config);
  // }

  // static resumeConferenceContact(tenantCode, token, callRecordId) {
  //   const config = {
  //     headers: ApiHelper.createHeader(token, tenantCode),
  //     url: '/api/v1/callcentre/call/conference/contact/un-hold',
  //     method: 'post',
  //     data: { CallRecordId: callRecordId },
  //   };

  //   return VoiceIQApiAxiosInstance.request(config);
  // }

  // static callRecordDraft(tenantCode, token, callRecordId, draftData) {
  //   const config = {
  //     headers: ApiHelper.createHeader(token, tenantCode),
  //     url: `/api/v1/calldetails/${callRecordId}/draft`,
  //     method: 'patch',
  //     data: draftData,
  //   };

  //   return VoiceIQApiAxiosInstance.request(config);
  // }

  static getCallRecord(tenantCode: string, token: string, callRecordId: string ) {
    const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: `/api/v1/calldetails/${callRecordId}`,
      method: 'get',
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  static callRecordFinalize(tenantCode: string, token: string, callRecordId: string, requestBody: any ) {
    const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: `/api/v1/calldetails/${callRecordId}/finalize`,
      method: 'put',
      data: requestBody,
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  static async getLocalNumber(tenantCode: string, token: string, contactNumber: string) {
    const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
      headers: ApiHelper.createHeader(token, tenantCode),
      url: `/api/v1/callcentre/local-number?contactNumber=${contactNumber}`,
      method: 'get',
    };

    return VoiceIQApiAxiosInstance.request(config);
  }

  // static async getCallQueueData(tenantCode, token, teamId) {
  //   const config = {
  //     headers: ApiHelper.createHeader(token, tenantCode),
  //     url: `/api/v1/callcentre/queue/team/${teamId}`,
  //     method: 'get',
  //   };

  //   return VoiceIQApiAxiosInstance.request(config);
  // }
}
