import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import TenantService from "../Services/Tenant/TenantService";
import { RootState } from '../app/store';

const initialState: any = { tenantSettings: {}, callDispositions: {} };

export const getTenantSettings: any = createAsyncThunk(
  "getTenantSettings/get",
  async (_, { getState }) => {
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const result = await TenantService.getTenantSettings(tenantCode, AccessToken);

    return result.data;
  }
);

export const getCallDispositions: any = createAsyncThunk(
  "getCallDispositions/get",
  async (_, { getState }) => {
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const result = await TenantService.getCallDispositions(tenantCode, AccessToken);

    return result.data;
  }
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: {
    [getTenantSettings.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.tenantSettings = data;
    },
    [getCallDispositions.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.callDispositions = data;
    },
  }
});

const { reducer } = settingsSlice;
export default reducer;
