import classnames from 'classnames';
import { forwardRef, useCallback } from 'react';
import { faPhone, faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '../../../Avatar';
import Tooltip from '../../../Tooltip';
import AvatarGroup from '../../../AvatarGroup';
import { CallMedium } from '../../../../Enums/ActivityEnum';
import { AvatarService } from '../../../../Services/Avatar';
import Typography, { TypographyVariant } from '../../../Typography';
import DropdownButton, { DropdownButtonSplitTheme } from '../../../Split';
import CallStatusBadge, { CallStatusBadgeStatusType } from '../../../CallStatusBadge';
import CallActionAvatar, { CallActionAvatarType, CallActionAvatarVariant, CallActionAvatarSize } from '../../../CallActionAvatar'

import CallHistoryRowThemer from './CallHistoryRow.theme';
import { CallHistoryRowProps, CallDirection, Theme } from './CallHistoryRow.types';

const CallHistoryRowComponent = forwardRef<HTMLDivElement, CallHistoryRowProps<any>>((props, ref) => {
  const {
    id,
    className,
    style,
    avatarUrl,
    avatarFallbackLabel,
    callFailed,
    callDirection,
    callMedium,
    date, time,
    duration,
    avatarClassName,
    actionsMaxCount,
    actions,
    getActionNode,
    showActionsOverflowTooltip,
    getActionsOverflowCountTooltipContent,
    showCallActionButton,
    callUnwrapped,
    theme,
    item,
    menuClassName, menuStyle, menuListClassName, menuListStyle,
    callButtonDisabled,
    getMenuItems, onCall, onHistoryRowClick, onAvatarClick,
    getCallStatusBadgeTooltip,
  } = props;
  const classes  = CallHistoryRowThemer.useStyles(props);
  const callStatusBadgeClasses = CallHistoryRowThemer.useCallStatusBadgeStyles();
  const rowClassName = classnames(
    classes.row,
    { [classes.rowNormal]: theme === Theme.normal, [classes.rowInverse]: theme === Theme.inverse },
    className,
  );
  const callIsInbound = callDirection === CallDirection.inbound;
  const isSalesforceCall = callMedium === CallMedium.salesForce;
  const isRocketCellCall = callMedium === CallMedium.rocketCell;

  let callStatusType = CallStatusBadgeStatusType.inboundReceived;
  if (callFailed) callStatusType = callIsInbound ? CallStatusBadgeStatusType.inboundMissed : CallStatusBadgeStatusType.outboundMissed;
  else callStatusType = callIsInbound ? CallStatusBadgeStatusType.inboundReceived : CallStatusBadgeStatusType.outboundReceived;
  
  const handleDefaultCallClick = useCallback((cb:any, e:any) => {
    e.stopPropagation();
    onCall(item);
  }, [onCall, item]);

  const handleHistoryRowClick = () => {
    onHistoryRowClick?.(item);
  };
  const handleAvatarClick = (e:any) => {
    e.stopPropagation();
    onAvatarClick?.(item);
  };

  const avatarStyle = {
    backgroundColor: AvatarService.getBackgroundColour(avatarUrl, null),
  };
  const fallbackTextStyle = {
    color: AvatarService.getTextColour(avatarUrl, null),
  };

  return (
    <div
      id={id}
      ref={ref}
      className={classnames(rowClassName)}
      style={style}
      onClick={handleHistoryRowClick}
    >
      <div className={classnames(classes.columnAvatar)} onClick={handleAvatarClick}>
        <CallStatusBadge
          id={`${id}-StatusBadge`}
          classes={callStatusBadgeClasses}
          statusType={callStatusType}
          callStatusTooltip={getCallStatusBadgeTooltip?.(item)}
        >
          <Avatar
              id={`${id}-Avatar`}
              style={avatarStyle}
              fallbackTextStyle={fallbackTextStyle}  
              className={classnames(classes.avatar, avatarClassName)}
              url={avatarUrl}
              fallbackLabel={avatarFallbackLabel}
              alt="Contact Avatar"
            />
          {/* {isSalesforceCall && (
            <Avatar
              id={`${id}-Avatar`}
              style={avatarStyle}
              fallbackTextStyle={fallbackTextStyle}  
              className={classnames(classes.avatar, avatarClassName)}
              url={avatarUrl}
              fallbackLabel={avatarFallbackLabel}
              alt="Contact Avatar"
            />
          )}
          {!isSalesforceCall && (
            <Tooltip
              id={`${id}-CallStatus-Tooltip-Wrapper`}
              content={<Typography.P2 variant={TypographyVariant.light}>{avatarFallbackLabel}</Typography.P2>}
              placement="bottom"
              arrow
            >
              <div className={classes.callMediumIcon}>
                <FontAwesomeIcon icon={isRocketCellCall ? faPhone : faRocket } />
              </div>
            </Tooltip>
          )} */}
        </CallStatusBadge>
      </div>

      <div className={classnames(classes.columnTimestamp)}>
        <Typography.P1
          id={`${id}-Timestamp-Date`}
          className={classnames(classes.timestampText)}
          variant={TypographyVariant.active}
          noWrap
        >
          {date}
        </Typography.P1>
        <Typography.P1
          id={`${id}-Timestamp-Time`}
          className={classnames(classes.timestampText)}
          variant={TypographyVariant.active}
          noWrap
        >
          {time}
        </Typography.P1>
      </div>

      <div className={classes.columnActions}>
        {!callFailed && callUnwrapped && (
          <CallActionAvatar
            id={`${id}-ActionIncompleted`}
            key={`${id}-ActionIncompleted`}
            className={classnames(classes.flagIcon)}
            size={CallActionAvatarSize.small}
            type={CallActionAvatarType.incomplete}
            variant={CallActionAvatarVariant.normal}
          />
        )}

        {actions && (
          <AvatarGroup
            id={`${id}-ActionsGroup`}
            key={`${id}-ActionsGroup`}
            data={actions}
            item={item}
            getAvatarNode={getActionNode}
            max={!callFailed && callUnwrapped ? 2 : actionsMaxCount}
            overflowCountHasTooltip={showActionsOverflowTooltip}
            getOverflowCountTolltipContent={getActionsOverflowCountTooltipContent}
          />
        )}
      </div>
      <div className={classnames(classes.columnDuration, { [classes.columnDurationWithCallButton]: showCallActionButton })}>
        <Typography.P1
          id={`${id}-Duration`}
          className={classnames(classes.durationText)}
          variant={TypographyVariant.active}
          noWrap
        >
          {duration}
        </Typography.P1>
      </div>

      <div className={classnames({ [classes.callButton]: showCallActionButton })}>
        <DropdownButton
          id={`${id}-CallActionButton`}
          menuClassName={classnames(menuClassName)}
          menuStyle={menuStyle}
          menuListClassName={classnames(menuListClassName)}
          menuListStyle={menuListStyle}
          theme={DropdownButtonSplitTheme.secondary}
          disabled={callButtonDisabled}
          menuItems={getMenuItems(item)}
          onPrimaryButtonClick={handleDefaultCallClick}
          usePopper
        >
          Call
        </DropdownButton>
      </div>
    </div>
  );
});

CallHistoryRowComponent.defaultProps = {
  className: undefined,
  style: undefined,
  callFailed: false,
  callUnwrapped: false,
  showNavigationNode: false,
  showCallActionButton: false,
  showNavNodeOnHover: false,
  navigationNode: undefined,  //may cause problem   () => null
  theme: Theme.normal,
  callButtonDisabled: false,
  onCall: () => null,
  onHistoryRowClick: () => null,
  onAvatarClick: () => null,
  getCallStatusBadgeTooltip: () => '',
};

export {
  Theme as CallHistoryRowTheme,
  CallDirection as CallHistoryCallDirection,
} from './CallHistoryRow.types';
export default CallHistoryRowComponent;
