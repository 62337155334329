import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { data: [] };

const contactSearchByPhoneSlice = createSlice({
  name: 'contactSearchByPhone',
  initialState,
  reducers: {
    setMatchingContacts(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
    cleanupContactSearchByPhone(state) {
      state.data = []
    }
  },
  extraReducers: {
  }
});

export const { cleanupContactSearchByPhone, setMatchingContacts } = contactSearchByPhoneSlice.actions;
export default contactSearchByPhoneSlice.reducer;
