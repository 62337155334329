import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../Theme/Colours';

import { CallActionAvatarProps, Size, Type, Variant } from './CallActionAvatar.types';

const TypeBackgroundColourMap = {
  default: '#5778EE',
  [Type.opportunity]: '#50CF97',
  [Type.case]: '#5778EE',
  [Type.note]: '#5778EE',
  [Type.gdpr]: '#F3605A',
  [Type.vulnerability]: '#FF7C80',
  [Type.callback]: '#5869E2',
  [Type.voicemail]: '#009FD9',
  [Type.email]: '#158BEA',
  [Type.sms]: '#158BEA',
  [Type.incomplete]: '#50CF97',
  [Type.hotTransfer]: '#009FD9',
  [Type.callOutcome]: '#009FD9',
};
const SizeMap = {
  default: 24,
  [Size.small]: 24,
  [Size.medium]: 36,
  [Size.large]: 48,
};

const classes = {};
const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: (props: CallActionAvatarProps) => SizeMap[props.size ?? Size.small],
    height: (props: CallActionAvatarProps) => SizeMap[props.size ?? Size.small],
    borderRadius: '50%',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    height: '80%',
    color: (props: CallActionAvatarProps) => {
      let result = Colours.Typography.light;

      if (props.variant === Variant.inverse) result = TypeBackgroundColourMap[props.type] || TypeBackgroundColourMap.default;

      return result;
    },
  },
  hotTransferToolTip: {
    display: 'block',
  }
});

export default { classes, useStyles };
