import { parsePhoneNumberFromString, getCountryCallingCode, AsYouType, CountryCode  } from 'libphonenumber-js';

const defaultNumberFormat = { id: null, number: '', country: '', countryCallingCode: '', nationalNumber: '' };

export default class PhoneNumberService {
  // static safeParsePhoneNumber = (number: any, defaultValueRequired = false) => {
  //   let result = defaultValueRequired ? defaultNumberFormat : null;

  //   if (number) {
  //     // We need to parse it and get rid of the metadata attribute because
  //     //   1. It is absolutely unnecessary for us in this context
  //     //   2. It's fucking HUUUUUGE!
  //     const parsedNumber = parsePhoneNumberFromString(number) || {};
  //     const { metadata, ...rest } = parsedNumber;
  //     result = rest || result;
  //   }

  //   return result;
  // }

  static resolveCountryCallingCodeByCountryCode = (countryCode: CountryCode) => getCountryCallingCode(countryCode)

  static getRealTimeNumberFormatter = (countryCode: CountryCode) => new AsYouType(countryCode || null)

  static isValid = (number: any, countryCode: CountryCode = 'GB') => !!(parsePhoneNumberFromString(number, countryCode)?.isValid())

  static getNumber = (number: any, countryCode: CountryCode = 'GB') => parsePhoneNumberFromString(number, countryCode)
}
