import React, { forwardRef } from 'react';
import classnames from 'classnames';

import ScheduledCallInfiniteList from './Private/ScheduledCallInfiniteList';

import { ScheduledCallProps } from './ScheduledCall.types';
import ScheduledCallThemer from './ScheduledCall.theme';

const ScheduledCallComponent = forwardRef<HTMLDivElement, ScheduledCallProps<any>>((props, ref) => {
  const {
    id, className, style, listClassName,
    data, dataTotalCount, dataLoading,
    bottomHitThreshold, onNextPageRequested,
    getId, getPhotoUrl, getPhotoFallbackLabel,
    getName, getCompanyName, getCampaignColour,
    getCampaignName, getTime, getDate, onCall, onContactClick,onScheduleRowClick,
    menuStyle, menuListStyle, showTime,
    getMenuItems, showCallButton, placeholderCount,
    callButtonDisabled, isUnknownContact
  } = props;
  const classes = ScheduledCallThemer.useStyles();

  return (
    <div
      id={id}
      ref={ref}
      className={classnames(classes.container, className)}
      style={style}
    >
      <ScheduledCallInfiniteList
        id={`${id}-ScheduledCall`}
        className={listClassName}
        dataTotalCount={dataTotalCount}
        style={style}
        getId={getId}
        data={data}
        getPhotoUrl={getPhotoUrl}
        getPhotoFallbackLabel={getPhotoFallbackLabel}
        getName={getName}
        getCompanyName={getCompanyName}
        getCampaignColour={getCampaignColour}
        getCampaignName={getCampaignName}
        getTime={getTime}
        getDate={getDate}
        onCall={onCall}
        callButtonDisabled={callButtonDisabled}
        onContactClick={onContactClick}
        onScheduleRowClick={onScheduleRowClick}
        menuStyle={menuStyle}
        menuListStyle={menuListStyle}
        showTime={showTime}
        getMenuItems={getMenuItems}
        showCallButton={showCallButton}
        dataLoading={dataLoading}
        bottomHitThreshold={bottomHitThreshold}
        onNextPageRequested={onNextPageRequested}
        placeholderCount={placeholderCount}
        isUnknownContact={isUnknownContact}
      />
    </div>
  );
});

ScheduledCallComponent.defaultProps = {
  className: undefined,
  listClassName: undefined,
  style: undefined,
  bottomHitThreshold: 300,
  placeholderCount: 5,
  getDate: undefined,
  data: [],
};

export default ScheduledCallComponent;
