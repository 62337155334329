type HexColour = string;
type RGBColour = string;
type Colour = HexColour | RGBColour;

export interface PrimaryColourSet {
  normal: Colour;
  hovered: Colour;
  pressed: Colour;
  disabled: Colour;
  inverseHovered: Colour;
  inversePressed: Colour;
  hoverBackground: Colour;
};

export interface ColourPalettePrimaryA extends PrimaryColourSet {};
export interface ColourPalettePrimaryB extends PrimaryColourSet {};
export interface ColourPaletteDanger extends PrimaryColourSet {};

export interface SecondaryColourSet {
  normal: Colour;
  hovered: Colour;
  pressed: Colour;
  background: Colour;
};

export interface ColourPaletteSecondary {
  Red: SecondaryColourSet;
  Orange: SecondaryColourSet;
  Yellow: SecondaryColourSet;
  Green: SecondaryColourSet;
  Teal: SecondaryColourSet;
  Blue: SecondaryColourSet;
  DarkBlue: SecondaryColourSet;
  Indigo: SecondaryColourSet;
  Purple: SecondaryColourSet;
  DarkGrey: SecondaryColourSet;
  Navigation: SecondaryColourSet;
};

export interface ColourPaletteAuxiliary {
  navigation: Colour;
  drawer: Colour;
  shadow: Colour;
  snackbar: Colour;
  successForeground: Colour;
  successBackground: Colour;
  dangerForeground: Colour;
  dangerBackground: Colour;
};

export interface ColourPaletteDivider {
  dark: Colour;
  light: Colour;
  primary: Colour;
  secondary: Colour;
};

export interface ColourPaletteTypography {
  title: Colour;
  active: Colour;
  input: Colour;
  system: Colour;
  label: Colour;
  disabled: Colour;
  prompt: Colour;
  light: Colour;
  primary: Colour;
  secondary: Colour;
  danger: Colour;
  navigation: Colour;
  black: Colour;
};

export interface IconographyColourSet {
  normal: Colour;
  hovered: Colour;
  pressed: Colour;
};

export interface ColourPaletteIconography {
  Primary: IconographyColourSet;
  Secondary: IconographyColourSet;
  Dark: IconographyColourSet;
  Light: IconographyColourSet;
  Danger: IconographyColourSet;
  Close: IconographyColourSet;
  Default: IconographyColourSet;
};

export interface ColourPaletteBorders {
  light: Colour;
  dark: Colour;
};

export interface ColourPaletteBackgrounds {
  white: Colour;
  page: Colour;
  button: Colour;
  field: Colour;
  row: Colour;
  pageHeader: Colour;
  tooltip: Colour;

  lightMenuItem: Colour;
  lightMenuItemHovered: Colour;
  dangerMenuItem: Colour;
  dangerMenuItemHovered: Colour;

  primary: Colour;
  secondary: Colour;
  danger: Colour;
};

export interface ShadowColourSet {
  normal: Colour;
  hovered: Colour;
  pressed: Colour;
};

export interface ShadowColourPalette {
  Regular: ShadowColourSet;
  Inverse: ShadowColourSet;
};

export interface ColourPaletteSpecialCase {
  accordionAlt: Colour;
  interactionForegroundOffState: Colour;
  interactionBackgroundOffState: Colour;
  disabledInteractionForeground: Colour;
  disabledInteractionBackground: Colour;
};

export interface NotificationBadgePalette {
  default: Colour;
  primary: Colour;
  action: Colour;
  danger: Colour;
};

export interface CompleteColourPalette {
  PrimaryA: ColourPalettePrimaryA;
  PrimaryB: ColourPalettePrimaryB;
  Danger: ColourPaletteDanger;

  Secondary: ColourPaletteSecondary;
  Auxiliary: ColourPaletteAuxiliary;
  Divider: ColourPaletteDivider;

  Typography: ColourPaletteTypography;
  Iconography: ColourPaletteIconography;
  Borders: ColourPaletteBorders;
  Shadows: ShadowColourPalette;
  Backgrounds: ColourPaletteBackgrounds;
  SpecialCase: ColourPaletteSpecialCase;
  NotificationBadge: NotificationBadgePalette;
};

/**
 * Creates a primary palette object
 *
 * @param   {Colour}           normal         Normal colour
 * @param   {Colour}           hovered        Hovered colour
 * @param   {Colour}           pressed        Pressed colour
 * @param   {Colour}           disabled       Disabled colour
 * @param   {Colour}           inverseHovered Inverse hover colour
 * @param   {Colour}           inversePressed Inverse pressed colour
 *
 * @returns {PrimaryColourSet}                Primary palette object
 */
 const createPrimaryPalette = (
  normal: Colour,
  hovered: Colour,
  pressed: Colour,
  disabled: Colour,
  inverseHovered: Colour,
  inversePressed: Colour,
  hoverBackground: Colour,
): PrimaryColourSet => ({
  normal,
  hovered,
  pressed,
  disabled,
  inverseHovered,
  inversePressed,
  hoverBackground,
});

/**
 * Creates a secondary palette object
 *
 * @param   {Colour}              normal      Normal colour
 * @param   {Colour}              hovered     Hovered colour
 * @param   {Colour}              pressed     Pressed colour
 * @param   {Colour}              background  Background colour
 *
 * @returns {SecondaryColourSet}              Secondary palette object
 */
const createSecondaryPalette = (
  normal: Colour,
  hovered: Colour,
  pressed: Colour,
  background: Colour,
): SecondaryColourSet => ({
  normal,
  hovered,
  pressed,
  background,
});

/**
 * Creates a shadow palette object
 *
 * @param   {Colour}          normal      Normal colour
 * @param   {Colour}          hovered     Hovered colour
 * @param   {Colour}          pressed     Pressed colour
 *
 * @returns {ShadowColourSet}             Secondary palette object
 */
const createShadowColourSet = (normal: Colour, hovered: Colour, pressed: Colour): ShadowColourSet => ({ normal, hovered, pressed });

/**
 * Primary Colour Palette
 * @type {ColourPalettePrimaryA}
 */
const PrimaryA: ColourPalettePrimaryA = createPrimaryPalette(
  'rgba(21, 139, 234, 1)',
  'rgba(2, 87, 215, 1)',
  'rgba(0, 64, 159, 1)',
  'rgba(185, 220, 249, 1)',
  'rgba(233, 239, 255, 1)',
  'rgba(0, 64, 159, 0.2)',
  'rgba(21, 139, 234, 0.1)',
);

/**
 * Primary Alternate Colour Palette
 * @type {ColourPalettePrimaryB}
 */
const PrimaryB: ColourPalettePrimaryB = createPrimaryPalette(
  'rgba(11, 163, 96, 1)',
  'rgba(6, 132, 76, 1)',
  'rgba(0, 113, 71, 1)',
  'rgba(182, 227, 207, 1)',
  'rgba(230, 243, 237, 1)',
  'rgba(0, 113, 71, 0.2)',
  'rgba(11, 163, 96, 0.1)',
);

/**
 * Primary Danger Colour Palette
 * @type {ColourPaletteDanger}
 */
const Danger: ColourPaletteDanger = createPrimaryPalette(
  'rgba(222, 30, 23, 1)',
  'rgba(207, 16, 12, 1)',
  'rgba(180, 1, 1, 1)',
  'rgba(245, 187, 185, 1)',
  'rgba(250, 231, 231, 1)',
  'rgba(180, 1, 1, 0.2)',
  'rgba(222, 30, 23, 0.1)',
);

/**
 * Secondary colour palette
 * @type {ColourPaletteSecondary}
 */
const Secondary: ColourPaletteSecondary = {
  Red: createSecondaryPalette('#FF5B60', '#F81B22', '#D9191F', '#FFEEEF'),
  Orange: createSecondaryPalette('#FE9930', '#FF8200', '#EA7800', '#FEF4EA'),
  Yellow: createSecondaryPalette('#FEBE12', '#FFF2D0', '#FFE5A0', '#FEF8E7'),
  Green: createSecondaryPalette('#2DB783', '#D5F1E6', '#ABE2CD', '#EAF7F2'),
  Teal: createSecondaryPalette('#009FD9', '#CCECF7', '#99D9F0', '#E5F5FB'),
  Blue: createSecondaryPalette('#3C8BD4', '#D8E8F6', '#B1D1EE', '#EBF3FA'),
  DarkBlue: createSecondaryPalette('#08104D', '#CECFDB', '#9C9FB8', '#E6E7ED'),
  Indigo: createSecondaryPalette('#5869E2', '#DEE1F9', '#BCC3F3', '#EEF0FC'),
  Purple: createSecondaryPalette('#A97FF0', '#EEE5FC', '#DDCCF9', '#F6F2FD'),
  DarkGrey: createSecondaryPalette('#666666', '#E0E0E0', '#C2C2C2', '#EFEFEF'),
  Navigation: createSecondaryPalette('#263B51', '#002449', '#001932', '#263B51'),
};

/**
 * Auxiliary colours palette
 * @type {ColourPaletteAuxiliary}
 */
const Auxiliary: ColourPaletteAuxiliary = {
  navigation: '#001932',
  drawer: '#031C41',
  shadow: '#001932',
  snackbar: '#001932',
  successForeground: 'rgba(0, 113, 71, 1)',
  successBackground: 'rgba(230, 243, 237, 1)',
  dangerForeground: 'rgba(180, 1, 1, 1)',
  dangerBackground: 'rgba(250, 231, 231, 1)',
};

/**
 * Dicider colour spalette
 * @type {ColourPaletteDivider}
 */
const Divider: ColourPaletteDivider = {
  dark: 'rgba(0, 0, 0, 0.25)',
  light: 'rgba(255, 255, 255, 0.25)',
  primary: 'rgba(21, 139, 234, 0.25)',
  secondary: 'rgba(11, 163, 96, 0.25)',
};

/**
 * Typography colour palette
 * @type {ColourPaletteTypography}
 */
const Typography: ColourPaletteTypography = {
  title: '#222222',
  active: '#4C4C4C',
  input: '#4C4C4C',
  system: '#6B6B6B',
  label: '#6B6B6B',
  disabled: '#BBBBBB',
  prompt: '#BBBBBB',
  light: '#FFFFFF',
  primary: PrimaryA.normal,
  secondary: 'rgba(91, 206, 173, 1)',
  danger: Danger.normal,
  navigation: Auxiliary.navigation,
  black: '#000000',
};

/**
 * Creates a iconography colour set object
 *
 * @param   {Colour}              normal   Normal colour
 * @param   {Colour}              hovered  Hovered colour
 * @param   {Colour}              pressed  Pressed colour
 *
 * @returns {IconographyColourSet}         Primary palette object
 */
const createIconographyColourSet = (normal: Colour, hovered: Colour, pressed: Colour): IconographyColourSet => ({ normal, hovered, pressed });

/**
 * Colours used for Icons
 * @type {ColourPaletteIconography}
 */
const Iconography: ColourPaletteIconography = {
  Primary: createIconographyColourSet('rgba(21, 139, 234, 1)', 'rgba(21, 139, 234, 0.2)', 'rgba(21, 139, 234, 0.1)'),
  Secondary: createIconographyColourSet('rgba(11, 163, 96, 1)', 'rgba(11, 163, 96, 0.2)', 'rgba(11, 163, 96, 0.1)'),
  Danger: createIconographyColourSet('rgba(222, 30, 23, 1)', 'rgba(222, 30, 23, 0.2)', 'rgba(222, 30, 23, 0.1)'),
  Dark: createIconographyColourSet('rgba(34, 34, 34, 1)', 'rgba(34, 34, 34, 0.2)', 'rgba(34, 34, 34, 0.1)'),
  Light: createIconographyColourSet('rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 0.2)', 'rgba(255, 255, 255, 0.1)'),
  Close: createIconographyColourSet('rgba(187, 187, 187, 1)', 'rgba(2, 87, 215, 1)', 'rgba(0, 64, 159, 1)'),
  Default: createIconographyColourSet('rgba(187, 187, 187, 1)', 'rgba(187, 187, 187, 0.2)', 'rgba(187, 187, 187, 0.1)'),
};

/**
 * Borders colour palette
 * @type {ColourPaletteBorders}
 */
const Borders: ColourPaletteBorders = {
  light: '#D1D4D8',
  dark: '#C3C6CA',
};

/**
 * Backgrounds colour palette
 * @type {ColourPaletteBackgrounds}
 */
const Backgrounds: ColourPaletteBackgrounds = {
  white: '#FFFFFF',
  page: '#F9F9F9',
  button: '#F7F7F7',
  field: '#F7F7F7',
  row: '#F7F7F7',
  pageHeader: '#EFEFF1',
  tooltip: '#4C4C4C',

  lightMenuItem: '#FFFFFF',
  lightMenuItemHovered: 'rgba(21, 139, 234, 0.1)',
  dangerMenuItem: '#FFFFFF',
  dangerMenuItemHovered: Danger.inverseHovered,

  primary: 'rgba(185, 220, 249, 1)',
  secondary: 'rgba(239, 251, 247, 1)',
  danger: 'rgba(245, 187, 185, 1)',
};

/**
 * Shadow colour palette
 * @type {ShadowColourPalette}
 */
const Shadows: ShadowColourPalette = {
  Regular: createShadowColourSet('rgba(0, 22, 99, 0.12)', 'rgba(0, 22, 99, 0.24)', 'rgba(0, 22, 99, 0.24)'),
  Inverse: createShadowColourSet('rgba(229, 232, 235, 0.3)', 'rgba(229, 232, 235, 0.36)', 'rgba(229, 232, 235, 0.36)'),
};

/**
 * Special cases colour palette
 * @type {ColourPaletteSpecialCase}
 */
const SpecialCase: ColourPaletteSpecialCase = {
  accordionAlt: '#FDFDFD',
  interactionForegroundOffState: '#F7F7F7',
  interactionBackgroundOffState: '#EFEFF1',
  disabledInteractionForeground: '#FBFBFB',
  disabledInteractionBackground: '#F5F5F5',
};

/**
 * Notification badge colour palette
 * @type {NotificationBadgePalette}
 */
const NotificationBadge: NotificationBadgePalette = {
  default: 'rgba(232, 87, 105, 1.0)',
  primary: PrimaryA.normal,
  action: PrimaryB.normal,
  danger: 'rgba(232, 87, 104, 1.0)',
};

/**
 * All Colours
 * @type {CompleteColourPalette}
 */
const Colours: CompleteColourPalette = {
  PrimaryA,
  PrimaryB,
  Danger,

  Secondary,
  Auxiliary,
  Divider,

  // Grey Palette
  Typography,
  Iconography,
  Borders,
  Backgrounds,
  Shadows,
  SpecialCase,
  NotificationBadge,
};;

export default Colours;
