import { makeStyles } from '@material-ui/core/styles';

import FontFamily from '../../Theme/FontFamily';
import Colours from '../../Theme/Colours';
import { Variant } from './Typography.types';

const fontFamily = FontFamily;

const classes = {
  root: { fontFamily },
  h1: {
    fontFamily,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '36px',
  },
  h2: {
    fontFamily,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '27px',
  },
  h3: {
    fontFamily,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  h4: {
    fontFamily,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '21px',
  },
  h5: {
    fontFamily,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '18px',
  },
  h6: {
    fontFamily,
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '18px',
  },
  body1: {
    fontFamily,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    display: 'inline-block',
  },
  body2: {
    fontFamily,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '18px',
    display: 'inline-block',
  },
};
const useStyles = makeStyles({
  [Variant.title]: { color: Colours.Typography.title },
  [Variant.active]: { color: Colours.Typography.active },
  [Variant.input]: { color: Colours.Typography.input },
  [Variant.system]: { color: Colours.Typography.system },
  [Variant.label]: { color: Colours.Typography.label },
  [Variant.disabled]: { color: Colours.Typography.disabled },
  [Variant.prompt]: { color: Colours.Typography.prompt },
  [Variant.light]: { color: Colours.Typography.light },
  [Variant.navigation]: { color: Colours.Typography.navigation },
  [Variant.error]: { color: Colours.Danger.normal },
  [Variant.primary]: { color: Colours.Typography.primary },
  [Variant.secondary]: { color: Colours.PrimaryB.normal }
});

export default { classes, useStyles };
