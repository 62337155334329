import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import BeaconService from "../Services/Beacon/BeaconService";
import { RootState } from '../app/store';
import RealTimeClient from '../Services/RealTimeClient';

const initialState: any = { data: {} };

export const getBeacon: any = createAsyncThunk(
  "getBeacon/post",
  async (args: {statusId: string, tenantSettings: any}, { getState, dispatch }) => {
    const currentState = getState() as RootState;
    const {statusId, tenantSettings} = args;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const currentUserStatus = tenantSettings?.userStatuses?.filter((status: any) => status?.id === statusId);
    // const onCallStatus = tenantSettings?.userStatuses?.filter((status: any) => status?.name === DefaultUserStatuses.OnCall)?.[0];
    // const availableStatus = tenantSettings?.userStatuses?.filter((status: any) => status?.name === DefaultUserStatuses.Available)?.[0];
    // const isUserOncallStatus = statusId === onCallStatus?.id;
    let requestBody = {};

    const available = currentUserStatus?.isAvailable ?? true;
    requestBody = { available };
    
    const result = await BeaconService.getBeacon(tenantCode, AccessToken, requestBody);
    
    return result.data;
  }
);

const beaconSlice = createSlice({
  name: 'beacon',
  initialState,
  reducers: {},
  extraReducers: {
    [getBeacon.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.data = data;

      const { connectionUrl, accessToken } = data;
      RealTimeClient.connect(connectionUrl, accessToken);
    },
  }
});

const { reducer } = beaconSlice;
export default reducer;
