import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../Theme/Colours';
import FontFamily from '../../Theme/FontFamily';

import { AvatarGroupProps } from './AvatarGroup.types';

const classes = {
  avatar: { 
    width: 24,
    height: 24,
    fontSize: 12,
    fontWeight: 600,
    fontFamily: FontFamily,
    border: 'none',
  }
};
const useStyles = makeStyles({
  avatarGroup: {
    position: 'relative',
    width: 'fit-content',
  },
  additionalCountAvailable: {},
  additionalCountNotAvailable: {},

  overflowCountWrapper: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    display: (props: AvatarGroupProps) => props.overflowCountHidden ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colours.Secondary.Green.normal,
    zIndex: (props: AvatarGroupProps) => `${props.data.length + 1} !important` as any,
  },

  bottomIcon: {
    position: 'absolute',
    zIndex: '12 !important' as any,
    right: -4,
    top: 15,
    background: '#fff',
    boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.12)',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '14px !important',
    width: '14px !important',

    '& > svg' : {
      fontSize: 10,
    }
  },
 });

export default { classes, useStyles }; 