import React, { forwardRef } from 'react';
import classnames from 'classnames';

import Button, { ButtonVariant } from '../../../../Button';
import { Theme } from '../Buttons.types';

import { CallActionButtonProps } from './CallActionButton.types';
import CallActionButtonThemer from './CallActionButton.theme';

const CallActionButton = forwardRef<HTMLButtonElement, CallActionButtonProps>((props, ref) => {
  const { id, className, style, theme, variant, children, onClick, callbackValue, disabled, startIcon, endIcon, type } = props;
  const classes = CallActionButtonThemer.useStyles();
  const themeClassName = theme ? classes[theme] : undefined;

  return (
    <Button
      id={id}
      ref={ref}
      type={type}
      className={classnames(themeClassName, className, classes.common)}
      style={style}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      callbackValue={callbackValue}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </Button>
  );
});

CallActionButton.defaultProps = {
  className: undefined,
  style: undefined,
  disabled: false,
  theme: Theme.primary,
  variant: ButtonVariant.normal,
  onClick: () => null,
  callbackValue: undefined,
};

export default CallActionButton;
