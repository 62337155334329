import { makeStyles } from '@material-ui/core/styles';

import FontFamily from '../../../../Theme/FontFamily';
import Colours from '../../../../Theme/Colours';

const textFieldClasses = {
  input: {
    cursor: 'default',
    fontFamily: FontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '27px',
    color: Colours.Typography.light,
    textAlign: 'center' as 'center',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
};

const useStyles = makeStyles({
  card: {
    minWidth: 264,
    minHeight: 60,
    maxWidth: 264,
    maxHeight: 72,
    textAlign: 'center',
    boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.12)',
    borderRadius: '24px 24px 0px 0px',
    backgroundColor: '#001326',
    display: 'flex',
    alignItems: 'center',
  },
  backspaceIcon: {
    marginRight: 12,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconElement: {
    '&:hover': {
      color: '#DADADA',
    },
    '&:active': {
      color: '#DADADA',
    },
    '&:focus': {
      color: '#DADADA',
    },
  },

  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 25,

    '& > div': {
      height: '100%',
      color: '#FFFFFF',
    },
  },
  contactName: {
    fontFamily: FontFamily,
    fontWeight: 600,
    fontSize: 12,
  },
  contactNameWithMargin: {
    marginRight: 30,
  },
});

export default { textFieldClasses, useStyles };
