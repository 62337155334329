import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  historyContainer: {
    minWidth: '300px !important',
    maxWidth: 300,
    '& > div:nth-child(1)': {
      height: '100%',
      width: 386,
      marginRight: '2px !important'
    },
  },
  summaryScheduledBody: {
    padding: 0,
  },
  historyFooter: {
    justifyContent: 'center',
  },
  historyButton: {
    color: '#0339C4',
    fontSize: 14,
  },
});

export default { useStyles };
