export default class ContactService {
  static isUnknownContact(contact: any) {
    if (contact?.contactType !== 'Lead') {
      const accountId = contact?.accountId || contact?.account?.id || contact?.account?.remoteId || null;
      if (!accountId) {
        return true;
      }
    }

    return false;
  }

  static isNewContact(contact: any) {
    if (contact?.contactType === 'Unknown') {
      return true;
    }

    return false;
  }

  static getContactFullName(contact: any) {
    if (ContactService.isUnknownContact(contact)) {
      return `${contact?.firstName || ''} ${contact?.lastName !== 'Unknown' ? (contact?.lastName || '') : ''}`.trim();
    }

    return `${contact?.title || ''} ${contact?.firstName || ''} ${contact?.lastName || ''}`.trim();
  }
}
