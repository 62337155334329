import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  summaryCard: {
    minHeight: 150,
    width: 386,
    boxShadow: '0px 2px 6px 1px rgba(0, 22, 99, 0.12)',
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
  },
  summaryHeader: {
    minHeight: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FCFBFA',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingLeft: 18,
    paddingTop: 10,
    paddingBottom: 10,
  },
  summaryBody: {
    padding: 0,
    height: 170,
    minHeight: 50,
  },
  summaryFooter: {
    minHeight: 1,
    height: 1,
    display: 'flex',
    alignItems: 'center',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    padding: 10,
  }
});

export default { useStyles };
