import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../Theme/Colours';
import { Theme, Size } from './IconButton.types';

const classes = {};
const useStyles = makeStyles({
  [Theme.default]: {
    color: Colours.Iconography.Default.normal,
    '&:hover': {
      color: Colours.Iconography.Default.normal,
      backgroundColor: Colours.Iconography.Default.hovered,
    },
    '&:active': {
      color: Colours.Iconography.Default.normal,
      backgroundColor: Colours.Iconography.Default.pressed,
    },
    '&:focus': {
      color: Colours.Iconography.Default.normal,
      backgroundColor: Colours.Iconography.Default.pressed,
    },
  },
  [Theme.dark]: {
    color: Colours.Iconography.Dark.normal,
    '&:hover': {
      color: Colours.Iconography.Dark.normal,
      backgroundColor: Colours.Iconography.Dark.hovered,
    },
    '&:active': {
      color: Colours.Iconography.Dark.normal,
      backgroundColor: Colours.Iconography.Dark.pressed,
    },
    '&:focus': {
      color: Colours.Iconography.Dark.normal,
      backgroundColor: Colours.Iconography.Dark.pressed,
    },
  },
  [Theme.light]: {
    color: Colours.Iconography.Light.normal,
    '&:hover': {
      color: Colours.Iconography.Light.normal,
      backgroundColor: Colours.Iconography.Light.hovered,
    },
    '&:active': {
      color: Colours.Iconography.Light.normal,
      backgroundColor: Colours.Iconography.Light.pressed,
    },
    '&:focus': {
      color: Colours.Iconography.Light.normal,
      backgroundColor: Colours.Iconography.Light.pressed,
    },
  },
  [Theme.primary]: {
    color: Colours.Iconography.Primary.normal,
    '&:hover': {
      color: Colours.Iconography.Primary.normal,
      backgroundColor: Colours.Iconography.Primary.hovered,
    },
    '&:active': {
      color: Colours.Iconography.Primary.normal,
      backgroundColor: Colours.Iconography.Primary.pressed,
    },
    '&:focus': {
      color: Colours.Iconography.Primary.normal,
      backgroundColor: Colours.Iconography.Primary.pressed,
    },
  },
  [Theme.primarySolid]: {
    color: Colours.Iconography.Light.normal,
    backgroundColor: Colours.PrimaryA.normal,
    '&:hover': { backgroundColor: Colours.PrimaryA.hovered },
    '&:active': { backgroundColor: Colours.PrimaryA.pressed },
    '&:focus': { backgroundColor: Colours.PrimaryA.pressed },
  },
  [Theme.secondary]: {
    color: Colours.Iconography.Secondary.normal,
    '&:hover': {
      color: Colours.Iconography.Secondary.normal,
      backgroundColor: Colours.Iconography.Secondary.hovered,
    },
    '&:active': {
      color: Colours.Iconography.Secondary.normal,
      backgroundColor: Colours.Iconography.Secondary.pressed,
    },
    '&:focus': {
      color: Colours.Iconography.Secondary.normal,
      backgroundColor: Colours.Iconography.Secondary.pressed,
    },
  },
  [Theme.secondarySolid]: {
    color: Colours.Iconography.Light.normal,
    backgroundColor: Colours.PrimaryB.normal,
    '&:hover': { backgroundColor: Colours.PrimaryB.hovered },
    '&:active': { backgroundColor: Colours.PrimaryB.pressed },
    '&:focus': { backgroundColor: Colours.PrimaryB.pressed },
  },
  [Theme.danger]: {
    color: Colours.Iconography.Danger.normal,
    '&:hover': {
      color: Colours.Iconography.Danger.normal,
      backgroundColor: Colours.Iconography.Danger.hovered,
    },
    '&:active': {
      color: Colours.Iconography.Danger.normal,
      backgroundColor: Colours.Iconography.Danger.pressed,
    },
    '&:focus': {
      color: Colours.Iconography.Danger.normal,
      backgroundColor: Colours.Iconography.Danger.pressed,
    },
  },
  [Theme.dangerSolid]: {
    color: Colours.Iconography.Light.normal,
    backgroundColor: Colours.Danger.normal,
    '&:hover': { backgroundColor: Colours.Danger.hovered },
    '&:active': { backgroundColor: Colours.Danger.pressed },
    '&:focus': { backgroundColor: Colours.Danger.pressed },
  },
  [Theme.close]: { '&:hover': { backgroundColor: 'unset' } },
  [Theme.navigation]: {
    color: Colours.Auxiliary.navigation,
    '&:hover': {
      color: '#33475B',
      backgroundColor: Colours.Iconography.Default.hovered,
    },
    '&:active': {
      color: '#4C5E6F',
      backgroundColor: Colours.Iconography.Default.pressed,
    },
    '&:focus': {
      color: '#4C5E6F',
      backgroundColor: Colours.Iconography.Default.pressed,
    },
  },
  [Theme.navigationSolid]: {
    color: Colours.Iconography.Light.normal,
    backgroundColor: Colours.Auxiliary.navigation,
    '&:hover': { backgroundColor: '#33475B' },
    '&:active': { backgroundColor: '#4C5E6F' },
    '&:focus': { backgroundColor: '#4C5E6F' },
  },

  [Size.small]: { width: 24, height: 24 },
  [Size.medium]: { width: 36, height: 36 },
  [Size.large]: { width: 42, height: 42 },
  [Size.extraLarge]: { width: 48, height: 48 },
});

export default { classes, useStyles };
