import { forwardRef, useCallback, useState, useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faRocket } from '@fortawesome/free-solid-svg-icons';
import { faFlag, faHistory } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '../Tooltip';
import Moment from 'moment';
import CallOutcomeCard from '../CallOutcomeCard';
import Button, { ButtonVariant } from '../Button';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { DispositionTypeEnum } from '../../constants/DispositionTypeEnum';
import IconTitle from '../IconTitle';
import Typography, { TypographyVariant } from '../Typography';
import { getCallOutcomeRecord, resetCallOutcomeRecord } from '../../slices/callCentre';
import Avatar from '../Avatar';
import { CallMedium } from '../../Enums/ActivityEnum';
import CallStatusBadge, { CallStatusBadgeStatusType } from '../CallStatusBadge';
import { CallActivityDirectionEnum } from '../../Enums/CallActivityActionType';
import { AvatarService } from '../../Services/Avatar';

import CallOutcomeThemer from './CallOutcome.theme';
import { CallOutcomeProps } from './CallOutcome.types';
import { LegendToggle } from '@mui/icons-material';

const warningText = 'You must complete Call Outcome Card in order to Submit';

const CallOutcomeComponent = forwardRef<HTMLDivElement, CallOutcomeProps>((props, ref) => {
  const { callRecord, onCallOutcomeSubmit } = props;
  const [callOutcomeSelected, setCallOutcomeSelected] = useState(false);
  const [callOutcome, setCallOutcome] = useState('');  
  const classes = CallOutcomeThemer.useStyles();
  const callStatusBadgeClasses = CallOutcomeThemer.useCallStatusBadgeStyles();
  const dispatch = useAppDispatch();
  const dispositions = useAppSelector((state :any) => state.settings.callDispositions);
  const callOutcomeRecordData = useAppSelector((state: any) => state.callCentre.callOutcomeRecord?.data);

  useEffect(() => {
    dispatch(getCallOutcomeRecord(callRecord?.callRecordId));
    return () => {
      dispatch(resetCallOutcomeRecord());
    }
  }, [callRecord, dispatch])

  const handleCallOutcomeSelected = useCallback((value: string) => {
    setCallOutcome(value);
    setCallOutcomeSelected(true);
  }, []);

  const resolveDividerIndex = useCallback(() => {
    const successTypeCount = (dispositions?.filter((disposition: any) => disposition?.type === DispositionTypeEnum.success)).length || 1;

    return successTypeCount - 1;
  }, [dispositions]);

  let callRecordDetail = {...callRecord, ...(callOutcomeRecordData || {})}
  if (callRecord?.contactDetails) {
    callRecordDetail.contactDetails = callRecord?.contactDetails;
  }

  const callIsInbound = callRecordDetail?.content?.callDirection === CallActivityDirectionEnum.incoming;
  const callStatusType = callIsInbound ? CallStatusBadgeStatusType.inboundReceived : CallStatusBadgeStatusType.outboundReceived;
  const isSalesforceCall = callRecordDetail?.content?.callMedium === CallMedium.salesForce;
  const isRocketCellCall = callRecordDetail?.content?.callMedium === CallMedium.rocketCell;

  const avatarStyle = {
    backgroundColor: AvatarService.getBackgroundColour(callRecordDetail?.contactDetails?.photoUrl, null),
  };
  const fallbackTextStyle = {
    color: AvatarService.getTextColour(callRecordDetail?.contactDetails?.photoUrl, null),
  };

  return (
    <div id={callRecordDetail?.callRecordId} className={classes.callOutcomeWrapper}>
      <div className={classes.callOutcome}>
        <div id='CallOutcomeRecord-Card' className={classes.callOutcomeRecordCard}>
          <div className={classes.callOutcomeRecordCardHeader}>
            <IconTitle
              icon={<FontAwesomeIcon icon={faHistory as IconProp} color={'white'}/>}
              iconBackgroundColor="rgb(250, 154, 113)"
            >
              <Typography.H3 style={{ color: '#000000', paddingLeft: 10 }} variant={TypographyVariant.active}>
                Call Record
              </Typography.H3>
            </IconTitle>
          </div>
          <div className={classes.callOutcomeRecordCardBody}>
            {callRecordDetail.loading && (
              <>
                <Skeleton variant="circular" width={40} height={40} style={{ marginRight: '6px' }}/>
                <Skeleton variant="rectangular" width={80} height={12} />
              </>
            )}
            {(!callRecordDetail.loading && callRecordDetail.error?.message === "Network Error") && (
              <div className={classes.callOutcomeRecordContact}>
                <Typography.H4 style={{ textAlign: 'left' }} variant={TypographyVariant.error}>No Internet Connection</Typography.H4>
              </div>
            )}
            {(!callRecordDetail.loading && !callRecordDetail.error) && (
              <>
                <div className={classes.callOutcomeRecordLeftWrapper}>
                  <div className={classes.callOutcomeRecordStatusBadge}>
                    <CallStatusBadge
                      id={`${callRecordDetail?.callRecordId}-StatusBadge`}
                      classes={callStatusBadgeClasses}
                      statusType={callStatusType}
                      callStatusTooltip={callRecordDetail?.customerPhone}
                    >
                      <Avatar
                          id={`${callRecordDetail?.callRecordId}-Avatar`}
                          style={avatarStyle}
                          fallbackTextStyle={fallbackTextStyle}
                          className={classnames(classes.avatar)}
                          url={callRecordDetail?.contactDetails?.photoUrl}
                          fallbackLabel={`${callRecordDetail?.contactDetails?.firstName || 'Unknown Caller'} ${callRecordDetail?.contactDetails?.lastName || ''}`}
                          alt="Contact Avatar"
                        />
                      {/* {isSalesforceCall && (
                        <Avatar
                          id={`${callRecordDetail?.callRecordId}-Avatar`}
                          style={avatarStyle}
                          fallbackTextStyle={fallbackTextStyle}
                          className={classnames(classes.avatar)}
                          url={callRecordDetail?.contactDetails?.photoUrl}
                          fallbackLabel={`${callRecordDetail?.contactDetails?.firstName || ''} ${callRecordDetail?.contactDetails?.lastName || ''}`}
                          alt="Contact Avatar"
                        />
                      )}
                      {!isSalesforceCall && (
                        <Tooltip
                          id={`${callRecordDetail?.callRecordId}-CallStatus-Tooltip-Wrapper`}
                          content={
                            <Typography.P2 variant={TypographyVariant.light}>
                              {`${callRecordDetail?.contactDetails?.firstName || ''} ${callRecordDetail?.contactDetails?.lastName || ''}`}
                            </Typography.P2>
                          }
                          placement="bottom"
                          arrow
                        >
                          <div className={classes.callMediumIcon}>
                            <FontAwesomeIcon icon={isRocketCellCall ? faPhone : faRocket} />
                          </div>
                        </Tooltip>
                      )} */}
                    </CallStatusBadge>
                  </div>
                  <div className={classes.callOutcomeRecordContact}>
                    <Typography.H4 style={{ textAlign: 'left' }}>{`${callRecordDetail?.contactDetails?.firstName || 'Unknown Caller'} ${callRecordDetail?.contactDetails?.lastName || ''}`}</Typography.H4>
                    <Typography.P2 style={{ textAlign: 'left' }}>{callRecordDetail?.contactDetails?.account?.name || ''}</Typography.P2>
                  </div>
                </div>
                <div>
                  <div className={classnames(classes.columnTimestamp)}>
                    <Typography.P2
                      id={`${callRecordDetail?.callRecordId}-Timestamp-Date`}
                      className={classnames(classes.timestampText)}
                      variant={TypographyVariant.active}
                      noWrap
                    >
                      {callRecordDetail?.createdDate ? Moment.utc(callRecordDetail?.createdDate).local().format('DD/MM/YY') : '-'}
                    </Typography.P2>
                    <Typography.P2
                      id={`${callRecordDetail?.callRecordId}-Timestamp-Time`}
                      className={classnames(classes.timestampText)}
                      variant={TypographyVariant.active}
                      noWrap
                    >
                      {callRecordDetail?.createdDate ? Moment.utc(callRecordDetail?.createdDate).local().format('HH:mm') : '-'}
                    </Typography.P2>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* Commenting CallAiSummaryCard for demo purpose. Bring it back after the demo */}
        {/* <CallAiSummaryCard /> */}
        <CallOutcomeCard
          id={`${callRecordDetail?.callRecordId}-Card`}
          className={classes.callOutcomeCard}
          icon={<FontAwesomeIcon icon={faFlag as IconProp} color="#ffffff" />}
          cardTitle="Call Outcome*"
          showIcon
          selectionItems={dispositions || []}
          getItemLabel={item => item.description}
          getItemValue={item => item.description}
          onItemSelect={handleCallOutcomeSelected}
          warningText={!callOutcomeSelected ? warningText : ''}
          showDivider
          dividerIndex={resolveDividerIndex()}
        />
      </div>
      <Button
        id='Actions-Primary-Button'
        variant={ButtonVariant.normal}
        fullWidth
        className={classes.actionButton}
        onClick={() => onCallOutcomeSubmit(callOutcome)}
        disabled={!callOutcomeSelected}
      >
        Submit
      </Button>
    </div>
  );
});

CallOutcomeComponent.defaultProps = {};

export default CallOutcomeComponent;
