export const BookmarkStatusEnum = Object.freeze({
  Rejected: 'Rejected',
  Approved: 'Approved',
  // Bookmarks: 'Bookmarks',
  Pending: 'Pending',
  // Automated: 'Automated',
  // Manual: 'Manual',
  Verified: 'Verified',
  // Bookmark: 'Bookmark',
  Added: 'Added',
});

export const BookmarkTypeEnum = Object.freeze({
  Manual: 'Manual',
  Automated: 'Automated',
  AutomatedPending: 'Automated-Pending',
});
