const SecuritySettingsOptions = Object.freeze({
  team: 'Share with Team (No Password Required)',
  public: 'Share Externally (Password Required)',
});

const steps = Object.freeze({
  optionSelect: 'optionSelect',
  recepientSelect: 'recepientSelect',
  securityOptions: 'securityOptions',
});

export default { SecuritySettingsOptions, steps };
