import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../../Theme/Colours';

import { ScheduledCallCardProps } from './ScheduledCallCard.types';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: 360,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 9,
    paddingBottom: 9,
    paddingRight: 18,
    paddingLeft: 18,
    '&:nth-child(even)': {
      backgroundColor: 'rgba(240, 248, 255, 1)',
    },
    '&:hover': {
      cursor: 'pointer',
      '& $scheduledTime': {
        display: 'none',
      },
      '& $callButton': {
        display: 'flex',
      },
    },
  },
  scheduledTime: {
    display: 'flex',
    flexDirection: 'row',
    height: 32,
    alignItems: 'center',
  },
  callButton: {
    display: 'none',
  },
  contact: {
    display: 'flex',
    marginLeft: 12,
  },
  contactAvtar: {
    display: 'flex',
    alignItems: 'center',
    color: 'white !important',
  },
  campaignIcon: {
    fontSize: 12,
  },
  unknownContactIcon: {
    fontSize: 32,
  },
  contactNameText: {
    maxWidth: 140,
    display: 'flex',
    padding: '0px !important',
    minHeight: '20px !important',
    height: '0px !important',
    border: 0,
    '&:hover': {
      border: `0px !important`,
    },
    '& > span': {
      fontSize: 14,
      display: 'flex',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      justifyContent: 'flex-start',
    },
  },
  contactCompanyText: {
    maxWidth: 140,
    color: '#001932',
    display: 'flex',
  },
  campaignContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    marginLeft: 6,
    borderRadius: '50%',
    backgroundColor: (props: ScheduledCallCardProps<any>) => `${props.getCampaignColour(props.data)}33` ?? Colours.PrimaryB.hoverBackground,
    backgroundOpacity: '50%',
  },
  toolTipContainer: {
    height: 24,
    '& > span:nth-child(1)': {
      height: 24,
      display: 'flex',
    },
  },
  timeText: {
    color: '#4C4C4C',
  },
  avatar: {
    height: 32,
    width: 32,
    color: 'white !important',
  },
});

export default { useStyles };
