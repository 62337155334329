export enum ActivityType {
  call = 'Call',
  schedule = 'Schedule',
  conferenceCall = 'ConferenceCall',
  sms = 'SMS',
  email = 'Email',
  note = 'Note',
}

export enum CallMedium {
  web = 'RocketPhone',
  zoom = 'Zoom',
  teams = 'Teams',
  whatsapp = 'Whatsapp',
  salesForce = 'RocketPhoneSF',
  rocketCell = 'RocketCel',
}

export enum ActivityStatusType {
  inboundMissed = 'inbound-missed',
  inboundReceived = 'inbound-received',
  outboundMissed = 'outbound-missed',
  outboundReceived = 'outbound-received',
  email = 'email',
  sms = 'sms',
  note = 'note',
  voicemail = 'voicemail',
  upcoming = 'upcoming',
  zoom = 'zoom',
  teams = 'teams',
  whatsapp = 'whatsapp',
  SalesForce = 'SalesForce',
}

export enum ActivityCallDirection {
  outbound = 'outbound',
  inbound = 'inbound',
}
