import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  home: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    paddingLeft: '14px',
    paddingRight: '14px',
    backgroundColor: '#001932'
  }
});

export default { useStyles };