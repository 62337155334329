import { makeStyles } from '@material-ui/core/styles';

import { SummaryCardEmptyStateProps } from './SummaryCardEmptyState.types';

const useStyles = makeStyles({
  title: {
    color: '#222222',
    textAlign: 'start',
  },
  summaryEmptyBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: 20,
  },
  emptyStateImageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
  },
  emptyStateImage: {
    width: 80,
  },
});

export default { useStyles };
