import React, { forwardRef } from 'react';
import classnames from 'classnames';

import { SummaryCardRootProps } from './SummaryCardRoot.types';
import SummaryCardRootThemer from './SummaryCardRoot.theme';
import Typography, { TypographyVariant } from '../../Typography';

const SummaryCardRootComponent = forwardRef<HTMLDivElement, SummaryCardRootProps>((props, ref) => {
  const {
    id, className, style, 
    header, duration, children, footer,
    headerClassName, bodyClassName, footerClassName,
  } = props;
  const classes = SummaryCardRootThemer.useStyles(props);

  return (
    <div
      ref={ref}
      className={classnames(className, classes.summaryCard)}
      style={style}
      id={id}
    >
      <div className={classnames(headerClassName, classes.summaryHeader)}>
        {header}
        {duration && (
          <div>
            <Typography.H3 variant={TypographyVariant.active}>{duration}</Typography.H3>
          </div>
        )}
      </div>
      <div className={classnames(bodyClassName, classes.summaryBody)}>
       {children}
      </div>
      {
        footer && 
        <div className={classnames(footerClassName, classes.summaryFooter)}>
          {footer}
        </div>
      }
    </div>
  );
});

SummaryCardRootComponent.defaultProps = {
  className: undefined,
  headerClassName: undefined,
  bodyClassName: undefined,
  footerClassName: undefined,
  style: undefined,
  header: undefined,
  duration: undefined,
  children: undefined,
  footer: undefined,
};

export default SummaryCardRootComponent;
