import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from '../app/store';
import CallCentre from '../Services/CallCentre/CallCentre';
import SaleforceService from "../Services/SaleforceService";

const initialState: any = {
  callBarState: {}, 
  inactiveCallRecordId: {}, 
  connectedUserInitialDetails: {},
  incomingCallInfo: { callIncoming: false, contacts: [] }, 
  currentActiveCallRecordId: null,
  callDetailsMap: {}, 
  callRecordingStatusMap: {}, 
  callRecordingInitiatedMap: {}, 
  callRecordingConsentMap: {},
  callOutcomeRecord: { data: {}, loading: true },
  callTimer: 0,
  isCurrentCallInHold: false,
  callIsIncoming: false,
};

export const getCallOutcomeRecord: any = createAsyncThunk(
  "getCallOutcomeRecord/get",
  async (recordId: string, { getState }) => {
    const result = await SaleforceService.getCallRecordDetailsByCallRecordId(recordId);

    if(result && typeof result === 'object' && Object.keys(result).length > 0) {
      return result;
    } else {
      const currentState = getState() as RootState;
      const { tenantCode, AccessToken } = currentState.auth.authUser;
      const result = await CallCentre.getCallRecord(tenantCode, AccessToken, recordId);
      
      return  result?.data?.data?.[0]?.callRecord || {};
    }
  }
);

const callCentreSlice = createSlice({
  name: 'callCentre',
  initialState,
  reducers: {
    updateCallBarState(state, action: PayloadAction<any>) {
      state.callBarState = action.payload;
    },
    updateCurrentInactiveCallRecordId(state, action: PayloadAction<any>) {
      state.inactiveCallRecordId = action.payload;
    },
    updateConnectedUserInitialDetails(state, action: PayloadAction<any>) {
      const { callRecordId, params } = action.payload;
      const userDetails = { ...params, firstName: params?.ContactFirstName, lastName: params?.ContactLastName }

      if (!params) return state;

      state.connectedUserInitialDetails[callRecordId] = { userDetails };
    },
    setIncomingCallTriggered(state, action: PayloadAction<any>) {
      const { incomingCallType, callDetails, contacts } = action.payload;
      state.incomingCallInfo = {
        callIncoming: true,
        callType: incomingCallType,
        callDetails,
        contacts
      }
    },
    setIncomingCallIdle(state) {
      state.incomingCallInfo = {
        callIncoming: false,
        callType: null,
        callDetails: null,
        contacts: [],
      }
    },
    getCallIncoming(state) {
        state.callIsIncoming = (state.incomingCallInfo.callIncoming) ? true : false;
    },
    updateCurrentActiveCallRecordId(state, action: PayloadAction<any>) {
      state.currentActiveCallRecordId = action.payload;
    },
    setCallPrimaryMetadata(state, action: PayloadAction<any>) {
      const {
        previousCallRecordId, callRecordId,
        contactNumber,
        customerHasJoinedConference, isHotTransfer, isConference, metadata,
      } = action.payload;

      const mapForCallRecordId = {
        previousCallRecordId, 
        callRecordId,
        customerHasJoinedConference, 
        isHotTransfer, 
        isConference,
        contactNumber, 
        metadata
      };

      state.callDetailsMap = {
        ...state.callDetailsMap,
        [callRecordId]: mapForCallRecordId
      };
    },
    setCallRecordingStatusData(state, action: PayloadAction<any>) {
      const { callRecordId, status } = action.payload;
      const proccessedCallRecordingStatusMap = { ...state.callRecordingStatusMap, [callRecordId]: status };
      const proccessedCallRecordingInitiatedMap = {
        ...state.callRecordingInitiatedMap,
        [callRecordId]: status || state.callRecordingInitiatedMap[callRecordId],
      };
    
      state.callRecordingStatusMap = proccessedCallRecordingStatusMap;
      state.callRecordingInitiatedMap = proccessedCallRecordingInitiatedMap;
    },
    setCallRecordingConsentData(state, action: PayloadAction<any>) {
      const { callRecordId, consent } = action.payload;
      const proccessedCallRecordingConsentMap = {
        ...state.callRecordingConsentMap,
        [callRecordId]: consent,
      };

      state.callRecordingConsentMap = proccessedCallRecordingConsentMap;
    },
    resetCallOutcomeRecord(state) {
      state.callOutcomeRecord.data = {};
      state.callOutcomeRecord.loading = true;
    },
    setCallTimer(state, action: PayloadAction<any>) {
      const { callDuration } = action.payload;
      state.callTimer = callDuration;
    },
    setIsCurrentCallInHold(state, action: PayloadAction<any>) {
      state.isCurrentCallInHold = action.payload;
    },
  },
  extraReducers: {
    [getCallOutcomeRecord.fulfilled]: (state, action) => {
      state.callOutcomeRecord.data = action.payload;
      state.callOutcomeRecord.loading = false;
    },
    [getCallOutcomeRecord.rejected]: (state, action) => {
      state.callOutcomeRecord.error = action?.error;
      state.callOutcomeRecord.loading = false;
    },
  }
});

export const {
  updateCallBarState, 
  updateCurrentInactiveCallRecordId,
  updateConnectedUserInitialDetails, 
  setIncomingCallTriggered,
  setIncomingCallIdle, 
  updateCurrentActiveCallRecordId,
  setCallPrimaryMetadata, 
  setCallRecordingStatusData, 
  setCallRecordingConsentData,
  resetCallOutcomeRecord,
  setCallTimer,
  setIsCurrentCallInHold,
  getCallIncoming,
} = callCentreSlice.actions;

export default callCentreSlice.reducer;
