import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { connection: navigator.onLine };

const networkConnectionSlice = createSlice({
  name: 'networkConnection',
  initialState,
  reducers: {
    updateConnectionStatus(state, action: PayloadAction<any>) {
      state.connection = action.payload.connectionAvailable;
    },
  },
});

export const { updateConnectionStatus } = networkConnectionSlice.actions;
export default networkConnectionSlice.reducer;
