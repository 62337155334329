import React, { forwardRef, useMemo } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { PopoverOrigin } from '@material-ui/core/Popover';
import classnames from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';

import MenuItem from '../MenuItem';
import Typography, { TypographyVariant } from '../Typography';

import { SelectProps, Variant, Theme, SelectMenuItemThemeMap } from './Select.types';
import SelectThemer from './Select.theme';

const ThemedFormControl = withStyles(SelectThemer.formControlClasses as any)(FormControl);
const ThemedInputLabel = withStyles(SelectThemer.inputLabelClasses as any)(InputLabel);
const ThemedSelect = withStyles(SelectThemer.classes as any)(Select);

const DefaultAnchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'left' };
const DefaultTransformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' };
const DefaultMenuProps = { anchorOrigin: DefaultAnchorOrigin, transformOrigin: DefaultTransformOrigin, getContentAnchorEl: null };

const SelectComponent = forwardRef<HTMLDivElement, SelectProps<any>>((props, ref) => {
  const {
    id,
    name,
    className,
    style,
    menuItemClassName,
    menuItemStyle,
    formControlClassName,
    value,
    startAdornmentNode,
    renderValue,
    defaultValue,
    inputElement,
    menuItems=[],
    renderMenuItem,
    getMenuItemValue,
    getMenuItemLabel,
    isMenuItemSelected,
    isMenuItemDisabled,
    onChange,
    onClose,
    onOpen,
    variant,
    theme,
    open,
    autoWidth,
    label,
    labelWidth,
    menuProps,
    multiple,
    iconComponent,
    formControlTheme,
    disabled,
    formControlError,
    focused,
    fullWidth,
    hiddenFormLabel,
    margin,
    required,
    formControlSize,
    formControlVariant,
    showLabel,
    inputLabel,
    labelShrink,
    showHelperText,
    formHelperText,
  } = props;
  const classes = SelectThemer.useStyles();

  const resolvedMenuProps = useMemo(() => ({ ...DefaultMenuProps, ...(menuProps ?? {}) }), [menuProps]);
  const themeClassNames = classnames(classes.default, { [classes.darkTheme]: theme === Theme.dark }, className);
  let menuItemRenderer: (menuItem: any, index: number) => any = () => null;
  if (renderMenuItem) menuItemRenderer = renderMenuItem;
  else if (getMenuItemValue && getMenuItemLabel) menuItemRenderer = (menuItem: any, index: number) => (
    <MenuItem
      id={`${id}-Menu-Item-${index + 1}`}
      key={`Menu-Item-${index + 1}`}
      className={menuItemClassName}
      style={menuItemStyle}
      theme={SelectMenuItemThemeMap[theme ?? Theme.light]}
      value={getMenuItemValue(menuItem)}
      selected={!!isMenuItemSelected?.(menuItem)}
      disabled={!!isMenuItemDisabled?.(menuItem)}
    >
      {getMenuItemLabel(menuItem)}
    </MenuItem>
  );

  const adornmentNode = (
      <InputAdornment position="start">
        {startAdornmentNode}
      </InputAdornment>
  );

  return (
    <ThemedFormControl
      id={`${id}-Form-Control`}
      ref={ref}
      className={formControlClassName}
      color={formControlTheme}
      disabled={disabled}
      error={formControlError}
      focused={focused}
      fullWidth={fullWidth}
      hiddenLabel={hiddenFormLabel}
      margin={margin}
      required={required}
      size={formControlSize}
      variant={formControlVariant}
    >
      {showLabel && (
        <ThemedInputLabel id={`${id}-Input-Label`} shrink={labelShrink}>
          {inputLabel}
        </ThemedInputLabel>
      )}
      <ThemedSelect
        id={id}
        name={name}
        className={themeClassNames}
        style={style}
        input={inputElement as any}
        labelId={`${id}-Input-Label`}
        value={value}
        defaultValue={defaultValue}
        renderValue={renderValue}
        open={open}
        onChange={onChange}
        onClose={onClose}
        onOpen={onOpen}
        autoWidth={autoWidth}
        IconComponent={iconComponent}
        label={label}
        labelWidth={labelWidth}
        MenuProps={resolvedMenuProps}
        multiple={multiple}
        variant={variant}
        startAdornment={startAdornmentNode ? adornmentNode : null}
      >
        {menuItems?.length > 0 ? menuItems?.map(menuItemRenderer) : <Typography.P2 variant={TypographyVariant.active} className={classes.emptyItemText}>No data available</Typography.P2>}
      </ThemedSelect>
      {showHelperText && <FormHelperText id={`${id}-Form-Helper-Text`}>{formHelperText}</FormHelperText>}
    </ThemedFormControl>
  );
});

SelectComponent.defaultProps = {
  name: undefined,
  className: undefined,
  style: undefined,
  onChange: () => {},
  onClose: () => {},
  onOpen: () => {},
  value: '',
  defaultValue: '',
  variant: Variant.standard,
  theme: Theme.light,
  showLabel: false,
  showHelperText: false,
  formHelperText: 'Helper Text',
  inputLabel: 'Input Label',
  menuItems: [],
  menuProps: undefined,
  startAdornmentNode: undefined,
};

export {
  Theme as SelectTheme,
  Variant as SelectVariant,
  Margin as SelectMargin,
} from './Select.types';
export default SelectComponent;
