import StreamProcessService from './StreamProcessService';

const LogTitle = 'StreamProcessManagerService';

class StreamProcessManagerService {
    private _streamProcessMap: Record<string, StreamProcessService> = {}; // Define the type for _streamProcessMap.
  
    startConnection(callRecordId: string): void { // Specify the type for callRecordId and the return type.
      const streamProcess = new StreamProcessService(callRecordId);
      this._streamProcessMap[callRecordId] = streamProcess;
  
      streamProcess.start();
    }
  
    stopConnection(callRecordId?: string): void { // Make callRecordId optional and specify the return type.
      if (!callRecordId) {
        console.log('CallRecordId is not given. Skipping removing socket mapping');
        return;
      }
  
      const streamProcess = this._streamProcessMap[callRecordId];
  
      if (streamProcess) {
        streamProcess.stop();
        delete this._streamProcessMap[callRecordId];
      }
    }
  
    setStreamMuteState(callRecordId: string, muted: boolean = true): void { // Specify the types for parameters and the return type.
      const streamProcess = this._streamProcessMap[callRecordId];
  
      if (streamProcess) {
        if (muted) {
          streamProcess.muteStreams();
        } else {
          streamProcess.unmuteStreams();
        }
      }
    }
  
    receiveCallHoldUpdate(callRecordId: string, isHold: boolean): void { // Specify the types for parameters and the return type.
      const streamProcess = this._streamProcessMap[callRecordId];
  
      if (streamProcess) {
        streamProcess.didReceiveCallHoldUpdate(callRecordId, isHold);
      }
    }
  
    createManualBookmark(callRecordId: string, startTime: number, endTime: number): void { // Specify the types for parameters and the return type.
      const streamProcess = this._streamProcessMap[callRecordId];
      if (streamProcess) {
        streamProcess.createManualBookmark(startTime, endTime);
      }
    }
  }
  
  export default StreamProcessManagerService;