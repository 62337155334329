import { Modal, Paper } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';

import { hideGdprModal } from '../../../slices/gdprModal';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import Typography, { TypographyVariant } from '../../Typography';
import Button, { ButtonTheme, ButtonVariant } from '../../Button';
import OutboundCallService from '../../../Services/Call/OutboundCall';

import GdprModalThemer from './GdprModal.theme';

const ThemedModal = withStyles(GdprModalThemer.classes as any)(Modal);

const GdprModal = () => {
  const dispatch = useAppDispatch();
  const classes = GdprModalThemer.useStyles();
  const authData = useAppSelector(state => state.auth);
  const tenantSettings = useAppSelector(state => state.settings.tenantSettings);
  const gdprModalData = useAppSelector(state => state.gdprModal);

  const handleClose = () => {
    dispatch(hideGdprModal());
  };

  const handleCall = () => {
    OutboundCallService.initiateCallRequest(
      authData, 
      tenantSettings,
      gdprModalData.modalData.contact, 
      gdprModalData.modalData.contactNumber,
      gdprModalData.modalData.dialFromNumber, 
      gdprModalData.modalData.callEventId
    );

    dispatch(hideGdprModal());
  };

  return (
    <ThemedModal className={classes.modal} open={gdprModalData?.open} onClose={handleClose}>
      <Paper className={classes.paper}>
        <div className={classes.title}><Typography.H2 variant={TypographyVariant.title}>{gdprModalData.modalData.title}</Typography.H2></div>
        <div><Typography.P1 variant={TypographyVariant.title}>{gdprModalData.modalData.primaryText}</Typography.P1></div>
        <br />
        {!!(gdprModalData.modalData.secondaryText) && (
          <div><Typography.P1 variant={TypographyVariant.title}>{gdprModalData.modalData.secondaryText}</Typography.P1></div>
        )}
        <div className={classes.footer}>
          <Button id="Back-Button" theme={ButtonTheme.primary} variant={ButtonVariant.text} onClick={handleClose} disabled={false}>
            <Typography.H4 variant={TypographyVariant.primary}>{gdprModalData.modalData.cancelButtonText}</Typography.H4>
          </Button>
          {!!(gdprModalData.modalData.confirmButtonText) && (
            <Button
              id="Proceed-Button"
              theme={ButtonTheme.primary}
              variant={ButtonVariant.normal}
              className={classes.dangerButton}
              onClick={handleCall}
              disabled={false}
            >
              <Typography.H4 variant={TypographyVariant.light}>{gdprModalData.modalData.confirmButtonText}</Typography.H4>
            </Button>
          )}
        </div>
      </Paper>
    </ThemedModal>
  );
};

export default GdprModal;
