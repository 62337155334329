import React from 'react';

export interface IconButtonProps {
  id: string;
  children: React.ReactNode;
  ariaOwns?: string | undefined;
  ariaHasPopup?: boolean | "grid" | "listbox" | "menu" | "false" | "true" | "dialog" | "tree" | undefined;
  ariaControls?: string | undefined;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  theme?: Theme;
  size?: Size;
  edge?: Edge;
  onClick?: (callbackValue?: any, event?:any) => any;
  callbackValue?: any;
  onMouseDown?: (event?: any) => any;
  onTouchStart?: (event?: any) => any;
  onMouseUp?: (event?: any) => any;
  onMouseLeave?: (event?: any) => any;
  onTouchEnd?: (event?: any) => any;
  onMouseEnter?: (event?: any) => any;
  onMouseOver?: (event?: any) => any;
}

export enum Theme {
  default = 'default',
  dark = 'dark',
  light = 'light',
  primary = 'primary',
  primarySolid = 'primarySolid',
  secondary = 'secondary',
  secondarySolid = 'secondarySolid',
  danger = 'danger',
  dangerSolid = 'dangerSolid',
  close = 'close',
  navigation = 'navigation',
  navigationSolid = 'navigationSolid',
}

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
}

export const MuiSizeMap = {
  [Size.small]: 'small',
  [Size.medium]: 'medium',
  [Size.large]: 'large',
  [Size.extraLarge]: 'extraLarge',
};

export type Edge = 'start' | 'end' | false;
