import React, { forwardRef } from 'react';
import classnames from 'classnames';

import CallHistoryInfiniteList from './CallHistoryInfiniteList';
import CallHistoryRowHeader from './Private/CallHistoryRowHeader';

import { CallHistoryProps } from './CallHistory.types';
import CallHistoryThemer from './CallHistory.theme';

const CallHistoryComponent = forwardRef<HTMLDivElement, CallHistoryProps<any, any>>((props, ref) => {
  const {
    id, className, style, listClassName, listStyle,
    data, dataTotalCount, dataLoading,
    bottomHitThreshold, onNextPageRequested,
    getId, getAvatarUrl, getAvatarFallbackLabel, isFailedCall,
    getCallDirection, getCallMedium, getDate, getTime, getDuration, getAvatarClassName, onCall,
    actionsMaxCount = 4, getActions, getActionNode, showActionsOverflowTooltip, getActionsOverflowCountTooltipContent,
    showNavigationNode, showNavNodeOnHover, getNavigationNode,
    miniVersion, showHeader, isUnwrapped,
    emptyCallHistoryNode, loaderCallHisoryNode,
    showCallActionButton, menuClassName, menuListClassName, menuStyle, menuListStyle, getMenuItems, onHistoryRowClick, onAvatarClick,
    getCallStatusBadgeTooltip,
    callHistoryInitialLoader,
  } = props;
  const classes = CallHistoryThemer.useStyles();
  const emptyData = dataTotalCount === 0;

  return (
    <div
      id={id}
      ref={ref}
      className={classnames(classes.container, className)}
      style={style}
    >
      {(emptyData && callHistoryInitialLoader) && (
        loaderCallHisoryNode
      )}

      {(emptyData && !callHistoryInitialLoader) && (
        emptyCallHistoryNode
      )}

      {(showHeader && !emptyData) && (
        <CallHistoryRowHeader id={`${id}-Header`} showNavigationNode={showNavigationNode} miniVersion={miniVersion} />
      )}

      {(!emptyData && !callHistoryInitialLoader) && (
        <CallHistoryInfiniteList
          id={`${id}-List`}
          className={listClassName}
          style={listStyle}
          data={data}
          dataTotalCount={dataTotalCount}
          dataLoading={dataLoading}
          bottomHitThreshold={bottomHitThreshold}
          onNextPageRequested={onNextPageRequested}
          getId={getId}
          getAvatarUrl={getAvatarUrl}
          getAvatarFallbackLabel={getAvatarFallbackLabel}
          getAvatarClassName={getAvatarClassName}
          isFailedCall={isFailedCall}
          isUnwrapped={isUnwrapped}
          getCallDirection={getCallDirection}
          getCallMedium={getCallMedium}
          getDate={getDate}
          getTime={getTime}
          getDuration={getDuration}
          actionsMaxCount={actionsMaxCount}
          getActions={getActions}
          getActionNode={getActionNode}
          onCall={onCall}
          showActionsOverflowTooltip={showActionsOverflowTooltip}
          getActionsOverflowCountTooltipContent={getActionsOverflowCountTooltipContent}
          showNavigationNode={showNavigationNode}
          showCallActionButton={showCallActionButton}
          showNavNodeOnHover={showNavNodeOnHover}
          getNavigationNode={getNavigationNode}
          miniVersion={miniVersion}
          menuStyle={menuStyle}
          menuListStyle={menuListStyle}
          menuClassName={menuClassName}
          menuListClassName={menuListClassName}
          getMenuItems={getMenuItems}
          onHistoryRowClick={onHistoryRowClick}
          onAvatarClick={onAvatarClick}
          getCallStatusBadgeTooltip={getCallStatusBadgeTooltip}
        />
      )}
    </div>
  );
});

CallHistoryComponent.defaultProps = {
  className: undefined,
  style: undefined,
  listClassName: undefined,
  listStyle: undefined,
  bottomHitThreshold: 300,
  actionsMaxCount: 3,
  showActionsOverflowTooltip: false,
  showNavigationNode: false,
  showCallActionButton: false,
  showNavNodeOnHover: false,
  miniVersion: false,
  showHeader: true,
  emptyCallHistoryNode: undefined,
  loaderCallHisoryNode: undefined,
  callHistoryInitialLoader: false,
};

export { CallHistoryCallDirection } from './CallHistory.types';
export default CallHistoryComponent;
