import React, { forwardRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import Typography, { TypographyVariant } from '../../../Typography';

import { DiallerButtonProps } from './DiallerButton.types';
import ButtonThemer from './DiallerButton.theme';

const DiallerButtonComponent = forwardRef<HTMLButtonElement, DiallerButtonProps>((props, ref) => {
  const {
    id,
    className,
    style,
    children,
    onClick,
    callbackValue,
    type,
    isSubscript,
    onMouseDown,
    onTouchStart,
    onMouseUp,
    onMouseLeave,
    onTouchEnd,
    isDisabled,
  } = props;
  const classes = ButtonThemer.useStyles();
  const buttonClassName = classnames(classes.contained, classes.root, className);
  const subScriptClassName = classnames(classes.subScriptNumber);
  const handleClick = useCallback(
    (e: any) => {
      onClick?.(callbackValue, e);
    },
    [onClick, callbackValue]
  );

  return (
    <Button
      id={id}
      ref={ref}
      className={buttonClassName}
      style={style}
      onClick={handleClick}
      disableRipple
      disableElevation
      disableFocusRipple
      type={type}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onTouchEnd}
      disabled={isDisabled}
    >
      <Typography.H3 variant={isDisabled ? TypographyVariant.disabled :TypographyVariant.light}>{children}</Typography.H3>
      {isSubscript && (
        <Typography.P2 variant={isDisabled ? TypographyVariant.disabled :TypographyVariant.light} className={subScriptClassName}>
          +
        </Typography.P2>
      )}
    </Button>
  );
});

DiallerButtonComponent.defaultProps = {
  className: undefined,
  style: undefined,
  onClick: () => {},
  callbackValue: undefined,
  type: 'button',
  isSubscript: false,
  isDisabled: false,
};
export default DiallerButtonComponent;
