import React from 'react';

export interface ButtonProps {
  id: string;
  children: React.ReactNode;
  ariaOwns?: string | undefined;
  ariaHasPopup?: boolean | 'grid' | 'listbox' | 'menu' | 'false' | 'true' | 'dialog' | 'tree' | undefined;
  ariaControls?: string | undefined;
  className?: string;
  style?: React.CSSProperties;
  theme?: Theme;
  variant?: Variant;
  // TODO: Need to change the order of the params here, and change it in components that derive from this.
  onClick?: (callbackValue?: any, event?: any) => any;
  callbackValue?: any;
  disabled?: boolean;
  href?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  type?: 'button' | 'reset' | 'submit';
  fullWidth?: boolean;
  onMouseDown?: (event?: any) => any;
  onTouchStart?: (event?: any) => any;
  onMouseUp?: (event?: any) => any;
  onMouseLeave?: (event?: any) => any;
  onTouchEnd?: (event?: any) => any;
  onMouseEnter?: (event?: any) => any;
  onMouseOver?: (event?: any) => any;
}

export enum Theme {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
  light = 'light',
}

export enum Variant {
  normal = 'normal',
  inverse = 'inverse',
  text = 'text',
}
