// import Logger from 'viq:services/Logger';
const LogTitle = 'BrowserPageVisibilityService';
class BrowserPageVisibilityService {
  visibilityParams = {
    hidden: '',
    visibilityChange: '',
  }
  constructor() {
    // Resolving visibility API parameter names
    if (typeof document.hidden !== 'undefined') {
      this.visibilityParams.hidden = 'hidden';
      this.visibilityParams.visibilityChange = 'visibilitychange';
    } else if ((document as any).msHidden !== 'undefined') {
      this.visibilityParams.hidden = 'msHidden';
      this.visibilityParams.visibilityChange = 'msvisibilitychange';
    } else if ((document as any).webkitHidden !== 'undefined') {
      this.visibilityParams.hidden = 'webkitHidden';
      this.visibilityParams.visibilityChange = 'webkitvisibilitychange';
    }
  }
  registerListener = (handler: EventListener) => {
    // If the feature is not supported, we have to terminate here
    if (typeof document.addEventListener === 'undefined' || !this.visibilityParams.hidden) {
      console.log(LogTitle, 'Page Visibility API not supported. Unable to register handler');
      return;
    }
    document.addEventListener(this.visibilityParams.visibilityChange, handler, false);
  }
  unregisterListener = (handler: EventListener) => {
    // If the feature is not supported, we have to terminate here
    if (typeof document.removeEventListener === 'undefined' || !this.visibilityParams.hidden) {
      console.log(LogTitle, 'Page Visibility API not supported. Unable to unregister handler');
      return;
    }
    document.removeEventListener(this.visibilityParams.visibilityChange, handler, false);
  }
}

export default new BrowserPageVisibilityService();