import React, { forwardRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { TooltipProps } from './Tooltip.types';
import TooltipThemer from './Tooltip.theme';

const ThemedTooltip = withStyles(TooltipThemer.classes)(Tooltip);

const TooltipComponent: React.FC<TooltipProps> = forwardRef<HTMLButtonElement, TooltipProps>((props, ref) => {
  const {
    id, className, style,
    content, placement,
    arrow, interactive, force,
    children,
  } = props;
  const classes = TooltipThemer.useStyles();
  const childContent = force ? (<span>{children}</span>) : (children);

  return (
    <ThemedTooltip
      id={id}
      ref={ref}
      className={classnames(classes.default, className)}
      style={style}
      title={content}
      placement={placement}
      arrow={arrow}
      interactive={interactive}
    >
      {childContent}
    </ThemedTooltip>
  );
});

TooltipComponent.defaultProps = {
  className: undefined,
  style: undefined,
  arrow: false,
  interactive: false,
  placement: 'bottom',
  force: false,
};

export default TooltipComponent;
