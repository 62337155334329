import { makeStyles, createStyles } from '@material-ui/core/styles';

import { CallHistoryRowHeaderProps } from './CallHistoryRowHeader.types';

import Colours from '../../../../Theme/Colours';

const classes = createStyles({});
const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    backgroundColor: Colours.Backgrounds.white,
    paddingLeft: 5,
    paddingRight: 18,
  },

  columnAvatar: { width: 40 },
  avatarText: { width: 40 },
  columnTimestamp: {
    flex: 1,
    minWidth: 62,
    maxWidth: '22%',
    marginLeft: '8%',
  },
  columnDuration: {
    flex: 1,
    minWidth: 55,
    maxWidth: '22%',
    marginLeft: '8%',
  },
  columnActions: {
    flex: 1,
    minWidth: 78,
    width: 78,
    marginLeft: '6%',
  },
  columnNavigation: {
    width: (props: CallHistoryRowHeaderProps) => props.miniVersion ? 30 : 90,
    marginLeft: '3%',
  },
});

export default { classes, useStyles };
