import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../Theme/Colours';

const classes = {
  filled: {},
  icon: {
    fontWeight: 900,
    fontSize: 24,
  },
  iconFilled: {
    color: `${Colours.Typography.light} !important`,
    marginRight: 16,
    top: 'calc(50% - 14px)',
  },
  iconOpen: {
    color: Colours.Typography.light,
    marginLeft: 16,
    top: 'calc(50% - 14px)',
  },
};
const formControlClasses = {};
const inputLabelClasses = {};
const useStyles = makeStyles({
  default: {
    minWidth: 178,
    fontSize: 14,
    color: Colours.Typography.input,
  },
  darkTheme: {
    color: Colours.Typography.light,
    backgroundColor: ' #001326 !important',

    '&:hover': {
      color: Colours.Typography.light,
      backgroundColor: ' #001326',
    },
    '&:active': {
      color: Colours.Typography.light,
      backgroundColor: ' #001326',
    },
    '&:focus': {
      color: Colours.Typography.light,
      backgroundColor: ' #001326',
    },
  },
  emptyItemText: {
    marginLeft: 12,
  },
});

export default { classes, formControlClasses, inputLabelClasses, useStyles };
