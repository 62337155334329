import React from 'react';

export interface CallHistoryRowLoadingProps {
  id: string
  className?: string
  style?: React.CSSProperties
  navigationNode?: React.ReactNode
  theme: Theme
  showNavigationNode?: boolean
  showCallActionButton?: boolean
  miniVersion?: boolean
};

export enum Theme {
  normal = 'normal',
  inverse = 'inverse',
};
