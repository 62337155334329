import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  panelContainer: {
    bottom: '0px', 
    height: '100%',
   },
  callPanel: {
    height: '100%',
    minWidth: 120,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#001932 !important',
  }, 
  callContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxShadow: 'lg',
    justifyContent: "space-between",
  },
  contactDetailsContent: {
    flex: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    height: '150px !important',
    width: '150px !important',
  },
  avatarFallbackText: {
    fontSize: '60px !important'
  },
  contactDetails: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contactName: {
    display: 'flex',
    alignItems: 'center',
  },
  account: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > svg': {
      color: '#FFFFFF',
      marginRight: 8,
    },
  },
  callForwardInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  callActions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  answerButton: {
    marginRight: '60px !important',
    backgroundColor: 'green'
  },
  hangupButton: {
    backgroundColor: 'red'
  },
});

export default { useStyles };
