export const CallBarState = {
  IDLE: 'IDLE',
  INCOMING: 'INCOMING',
  CALLING: 'CALLING', // Stete to identify the moment between calling and connecting
  IN_CALL: 'IN_CALL',
  IN_PROGRESS: 'IN_PROGRESS', // State to identify whether the call is asnwered
  POST_CALL: 'POST_CALL',
  WRAP_UP: 'WRAP_UP',
};

export const IsUserInCall = (callBarState: any) => callBarState !== CallBarState.IDLE;
export const IsCallAttempting = (callBarState: any) => callBarState === CallBarState.CALLING;
export const IsCallIncoming = (callBarState: any) => callBarState === CallBarState.INCOMING;
export const IsUserInActiveCall = (callBarState: any) => callBarState === CallBarState.IN_CALL;
export const IsUserGetAnswered = (callBarState: any) => callBarState === CallBarState.IN_PROGRESS;
export const IsUserInWrapUpScreen = (callBarState: any) => callBarState === CallBarState.WRAP_UP;
