import { makeStyles } from '@material-ui/core/styles';

import { NumberDropdownProps } from './NumberDropdown.types';
import Colours from '../../../../Theme/Colours';

const filledInputClasses = {
  input: {
    padding: '20px 50px 24px 24px',
  },
  underline: {
    borderBottom: 'unset',
    '&:before': { borderBottom: 'unset', transition: 'unset' },
    '&:after': { borderBottom: 'unset' },
    '&$disabled': {
      '&:before': { borderBottom: 'unset', transition: 'unset' },
    },
  },
  disabled: {
    color: Colours.Typography.light,
    '&:before': { borderBottom: 'unset', transition: 'unset' },
  },
};
const menuClasses = {
  paper: {},
};

const useStyles = makeStyles({
  default: {},
  popoverRootStyle: {
    zIndex: (props: NumberDropdownProps<any>) => `${props.menuZIndex} !important` as any,
  },
  inputContainer: {
    width: '100%',
    height: 78,
  },
  inputText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px !important',
    lineHeight: '24px',
  },
  dropdownMenuStyle: {
    backgroundColor: '#001326 !important',
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    top: '134px !important',
    minWidth: '398px !important',
    width: '100%',
    maxWidth: '400px !important',
    left: '0px !important' // Should be removed in maximized view
  },
  dropdownListStyle: {
    paddingBottom: 11,
  },
  dropdownItemStyles: {
    height: 52,
    paddingLeft: 24,
    backgroundColor: '#001326 !important',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1) !important' },
    '& >div': {
      display: 'flex',
      alignItems: 'center',
      '& >span': {
        display: 'flex',
      },
    },
  },
});

export default { filledInputClasses, menuClasses, useStyles };
