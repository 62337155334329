import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import TwilioService from "../Services/Twilio/TwilioService";
import TwilioVoiceService from '../Services/TwilioVoiceService';
import { RootState } from '../app/store';

const initialState: any = { data: {}, ringtoneDeviceId: null, speakerDeviceId: null };

export const getTwilioData: any = createAsyncThunk(
  "getTwilioData/post",
  async (userId, { getState }) => {
    const currentState = getState() as RootState;
    const { tenantCode, AccessToken } = currentState.auth.authUser;
    const requestBody = {
      callerId: userId
    };

    const result = await TwilioService.getTwilioData(tenantCode, AccessToken, requestBody);
    const twilioInfo = result.data
    const twilioToken = twilioInfo?.data?.token;
    const effectiveRegionId = twilioInfo?.data?.regionId;
    const restoringCallState = 'Ready';
    TwilioVoiceService.setupTwilio(twilioToken, effectiveRegionId, restoringCallState);

    return result.data;
  }
);

const twilioSlice = createSlice({
  name: 'twilio',
  initialState,
  reducers: {
    deviceDidChange(state, action: PayloadAction<any>) {
      const { type, deviceId } = action.payload;
      if (type === 'ringTone') {
        state.ringtoneDeviceId = deviceId;
      } else {
        state.speakerDeviceId = deviceId;
      }
    },
  },
  extraReducers: {
    [getTwilioData.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.data = data;
      },
  }
});

export const { deviceDidChange } = twilioSlice.actions;
const { reducer } = twilioSlice;
export default reducer;
