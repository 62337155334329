import { useEffect, useState } from "react";

export const useExternalScript = (url: string | null) => {
  const [state, setState] = useState(url ? "loading" : "error");
  
  useEffect(() => {
    if (!url) {
      setState("error");
      return;
     }
    let script = document.querySelector(`script[src="${url}"]`) as HTMLScriptElement || null;
    
    const handleScript = (e: Event) => {
      setState(e.type === "load" ? "ready" : "error");
    };
    
    if (!script) {
      script = document.createElement("script");
      if (script != null) {
        script.type = "application/javascript";
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
        script.addEventListener("load", handleScript);
        script.addEventListener("error", handleScript);
      }
    }
   
   script?.addEventListener("load", handleScript);
   script?.addEventListener("error", handleScript);
   
   return () => {
     script?.removeEventListener("load", handleScript);
     script?.removeEventListener("error", handleScript);
   };
  }, [url]);
  
  return state;
};