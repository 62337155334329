import React, { forwardRef } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestion,
  faBriefcaseMedical,
  faStickyNote,
  faExclamationTriangle,
  faHandHeart,
  faBadgeDollar,
  faVoicemail,
  faCalendarPlus,
  faEnvelope,
  faSms,
  faFlag,
  faUserPlus,
  faPhone,
} from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import Typography, { TypographyVariant } from '../Typography';
import Icon, { IconSize, IconTheme } from '../Icon';
import Tooltip from '../Tooltip';

import { CallActionAvatarProps, Size, Type, Variant } from './CallActionAvatar.types';
import CallActionAvatarThemer from './CallActionAvatar.theme';

const TypeIconComponentMap = {
  default: <FontAwesomeIcon icon={faQuestion as IconProp} />,
  [Type.opportunity]: <FontAwesomeIcon icon={faBadgeDollar as IconProp} />,
  [Type.case]: <FontAwesomeIcon icon={faBriefcaseMedical as IconProp} />,
  [Type.note]: <FontAwesomeIcon icon={faStickyNote as IconProp} />,
  [Type.gdpr]: <FontAwesomeIcon icon={faExclamationTriangle as IconProp} />,
  [Type.vulnerability]: <FontAwesomeIcon icon={faHandHeart as IconProp} />,
  [Type.callback]: <FontAwesomeIcon icon={faCalendarPlus as IconProp} />,
  [Type.voicemail]: <FontAwesomeIcon icon={faVoicemail as IconProp} />,
  [Type.email]: <FontAwesomeIcon icon={faEnvelope as IconProp} />,
  [Type.sms]: <FontAwesomeIcon icon={faSms as IconProp} />,
  [Type.incomplete]: <FontAwesomeIcon icon={faFlag as IconProp} />,
  [Type.hotTransfer]: <FontAwesomeIcon icon={faUserPlus as IconProp} />,
  [Type.callOutcome]: <FontAwesomeIcon icon={faPhone as IconProp} />,
};

const SizeIconSizeMap = {
  [Size.small]: IconSize.medium,
  [Size.medium]: IconSize.large,
  [Size.large]: IconSize.extraLarge,
};

const CallActionAvatarComponent = forwardRef<HTMLDivElement, CallActionAvatarProps>((props, ref) => {
  const { id, className, style, iconClassName, iconStyle, type, size, hideTooltip, firstAgent, secondAgent, callOutcome } = props;
  const classes = CallActionAvatarThemer.useStyles(props);
  const IconComponent = TypeIconComponentMap[type] ?? TypeIconComponentMap.default;
  const TypeIconTooltipMap = {
    default: 'Unknown',
    [Type.opportunity]: 'Opportunity',
    [Type.case]: 'Case Created',
    [Type.note]: 'Notes',
    [Type.gdpr]: 'GDPR',
    [Type.vulnerability]: 'Vulnerability',
    [Type.callback]: 'Scheduled Callback',
    [Type.voicemail]: 'Voicemail',
    [Type.email]: 'Email',
    [Type.sms]: 'SMS',
    [Type.incomplete]: 'Incomplete Call Outcome',
    [Type.callOutcome]: callOutcome,
  };
  const TooltipContent = () => {
    let result;

    // Separating for style the hot transfer tool tip
    if (type === Type.hotTransfer) {
      result = (
        <>
        <Typography.P2 id={`${id}-Tooltip-Content`} variant={TypographyVariant.light}>
          Hot Transfer
        </Typography.P2>
        {(firstAgent && secondAgent) && (
          <Typography.P2 id={`${id}-Tooltip-Content`} variant={TypographyVariant.light} className={classes.hotTransferToolTip}>
            {firstAgent} to {secondAgent}
          </Typography.P2>
        )}
      </>
      )
    } else {
      result = (
        <>
        <Typography.P2 id={`${id}-Tooltip-Content`} variant={TypographyVariant.light}>
          {TypeIconTooltipMap[type] ?? TypeIconTooltipMap.default}
        </Typography.P2>
      </>
      )
    }

    return result;
  };

  const content = (
    <div id={id} ref={ref} className={classnames(classes.wrapper, className)} style={style}>
      <Icon
        id={`${id}-Icon`}
        className={classnames(classes.icon, iconClassName)}
        style={iconStyle}
        size={SizeIconSizeMap[size ?? Size.small]}
        theme={IconTheme.light}
      >
        {IconComponent}
      </Icon>
    </div>
  );
  let result = content;

  if (!hideTooltip) {
    result = (
      <Tooltip id={`${id}-Tooltip`} content={TooltipContent()} placement="bottom" arrow>
        {content}
      </Tooltip>
    );
  }

  return result;
});

CallActionAvatarComponent.defaultProps = {
  className: undefined,
  style: undefined,
  iconClassName: undefined,
  iconStyle: undefined,
  variant: Variant.normal,
  size: Size.small,
  hideTooltip: false,
  firstAgent: '',
  secondAgent: '',
  callOutcome: '',
};

export { Size as CallActionAvatarSize, Type as CallActionAvatarType, Variant as CallActionAvatarVariant } from './CallActionAvatar.types';
export default CallActionAvatarComponent;
