import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { open: false, modalData: {} };

const alertModalSlice = createSlice({
  name: 'alertModal',
  initialState,
  reducers: {
    showAlertModal: (state,  action: PayloadAction<any>) => {
      const { contact, contactNumber, dialFromNumber, callEventId } = action.payload;

      state.open = true;
      state.modalData = {
        contact,
        contactNumber,
        dialFromNumber,
        callEventId,
        title: 'Wrap up call to continue',
        primaryText: 'It looks like your last call did not close properly. You must wrap the call up before you can dial out again',
        confirmButtonText: 'Go to Call wrap up',
        cancelButtonText: 'Go back',
      };
    },
    hideAlertModal: (state) => {
      state.open = false;
      state.modalData = {
        contact: null,
        contactNumber: '',
        dialFromNumber: '',
        callEventId: '',
        title: ''
      }
    },
  },
});

export const { showAlertModal, hideAlertModal } = alertModalSlice.actions;
const { reducer } = alertModalSlice;
export default reducer;
