import { DefaultUserStatuses } from '../../constants/UserStatusesEnum';

const GetAvailabilityStatus = (userStatus: any, tenantSettingsUserStatuses: any) => {
  const userStatusId = userStatus?.id;
  const userAvailability = tenantSettingsUserStatuses?.find((status: any) => userStatusId === status?.id);
  const isUserUnavailable = userAvailability?.name === DefaultUserStatuses.Unavailable;

  return isUserUnavailable;
};

export default GetAvailabilityStatus;
