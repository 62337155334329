import ApiHelper from '../../helpers/apiHelper';
import VoiceIQApiAxiosInstance from '../instances/VoiceIQAPI';
import { store } from '../../app/store';

const getBeacon = (tenantCode: string, AccessToken: string, requestBody: any) => {
  const config = {
    baseURL: store.getState().auth?.authUser?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(AccessToken, tenantCode),
    url: `/api/v1.0/beacon`,
    method: 'post',
    data: requestBody,
  };

  return VoiceIQApiAxiosInstance.request(config);
};

const TenantService = { getBeacon };

export default TenantService;