import CallCentreApi from '../CallCentre/CallCentre';
import { store } from '../../app/store';

class CallAgentSearchService {
  static getCallAgentOptions = async (
    searchTerm = '',
    includeTeams = true,
    includeBusyUsers = false,
    includeUnavailableUsers = false,
    includeBusyTeams = false,
    includeUnavailableTeams = false,
  ) => {
    const tenantCode = store.getState()?.auth?.authUser?.tenantCode;
    const accessToken = store.getState()?.auth?.authUser?.AccessToken;
    const userId = store.getState()?.auth?.user?.id;
    const response = await CallCentreApi.getCallForwardOptions(
      tenantCode,
      accessToken,
      searchTerm,
      includeTeams,
      includeBusyUsers,
      includeUnavailableUsers,
      includeBusyTeams,
      includeUnavailableTeams,
    );
    // const { data: { teams: availableTeams, users } } = response;
    const usersData = response?.data?.data?.users;
    const teamsData = response?.data?.data?.teams;

    const availableUsers = (usersData || []).filter((user: any) => user.id !== userId && user.isOnline);

    // Demo purpose
    // availableUsers.unshift({
    //   externalNumber: null,
    //   id: 'Demo-User',
    //   isAvailable: true,
    //   isAvailableMobile: true,
    //   isOnline: true,
    //   name: 'Karen Aldred',
    //   photoDetails: { photoUrl: null },
    // });

    const unavailableExternalUsers = (usersData || []).filter((user: any) => !user.isAvailable && !!user.externalNumber);

    return { availableTeams: teamsData, availableUsers, unavailableExternalUsers };
  }
}

export default CallAgentSearchService;
