export const ContactTypeEnum = Object.freeze({
  contact: 'Contact',
  lead: 'Lead',
  unknown: 'Unknown',
});

export const ContactSortBy = Object.freeze({
  CreatedDate: 'CreatedDate',
  FirstName: 'FirstName',
  LastName: 'LastName',
  AccountName: 'AccountName',
  Type: 'ContactType',
});

export const ContactPerson = Object.freeze({ caller: 'Caller' });

