import { makeStyles, createStyles } from '@material-ui/core/styles';

import { CallHistoryRowLoadingProps } from './CallHistoryRowLoading.types';

const Background = { normal: 'rgba(255, 255, 255, 1)', inverse: 'rgba(240, 248, 255, 1)' };

const classes = createStyles({});
const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    paddingLeft: 5,
    paddingRight: 18,
  },
  rowNormal: { backgroundColor: Background.normal },
  rowInverse: { backgroundColor: Background.inverse },

  columnAvatar: { width: 40 },
  avatar: { width: 36, height: 36 },

  columnTimestamp: {
    flex: 1,
    minWidth: 62,
    maxWidth: '22%',
    marginLeft: '8%',
  },
  columnDuration: {
    flex: 1,
    minWidth: 55,
    maxWidth: '22%',
    marginLeft: '8%',
  },
  columnActions: {
    flex: 1,
    minWidth: 78,
    width: 78,
    marginLeft: '8%',
  },
  columnNavigation: {
    width: (props: CallHistoryRowLoadingProps) => props.miniVersion ? 30 : 90,
    marginLeft: '3%',
  },
});

export default { classes, useStyles };
