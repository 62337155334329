import { makeStyles } from '@material-ui/core/styles';

import Colours from '../../Theme/Colours';

const classes = {
  tooltip: {
    padding: '7px 6px',
    margin: '6px 0',
    backgroundColor: Colours.Backgrounds.tooltip,
  },
};
const useStyles = makeStyles({
  default: {},
});

export default { classes, useStyles };
